import { CustomerSubsidiaryFormRequest } from './customer-subsidiary'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { addCustomerSubsidiary, resetAddCustomerSubsidiary } from './add-customer-subsidiary-slice'
import { CustomerSubsidiaryFormDialog } from './customer-subsidiary-form-dialog'
import { AnilityBackdrop } from '../../../../components/anility-backdrop'
import { getCustomerDetails } from '../get-customer-details-slice'
import { useEffect } from 'react'

interface AddCustomerSubsidiaryFormDialogProps {
  customerId: number;
  isSsoEnabled: boolean;
  auth0ConnectionId?: string;
  open: boolean;
  onSaveSuccess: () => void;
  onClose: () => void;
}
export const AddCustomerSubsidiaryForm = ({ customerId, open, onSaveSuccess, onClose, isSsoEnabled, auth0ConnectionId }: AddCustomerSubsidiaryFormDialogProps) => {
  const dispatch = useAppDispatch()
  const {
    addCustomerSubsidiaryState
  } = useAppSelector(state => state)
  const { error } = addCustomerSubsidiaryState

  useEffect(() => {
    dispatch(resetAddCustomerSubsidiary())
  }, [open])

  const defaultValues: CustomerSubsidiaryFormRequest = {
    id: 0,
    customerId,
    name: '',
    domainName: '',
    isSsoEnabled,
    auth0ConnectionId: auth0ConnectionId ?? ''
  }

  const handleSubmit = (formValues: CustomerSubsidiaryFormRequest) => {
    if (!customerId) { return }
    dispatch(addCustomerSubsidiary({
      ...formValues,
      onSuccess: () => {
        dispatch(
          getCustomerDetails({ customerId })
        )
        onSaveSuccess()
      }
    }))
  }

  return (
    <>
        <CustomerSubsidiaryFormDialog
            open={open}
            isSsoEnabled={isSsoEnabled}
            defaultValues={defaultValues}
            subLabel='You may add the customer customer subsidiary details below'
            onClose={onClose}
            onSubmit={handleSubmit}
            apiError={error}
        />
        <AnilityBackdrop open={addCustomerSubsidiaryState.loading === 'loading'} />
    </>
  )
}
