import React, { ChangeEvent, KeyboardEvent } from 'react'
import { FormControl, IconButton, Input, InputAdornment } from '@mui/material'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

export interface TableSearchProps {
  searchValue: string;
  onSearchClick: () => void;
  onResetSearchClick: () => void;
  onSearchTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onExportButtonClick?: () => void;
}

export const TableSearch = ({ searchValue, onSearchClick, onResetSearchClick, onSearchTextChange }: TableSearchProps) => {
  const handleSearchKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Return') {
      onSearchClick?.()
    }
  }

  return (
    <FormControl variant="standard">
      <Input
        id="input-with-icon-adornment"
        startAdornment={
          <InputAdornment position="start">
            <IconButton onClick={onSearchClick}>
              <SearchOutlinedIcon />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={onResetSearchClick}>
              <CloseOutlinedIcon />
            </IconButton>
          </InputAdornment>
        }
        placeholder="Search ..."
        onChange={onSearchTextChange}
        onKeyDown={handleSearchKeyDown}
        value={searchValue}
      />
    </FormControl>
  )
}
