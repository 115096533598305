import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'

export interface CreateReportWorkspace {
  assessmentRequestId: number
  onSuccess?: () => void
  onError?:() => void
}

interface CreateReportWorkspaceState {
  loading: LoadingType
  error: string
}

const initialState: CreateReportWorkspaceState = {
  loading: 'idle',
  error: ''
}

export const createReportWorkspace = createAsyncThunk<
  void,
  CreateReportWorkspace,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('report-workspace/save-super-form', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    const data = Object.assign({}, request, { onSuccess: undefined })
    await axios.post<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/super-form`,
      data,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    request.onError?.()
    return thunkAPI.rejectWithValue({ error })
  }
})

export const createReportWorkspaceSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    resetCreateReportWorkspaceLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    },
    resetCreateReportWorkspaceError: (state) => {
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createReportWorkspace.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(createReportWorkspace.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(createReportWorkspace.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetCreateReportWorkspaceLoadingStatus, resetCreateReportWorkspaceError } = createReportWorkspaceSlice.actions
export default createReportWorkspaceSlice.reducer
