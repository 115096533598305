
import React, { ChangeEvent, MouseEvent, useEffect, useMemo } from 'react'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'

import { PaginatedTable } from '../../../../components/paginated-table'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { formatDate, formatDuration, secureFileDownload } from '../../../../utils/functions'
import { useNavigate } from 'react-router-dom'
import { CompletedAssessmentRequestsItem, getCompletedAssessmentRequests, setPageNumber, setPageSize } from '../../../report/completed-assessments/get-completed-assessment-requests-slice'
import { InternalNoteContextMenuItem } from '../../../report/components/assessments-action-menu'
import { MenuListItemProps } from '../../../../components/menu-list-item'
import { setSelectedAssessmentInternalNote } from '../../../../components/set-internal-notes-slice'
import { DownloadLink } from '../../../../components/download-link'
import { ActionMenu } from '../../../../components/action-menu'
import { columns } from '../../../report/completed-assessments/completed-assessments'
import { AssessmentFooter } from '../../../report/components/assessment-footer'
import { TableHeader } from '../../../../components/table-header'

interface CustomerCompletedAssessmentsProps {
  customerId: string;
  searchValue: string;
  onSearchClick: () => void;
  onResetSearchClick: () => void;
  onSearchTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setShowInternalNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

const getTableCellStyle = () => ({
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  cursor: 'pointer'
})

export const CustomerCompletedAssessments = ({ customerId, searchValue, onSearchClick, onResetSearchClick, onSearchTextChange, setShowInternalNotes }: CustomerCompletedAssessmentsProps) => {
  const status = 'Completed'

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { getCompletedAssessmentRequestsState, tokenState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount } = getCompletedAssessmentRequestsState

  const handleViewReportWorkspace = (
    item: CompletedAssessmentRequestsItem
  ) => {
    navigate(`/report/report-workspace/view/${item.reportWorkspaceId}`, {
      state: {
        source: '/report/completed-assessments'
      }
    })
  }

  const loadCompletedAssessmentsInCustomers = () => {
    dispatch(getCompletedAssessmentRequests({
      customerId,
      status
    }))
  }

  useEffect(() => {
    if (getCompletedAssessmentRequestsState.loading === 'idle') {
      loadCompletedAssessmentsInCustomers()
    }
  }, [getCompletedAssessmentRequestsState.loading, customerId])

  const handleInternalNotesClick = (item: CompletedAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const generateContextMenu = (item: CompletedAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      })
    ]
  }

  const handleSearch = () => {
    onSearchClick()
  }

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const formattedCompletedAssessments = items.map((item) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      completedDateUtc: formatDate(item.completedDateUtc),
      entityAssessedResponseTime: formatDuration(item.entityAssessedResponseTime),
      timeTakenToCreateAssessment: formatDuration(item.timeTakenToCreateAssessment),
      timeTakenToDeliverAssessment: formatDuration(item.timeTakenToDeliverAssessment),
      rowInlineStyles: getTableCellStyle(),
      assignedAnalyst: item.assignedAnalyst?.label,
      assessmentReportLink: item.assessmentReportLink &&
        (<DownloadLink url={item.assessmentReportLink}><CloudDownloadOutlinedIcon /></DownloadLink>)
    }
  })

  const page = useMemo(() => {
    return { totalCount, size: pageSize, number: pageNumber }
  }, [totalCount, pageSize, pageNumber])

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()

    params.append('status', status)
    params.append('search', searchValue)
    params.append('customerId', customerId)

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/export?${params.toString()}`, tokenState.token)
  }

  return (
    <PaginatedTable
      columns={columns}
      items={formattedCompletedAssessments}
      headerComponent={
        <TableHeader
          searchValue={searchValue}
          onSearchTextChange={onSearchTextChange}
          onSearchClick={handleSearch}
          onResetSearchClick={onResetSearchClick}
          onExportButtonClick={handleExportButtonClick} />}
      footerComponent={
        <AssessmentFooter
          page={page}
          onTablePageChange={handlePageChange}
          onTablePageSizeChange={handlePageSizeChange}
        />
      }
      renderMenuRowActions={(item, index, itemLength) => {
        return (
          <ActionMenu
            isContextMenuDisabled={false}
            contextMenuIndicatorMarginLeft={0.6}
            menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
        )
      }}
      onRowClick={handleViewReportWorkspace}
    />
  )
}
