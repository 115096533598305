import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../store/store'
import { LoadingType } from '../utils/types'

export interface SetInternalNotes {
  assessmentRequestId: number
  internalNotes: string
  onSuccess?: () => void
}

interface SetInternalNotesState {
  selectedInternalNote: number | null
  loading: LoadingType
  error: string
}

const initialState: SetInternalNotesState = {
  selectedInternalNote: null,
  loading: 'idle',
  error: ''
}

export const setInternalNotes = createAsyncThunk<
  void,
  SetInternalNotes,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('report/setInternalNotes', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    await axios.patch<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}/internal-notes`,
      request.internalNotes,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'application/json'
        },
        validateStatus: (status) => status < 400
      }
    )

    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const setInternalNotesSlice = createSlice({
  name: 'set-internal-notes',
  initialState,
  reducers: {
    setSelectedAssessmentInternalNote: (state, action: PayloadAction<number>) => {
      state.selectedInternalNote = action.payload
    },
    resetSelectedAssessmentInternalNote: (state) => {
      state.selectedInternalNote = null
    },
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setInternalNotes.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(setInternalNotes.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(setInternalNotes.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus, setSelectedAssessmentInternalNote, resetSelectedAssessmentInternalNote } = setInternalNotesSlice.actions
export default setInternalNotesSlice.reducer
