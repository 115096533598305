import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Box, Grid, TextField, useTheme } from '@mui/material'
import { CustomDialogForm } from '../../../../components/custom-dialog-form'
import { AnilityBackdrop } from '../../../../components/anility-backdrop'
import TeamMemberInvitation from '../../../../interfaces/team-member-invitation'
import { teamMemberInvitationValidationSchema } from '../../../../validations/team-member-invitation-validation-schema'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { getEntityAssessedUsersDomainList, resetLoadingStatus as resetDomainListLoadingStatus } from '../get-entity-users-domain-list-slice'
import { inviteEntityAssessedUser, resetInviteEntityAssessedUser } from '../invite-entity-assessed-user-slice'
import { extractEmailDomain, isEmailOutsideValidDomains } from '../../../../utils/functions'
import { EMAIL_ADDRESS_MAX_LENGTH, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH } from '../../../../utils/constants'

export interface InviteEntityAssessedUserDialogProps {
  entityAssessedId: number
  open: boolean
  onSubmitSuccess: () => void
  onClose: () => void
}

export const InviteEntityAssessedUserDialog = ({ entityAssessedId, open, onSubmitSuccess, onClose } : InviteEntityAssessedUserDialogProps) => {
  const theme = useTheme()
  const [isOutsideDomain, setIsOutsideDomain] = useState(false)
  const dispatch = useAppDispatch()

  const { getEntityAssessedUsersDomainListState, inviteEntityAssessedUserState } = useAppSelector((state) => state)
  const { entityAssessedDetails } = getEntityAssessedUsersDomainListState
  const { error: inviteEntityUserError } = inviteEntityAssessedUserState
  const loading = getEntityAssessedUsersDomainListState.loading === 'loading' || inviteEntityAssessedUserState.loading === 'loading'

  const defaultValues: TeamMemberInvitation = {
    firstName: '',
    lastName: '',
    email: ''
  }
  const validationSchema = teamMemberInvitationValidationSchema.shape<Partial<Record<keyof TeamMemberInvitation, yup.AnySchema>>>({})
  const {
    handleSubmit, register, watch, formState: { errors }, reset
  } = useForm<TeamMemberInvitation>({
    resolver: yupResolver(validationSchema),
    defaultValues
  })

  const onSubmit = (entityUser: TeamMemberInvitation) => {
    dispatch(inviteEntityAssessedUser({
      entityAssessedId,
      firstName: entityUser.firstName,
      lastName: entityUser.lastName,
      email: entityUser.email,
      onSuccess: () => {
        reset()
        dispatch(resetInviteEntityAssessedUser())
        onSubmitSuccess()
      }
    }))
  }

  const handleClose = () => {
    reset()
    dispatch(resetInviteEntityAssessedUser())
    onClose()
  }

  const inputtedEmailDomain = extractEmailDomain(watch('email'))

  useEffect(() => {
    dispatch(resetDomainListLoadingStatus())
  }, [])

  useEffect(() => {
    if (!entityAssessedId) return

    if (getEntityAssessedUsersDomainListState.loading === 'idle') {
      dispatch(getEntityAssessedUsersDomainList({
        entityAssessedId
      }))
    }
  }, [entityAssessedId, getEntityAssessedUsersDomainListState.loading])

  useEffect(() => {
    if (inputtedEmailDomain && !errors.email && entityAssessedDetails) {
      const result = isEmailOutsideValidDomains(inputtedEmailDomain, entityAssessedDetails.validDomains)
      setIsOutsideDomain(result)
    } else {
      setIsOutsideDomain(false)
    }
  }, [inputtedEmailDomain, errors.email, entityAssessedDetails])

  return (
    <>
     <CustomDialogForm
          handleSubmit={handleSubmit(onSubmit)}
          onClose={handleClose} primaryText='Invite' secondaryText='Close' title='Invite team member' open={open}>
          <Grid container spacing={2} pb={1} pt={3}>
            <Grid item xs={6}>
              <TextField
                variant='outlined'
                label='First Name'
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName?.message : ' '}
                InputLabelProps={{ style: { color: theme.palette.primary.main } }}
                fullWidth {...register('firstName')}
                inputProps={{
                  maxLength: FIRST_NAME_MAX_LENGTH
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant='outlined'
                label='Last Name'
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName?.message : ' '}
                InputLabelProps={{ style: { color: theme.palette.primary.main } }}
                fullWidth {...register('lastName')}
                inputProps={{
                  maxLength: LAST_NAME_MAX_LENGTH
                }}
                />
            </Grid>
          </Grid>
          <Grid container pt={3}>
            <TextField
              variant='outlined'
              label='E-mail'
              error={!!errors.email}
              helperText={errors.email ? errors.email?.message : ' '}
              InputLabelProps={{ style: { color: theme.palette.primary.main } }}
              fullWidth {...register('email')}
              inputProps={{
                maxLength: EMAIL_ADDRESS_MAX_LENGTH
              }} />
          </Grid>
          {inviteEntityUserError && <Grid container pt={2}>
            <Grid item xs={12}>
              <Alert severity='error'>{inviteEntityUserError}</Alert>
            </Grid>
          </Grid>}
          {isOutsideDomain && <Grid container pt={2}>
            <Grid item xs={12}>
              <Alert severity='warning'>
                <Box textAlign="left">
                  Email is outside the current domain: <br/>{entityAssessedDetails?.validDomains.join(', ')}
                </Box>
              </Alert>
            </Grid>
          </Grid>}
     </CustomDialogForm>
     <AnilityBackdrop open={loading} />
    </>
  )
}
