import { useState, useEffect } from 'react'
import seq from 'seq-logging'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppSelector } from './hooks'

type LogLevel = 'Verbose' | 'Debug' | 'Information' | 'Warning' | 'Error' | 'Fatal'
export interface ILogger {
    log: (messageTemplate: string, level: LogLevel, metric: any) => void,
    verbose: (messageTemplate: string, metric: any) => void,
    debug: (messageTemplate: string, metric: any) => void,
    info: (messageTemplate: string, metric: any) => void,
    warning: (messageTemplate: string, metric: any) => void,
    error: (e: ErrorEvent | Error, messageTemplate: string, metric: any) => void,
    fatal: (e: Error, messageTemplate: string, metric: any) => void
}

export function useLogger (context: string) {
  const [logger, setLogger] = useState<null | ILogger>(null)
  const { user } = useAuth0()

  const { settings } = useAppSelector(state => (
    {
      settings: state.appSettingsState.settings
    }))

  useEffect(() => {
    if (settings == null) return
    // Initialize the logger
    const seqLogger = new seq.Logger({
      serverUrl: settings?.seqServerUrl,
      apiKey: settings?.seqApiKey,
      onError: (error) => {
        console.error('Error', error)
      }
    })
    const sendToSeq = (messageTemplate: string, level: LogLevel, metric: any) => {
      seqLogger.emit({
        timestamp: new Date(),
        level,
        messageTemplate,
        properties: {
          ...metric,
          context,
          UserEmail: user?.email
        }
      })
    }
    const sendErrorToSeq = (e: ErrorEvent | Error, messageTemplate: string, level: LogLevel, metric: any) =>
      seqLogger.emit({
        timestamp: new Date(),
        level,
        messageTemplate,
        properties: {
          ...metric,
          context,
          UserEmail: user?.email,
          type: e instanceof ErrorEvent ? e.type : e.name,
          message: e.message
        }

      })

    const logger = {
      log: (messageTemplate: string, level: LogLevel, metric: any) => sendToSeq(messageTemplate, level, metric),
      verbose: (messageTemplate: string, metric: any) => sendToSeq(messageTemplate, 'Verbose', metric),
      debug: (messageTemplate: string, metric: any) => sendToSeq(messageTemplate, 'Debug', metric),
      info: (messageTemplate: string, metric: any) => sendToSeq(messageTemplate, 'Information', metric),
      warning: (messageTemplate: string, metric: any) => sendToSeq(messageTemplate, 'Warning', metric),
      error: (e: ErrorEvent | Error, messageTemplate: string, metric: any) => sendErrorToSeq(e, messageTemplate, 'Error', metric),
      fatal: (e: Error, messageTemplate: string, metric: any) => sendErrorToSeq(e, messageTemplate, 'Fatal', metric)
    }

    // Set the logger state
    setLogger(logger)
  }, [user, settings])

  return { logger }
}
