import React from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, useTheme } from '@mui/material'

import { CustomDialogForm } from '../../../../components/custom-dialog-form'
import RoleInfo from '../components/role-info'
import { AnilityBackdrop } from '../../../../components/anility-backdrop'
import TeamMemberInvitation from '../../../../interfaces/team-member-invitation'
import { CustomerUserRoles } from './customer-user-roles'
import { teamMemberInvitationValidationSchema } from '../../../../validations/team-member-invitation-validation-schema'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { inviteCustomerUser, resetInviteCustomerUser } from '../invite-customer-user-slice'
import { Colors } from '../../../../theme/anility-dark-theme'
import { EMAIL_ADDRESS_MAX_LENGTH, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH } from '../../../../utils/constants'

export interface InviteCustomerUserDialogProps {
    customerId: number
    open: boolean
    onSubmitSuccess: () => void
    onClose: () => void
}

export const InviteCustomerUserDialog = ({ customerId, open, onSubmitSuccess, onClose } : InviteCustomerUserDialogProps) => {
  const theme = useTheme()
  const defaultSelectedRole = CustomerUserRoles.Owner
  const defaultValues: TeamMemberInvitation = {
    firstName: '',
    lastName: '',
    email: '',
    role: defaultSelectedRole
  }

  const {
    inviteCustomerTeamMemberState
  } = useAppSelector(state => state)
  const dispatch = useAppDispatch()
  const { error, loading } = inviteCustomerTeamMemberState

  const validationSchema = teamMemberInvitationValidationSchema.shape<Partial<Record<keyof TeamMemberInvitation, yup.AnySchema>>>({})
  const {
    handleSubmit, register, formState: { errors }, reset
  } = useForm<TeamMemberInvitation>({
    resolver: yupResolver(validationSchema),
    defaultValues
  })

  const onSubmit = (value: TeamMemberInvitation) => {
    if (!customerId) return
    dispatch(inviteCustomerUser({
      customerId,
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      role: value.role,
      onSuccess: () => {
        reset()
        dispatch(resetInviteCustomerUser())
        onSubmitSuccess()
      }
    }))
  }

  const handleClose = () => {
    reset()
    dispatch(resetInviteCustomerUser())
    onClose()
  }

  return (
    <>
        <CustomDialogForm
            primaryText='Invite'
            secondaryText='Close'
            title='Invite team member'
            open={open}
            handleSubmit={handleSubmit(onSubmit)}
            onClose={handleClose} >
          <Grid container spacing={2} pb={1} pt={3}>
            <Grid item xs={6}>
              <TextField
                variant='outlined'
                label="First Name" error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName?.message : ' '}
                InputLabelProps={{ style: { color: theme.palette.primary.main } }}
                fullWidth {...register('firstName')}
                inputProps={{
                  maxLength: FIRST_NAME_MAX_LENGTH
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Last Name" variant='outlined' error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName?.message : ' '}
                InputLabelProps={{ style: { color: theme.palette.primary.main } }}
                fullWidth {...register('lastName')}
                inputProps={{
                  maxLength: LAST_NAME_MAX_LENGTH
                }} />
            </Grid>
          </Grid>
          <Grid container pt={3}>
            <TextField color="primary" label="E-mail" variant='outlined' error={!!errors.email}
              helperText={errors.email ? errors.email?.message : ' '}
              InputLabelProps={{ style: { color: theme.palette.primary.main } }}
              fullWidth {...register('email')}
              inputProps={{
                maxLength: EMAIL_ADDRESS_MAX_LENGTH
              }}
              />
          </Grid>
          <Grid container pt={3} justifyContent="flex-end" flexWrap="nowrap" alignItems="center">
            <FormControl fullWidth>
              <InputLabel id={register('role').name} sx={{
                '&.MuiInputLabel-root.MuiInputLabel-shrink': {
                  color: theme.palette.text.primary
                }
              }}>Role</InputLabel>
              <Select defaultValue={defaultSelectedRole} label="Role" variant='outlined'
                id={register('role').name} labelId={register('role').name} color="primary" {...register('role')} >
                {Object.entries(CustomerUserRoles).map(([key, value]) => (
                  <MenuItem
                    key={key}
                    value={value}
                    sx={{ margin: 0, paddingTop: 1.5, paddingBottom: 1.5 }}>
                        {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip
              disableFocusListener
              disableTouchListener
              arrow
              placement='top-end'
              title={<RoleInfo />}
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: 450,
                    background: Colors.white,
                    color: Colors.mainColor,
                    border: `1px solid ${Colors.backgroundColor}`,
                    filter: 'drop-shadow(0px 2px 6px rgba(0,0,0,0.32))',
                    padding: '16px',
                    fontWeight: 400,
                    fontSize: '0.75rem'
                  }
                },
                arrow: {
                  sx: {
                    '&:before': {
                      filter: 'drop-shadow(0px 2px 6px rgba(0,0,0,0.32))'
                    },
                    color: Colors.white
                  }
                }
              }}
            >
              <HelpOutlineIcon fontSize='small' sx={{ marginLeft: 0.5 }} />
            </Tooltip>
          </Grid>
          {error && <Grid container pt={2}>
            <Grid item xs={12}>
              <Alert severity='error'>{error}</Alert>
            </Grid>
          </Grid>}
        </CustomDialogForm>
        <AnilityBackdrop open={loading === 'loading'} />
    </>
  )
}
