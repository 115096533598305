import * as React from 'react'
import { Box, IconButton, Avatar, Menu, MenuItem, ListItemIcon, Theme, ListItemText } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { Logout } from '@mui/icons-material'

export const UserMenu = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<EventTarget & HTMLElement | null>(null)
  const { isAuthenticated, user, logout } = useAuth0()

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return !isAuthenticated
    ? <></>
    : <Box sx={{ flexGrow: 0 }}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={(user?.name || user?.email)} src={user?.picture} />
        </IconButton>
      <Menu
        open={Boolean(anchorElUser)}
        anchorEl={anchorElUser}
        id="account-menu"
        onClose={handleCloseUserMenu}
        onClick={handleCloseUserMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 240,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            pt: 2,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component="a" sx={{ py: 0 }} onClick={() => logout()}>
          <ListItemIcon>
            <Logout fontSize="medium" sx={{ color: (theme: Theme) => theme.palette.primary.main }} />
          </ListItemIcon>
          <ListItemText primary='Logout' sx={{ color: (theme: Theme) => theme.palette.primary.main }} />
        </MenuItem>
      </Menu>
    </Box>
}
