import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'

export interface SyncCrmDeal {
  assessmentRequestId: number;
  onSuccess?: () => void;
}

interface SyncCrmDealState {
  loading: LoadingType;
  error: string;
}

const initialState: SyncCrmDealState = {
  loading: 'idle',
  error: ''
}

export const syncCrmDeal = createAsyncThunk<
  void,
  SyncCrmDeal,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: RejectValue;
  }
>('report/syncCrmDeal', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}/sync-crm-deal`,
      {},
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const syncCrmDealSlice = createSlice({
  name: 'sync-crm-deal',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(syncCrmDeal.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(syncCrmDeal.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(syncCrmDeal.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetLoadingStatus } = syncCrmDealSlice.actions
export default syncCrmDealSlice.reducer
