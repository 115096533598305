import { Route, Routes } from 'react-router-dom'
import AnalystList from './analyst-list'
import AnalystDetails from './analyst-details'

export const Analysts = () => {
  return (
    <Routes>
      <Route
        path='*'
        element={<AnalystList />}
      />
      <Route
        path='/:id'
        element={<AnalystDetails />} />
    </Routes>
  )
}
