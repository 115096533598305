
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { PaginatedTable } from '../../../../components/paginated-table'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { formatDate, formatDuration, secureFileDownload } from '../../../../utils/functions'
import { Typography } from '@mui/material'
import { InternalNoteContextMenuItem, ReactivateDeclinedAssessmentContextMenuItem } from '../../../report/components/assessments-action-menu'
import { MenuListItemProps } from '../../../../components/menu-list-item'
import { setSelectedAssessmentInternalNote } from '../../../../components/set-internal-notes-slice'
import { ActionMenu } from '../../../../components/action-menu'
import { usePermission } from '../../../../hooks/use-permission'
import { AnilityScopes as FiableScopes } from '../../../../interfaces/anility-scopes'
import { ExpiredAssessmentRequestsItem, getExpiredAssessmentRequests, setPageNumber, setPageSize } from '../../../report/expired-assessments/get-expired-assessment-requests-slice'
import { AssessmentDialog } from '../../../interfaces/assessment-dialog'
import { CustomDialogForm } from '../../../../components/custom-dialog-form'
import { reactivateDeclinedAssessmentRequest } from '../../../report/expired-assessments/reactivate-declined-assessment-request-slice'
import { columns } from '../../../report/expired-assessments/expired-assessments'
import { AssessmentFooter } from '../../../report/components/assessment-footer'
import { TableHeader } from '../../../../components/table-header'

interface EntityAssessedExpiredAssessmentsProps {
  entityAssessedId: number;
  searchValue: string;
  onSearchClick: () => void;
  onResetSearchClick: () => void;
  onSearchTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setShowInternalNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EntityAssessedExpiredAssessments = ({ entityAssessedId, searchValue, onSearchClick, onResetSearchClick, onSearchTextChange, setShowInternalNotes }: EntityAssessedExpiredAssessmentsProps) => {
  const status = 'Expired'
  const dispatch = useAppDispatch()

  const { getExpiredAssessmentRequestsState, tokenState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount } = getExpiredAssessmentRequestsState
  const [writeAssessment] = usePermission([FiableScopes.Write.Assessment])
  const [showReactiveConfirmationDialog, setShowReactiveConfirmationDialog] = useState<AssessmentDialog>(
    {
      show: false,
      assessmentRequestId: undefined,
      assessmentSubject: ''
    }
  )

  const loadExpiredAssessmentsInEntityAssessed = () => {
    dispatch(getExpiredAssessmentRequests({
      entityAssessedId,
      status
    }))
  }

  useEffect(() => {
    if (getExpiredAssessmentRequestsState.loading === 'idle') {
      loadExpiredAssessmentsInEntityAssessed()
    }
  }, [getExpiredAssessmentRequestsState.loading, entityAssessedId])

  const handleInternalNotesClick = (item: ExpiredAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const handleReactivateDeclinedAssessmentClick = useCallback(() => {
    if (!showReactiveConfirmationDialog.assessmentRequestId) {
      return
    }

    dispatch(reactivateDeclinedAssessmentRequest({
      assessmentRequestId: showReactiveConfirmationDialog.assessmentRequestId,
      onSuccess: () => {
        loadExpiredAssessmentsInEntityAssessed()
        setShowReactiveConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })
      }
    }))
  }, [showReactiveConfirmationDialog.assessmentRequestId])

  const generateContextMenu = (item: ExpiredAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReactivateDeclinedAssessmentContextMenuItem({
        show: writeAssessment && item.status === 'Declined',
        onClick: () => setShowReactiveConfirmationDialog({
          show: true,
          assessmentRequestId: item.id,
          assessmentSubject: item.contractName
        })
      })
    ]
  }

  const handleSearch = () => {
    onSearchClick()
  }

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const formattedExpiredAssessments = items.map((item, index) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      assignedAnalyst: item.assignedAnalyst?.label,
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering)
    }
  })

  const page = useMemo(() => {
    return { totalCount, size: pageSize, number: pageNumber }
  }, [totalCount, pageSize, pageNumber])

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()

    params.append('search', searchValue)
    params.append('status', status)
    params.append('entityAssessedId', entityAssessedId.toString())

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/export?${params.toString()}`, tokenState.token)
  }

  return (
    <>
      <PaginatedTable
        columns={columns}
        items={formattedExpiredAssessments}
        headerComponent={
          <TableHeader
            searchValue={searchValue}
            onSearchTextChange={onSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={onResetSearchClick}
            onExportButtonClick={handleExportButtonClick} />}
        footerComponent={
          <AssessmentFooter
            page={page}
            onTablePageChange={handlePageChange}
            onTablePageSizeChange={handlePageSizeChange}
          />
        }
        renderMenuRowActions={(item, index, itemLength) => {
          return (
            <ActionMenu
              isContextMenuDisabled={false}
              contextMenuIndicatorMarginLeft={0.6}
              menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
          )
        }}
      />
      {writeAssessment && showReactiveConfirmationDialog.show && <CustomDialogForm
        open={showReactiveConfirmationDialog.show}
        maxWidth={500}
        primaryText="Yes"
        handleSubmit={handleReactivateDeclinedAssessmentClick}
        onClose={() => setShowReactiveConfirmationDialog({
          show: false,
          assessmentRequestId: undefined,
          assessmentSubject: ''
        })}
        title="Do you want to proceed with reactivating this declined assessment?"
        secondaryText="Cancel">
        <Typography component='p' pb={1}>This assessment will revert to its original status.</Typography>
        <Typography component='span'>
          Assessment Subject: &nbsp;
          <Typography component='span' variant='h5'>
            {showReactiveConfirmationDialog?.assessmentSubject}
          </Typography>
        </Typography>
      </CustomDialogForm>}
    </>
  )
}
