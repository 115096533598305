import React, { useEffect } from 'react'
import { AppLayoutProps, Layout } from '../components/layout'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { getAdminAppSettings } from '../components/admin-app-settings-slice'
import { StyledEngineProvider } from '@mui/material'

export function DashboardLayout ({ children, menuItems, ...appLayoutProps }: React.PropsWithChildren<AppLayoutProps>) {
  const dispatch = useAppDispatch()

  const { appSettingsState, tokenState } = useAppSelector((state) => state)
  useEffect(() => {
    if (appSettingsState.loading === 'idle' && tokenState.token) {
      dispatch(getAdminAppSettings())
    }
  }, [dispatch, appSettingsState.loading, tokenState.token])

  return (
    <StyledEngineProvider injectFirst>
      <Layout menuItems={menuItems} {...appLayoutProps}>{children}</Layout>
    </StyledEngineProvider>
  )
}
