import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../../store/store'
import { GetReportWorkspaceListState, ReportWorkspaceList } from '../../../report/report-workspace/get-report-workspace-list-slice'

export const DEFAULT_PAGE_SIZE = 10

const initialState: GetReportWorkspaceListState = {
  items: [],
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  showDeleted: false,
  searchText: '',
  loading: 'idle',
  showAssignedToCurrentUser: false,
  showUnassigned: false,
  error: ''
}

export interface ReportWorkspaceSearch {
  pageNumber: number
  pageSize: number
}

interface GetAnalystInProgressReportParams extends ReportWorkspaceSearch {
  analystId: number
}

export const getAnalystInProgressReports = createAsyncThunk<
  ReportWorkspaceList,
  GetAnalystInProgressReportParams,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('analyst/getAnalystInProgressReports', async (request, thunkAPI) => {
  const { tokenState, getAnalystInProgressReportsState } = thunkAPI.getState()
  const params = new URLSearchParams()
  const { pageNumber, pageSize, searchText } = getAnalystInProgressReportsState

  params.append('pageNumber', pageNumber.toString())
  params.append('pageSize', pageSize.toString())
  params.append('search', searchText)
  params.append('showDeleted', 'false')
  params.append('analystId', request.analystId.toString())

  try {
    const response = await axios.get<ReportWorkspaceList>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace?${params.toString()}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getAnalystInProgressReportsSlice = createSlice({
  name: 'get-analyst-in-progress-reports',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
      state.loading = 'idle'
    },
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalystInProgressReports.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getAnalystInProgressReports.fulfilled, (state, { payload }) => {
      state.items = payload.items
      state.pageNumber = payload.pageNumber
      state.totalPages = payload.totalPages
      state.totalCount = payload.totalCount
      state.hasNextPage = payload.hasNextPage
      state.hasPreviousPage = payload.hasPreviousPage
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getAnalystInProgressReports.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { setSearchText, setPageSize, setPageNumber, resetLoadingStatus } = getAnalystInProgressReportsSlice.actions
