import { Route, Routes } from 'react-router-dom'
import { JobList } from './job-list'

export const Jobs = () => {
  return (
    <Routes>
      <Route
        path='*'
        element={<JobList />}
      />
    </Routes>
  )
}
