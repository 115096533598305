import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'

interface DeletePPSRState {
  loading: LoadingType;
  error: string;
}

const initialState: DeletePPSRState = {
  loading: 'idle',
  error: ''
}

export interface DeletePPSR {
  reportWorkspaceId: string;
  onSuccess?: () => void;
  onFinal?: () => void;
}

export const deletePPSR = createAsyncThunk<
  void,
  DeletePPSR,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('report-workspace/delete-ppsr', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    await axios.delete(`${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${request.reportWorkspaceId}/delete-ppsr`,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`
        },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  } finally {
    request.onFinal?.()
  }
})

export const deletePPSRSlice = createSlice({
  name: 'delete-ppsr',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(deletePPSR.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(deletePPSR.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(deletePPSR.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = deletePPSRSlice.actions
export default deletePPSRSlice.reducer
