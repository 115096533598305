import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios, { AxiosError } from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { RejectValue } from '../../../interfaces/reject-value'
import { BaseAssessmentRequestItem } from '../../interfaces/base-assessment-request-item'
import { getGenericErrorMessage } from '../../../utils/functions'

export interface GetUnderReviewAssessmentRequestsState {
  items: UnderReviewAssessmentRequestsItem[]
  pageSize: number
  pageNumber: number
  totalCount: number
  searchText: string
  tabFilter?: string
  analystId?: number
  loading: LoadingType
  error: string
}

export interface UnderReviewAssessmentRequestsItem extends BaseAssessmentRequestItem {
  dateFirstClarificationSent?: string
}

export interface GetUnderReviewAssessmentRequests {
  items: UnderReviewAssessmentRequestsItem[]
  pageNumber: number
  totalCount: number
}

export const DEFAULT_PAGE_SIZE = 10

const initialState: GetUnderReviewAssessmentRequestsState = {
  items: [],
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
  totalCount: 0,
  searchText: '',
  loading: 'idle',
  error: ''
}

export interface GetUnderReviewAssessmentRequestsParams {
  status: string
  analystId?: number
  customerId?: string
  entityAssessedId?: number
}

export const getUnderReviewAssessmentRequests = createAsyncThunk<
  GetUnderReviewAssessmentRequests,
  GetUnderReviewAssessmentRequestsParams,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: RejectValue
  }
>('report/getUnderReviewAssessmentRequests', async (request, thunkAPI) => {
  const { tokenState, getUnderReviewAssessmentRequestsState, appSettingsState } = thunkAPI.getState()
  const params = new URLSearchParams()
  const { pageNumber, pageSize, searchText, tabFilter, analystId } = getUnderReviewAssessmentRequestsState
  const showUnassigned = tabFilter === 'unassigned'
  params.append('pageNumber', pageNumber.toString())
  params.append('pageSize', pageSize.toString())
  params.append('assessmentRequestStatus', request.status)
  params.append('search', searchText)
  params.append('showUnassigned', showUnassigned.toString())
  params.append('customerId', request.customerId ?? '')
  params.append('entityAssessedId', request.entityAssessedId?.toString() ?? '')
  tabFilter === 'assignedTo' && !!analystId && params.append('analystId', analystId.toString())

  try {
    const response = await axios.get<GetUnderReviewAssessmentRequests>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests?${params.toString()}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const getUnderReviewAssessmentRequestsSlice = createSlice({
  name: 'get-under-review-assessment-requests',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
      state.loading = 'idle'
    },
    resetLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.loading = 'idle'
      state.error = ''
      if (action.payload) {
        state.pageSize = initialState.pageSize
        state.pageNumber = initialState.pageNumber
        state.searchText = initialState.searchText
        state.items = initialState.items
      }
    },
    setTabFilter: (state, action: PayloadAction<string>) => {
      state.tabFilter = action.payload
      state.loading = 'idle'
    },
    setShowAssignedToSelectedAnalyst: (state, action: PayloadAction<number | undefined>) => {
      state.analystId = action.payload
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUnderReviewAssessmentRequests.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getUnderReviewAssessmentRequests.fulfilled, (state, { payload }) => {
      state.items = payload.items
      state.pageNumber = payload.pageNumber
      state.totalCount = payload.totalCount
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getUnderReviewAssessmentRequests.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { setSearchText, setPageSize, setPageNumber, resetLoadingStatus, setTabFilter, setShowAssignedToSelectedAnalyst } = getUnderReviewAssessmentRequestsSlice.actions
