import { Route, Routes } from 'react-router-dom'
import { DashboardLayout } from '../../components/dashboard-layout'
import navigation from '../../_navMenu'
import { Customers } from './customers'
import { EntitiesAssessed } from './entity-assessed'
import { ProtectedRoute } from '../../components/protected-route'
import { AnilityScopes } from '../../interfaces/anility-scopes'

export const CrmTools = () => {
  return (
    <DashboardLayout mainContainerProps={{ p: 0, display: 'flex', flexGrow: 1 }}
      menuItems={navigation}>
      <Routes>
        <Route
          path='/customers/*'
          element={<ProtectedRoute permissions={[AnilityScopes.Read.Customers]}><Customers /></ProtectedRoute>}
        />
      </Routes>
      <Routes>
        <Route
          path='/entities-assessed/*'
          element={<ProtectedRoute permissions={[AnilityScopes.Read.EntityAssessed]}><EntitiesAssessed /></ProtectedRoute>}
        />
      </Routes>
    </DashboardLayout>
  )
}
