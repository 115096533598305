import React, { useEffect, useRef, useState } from 'react'
import { Alert, Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import JSONEditor, { JSONEditorOptions } from 'jsoneditor'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { getReportConfiguration } from './get-report-configuration-slice'
import { getReportConfigurationsList } from './get-report-configurations-list-slice'
import { AnilityBackdrop } from '../../../components/anility-backdrop'

import 'jsoneditor/dist/jsoneditor.css'

const ReportsWorkflow = () => {
  const jsonEditorRef = useRef<HTMLDivElement>(null)
  const jsonEditor = useRef<JSONEditor>(
    null
  ) as React.MutableRefObject<JSONEditor>
  const dispatch = useAppDispatch()
  const { getReportConfigurationState, getReportConfigurationListState } = useAppSelector(
    (state) => state
  )
  const [forceRefresh, setForceRefresh] = useState(0)
  const loading =
    getReportConfigurationState.loading === 'loading' || getReportConfigurationListState.loading === 'loading'
  const { config } = getReportConfigurationState
  const error = getReportConfigurationState.error
  const [selectedReport, setSelectedReport] = useState('')

  const resetJsonEditor = () => {
    const options: JSONEditorOptions = {
      mode: jsonEditor.current?.getMode() ?? 'tree',
      modes: ['code', 'form', 'text', 'tree', 'view', 'preview'] // allowed modes
    }
    if (!jsonEditorRef.current?.childElementCount) {
      jsonEditor.current = new JSONEditor(
        jsonEditorRef.current as HTMLDivElement,
        options
      )
    }
  }
  const expandSteps = () => {
    if (['tree', 'view', 'form'].includes(jsonEditor.current?.getMode())) {
      jsonEditor.current?.expand({
        path: ['Steps'],
        isExpand: true,
        recursive: false
      })
    }
  }

  useEffect(() => {
    if (getReportConfigurationListState.loading === 'idle') {
      resetJsonEditor()
      dispatch(getReportConfigurationsList())
    }
  }, [dispatch, getReportConfigurationListState.loading])

  useEffect(() => {
    if (getReportConfigurationListState.reportConfigurations.length > 0) {
      setSelectedReport(getReportConfigurationListState.reportConfigurations[0].id)
    }
  }, [getReportConfigurationListState.reportConfigurations])

  useEffect(() => {
    if (selectedReport) {
      dispatch(getReportConfiguration({ reportConfigurationId: selectedReport }))
    }
  }, [selectedReport, forceRefresh])

  useEffect(() => {
    resetJsonEditor()
    jsonEditor.current?.set(JSON.parse(config))
    expandSteps()
  }, [config, forceRefresh])

  function handleRefreshClicked () {
    if (jsonEditorRef.current?.childElementCount) {
      jsonEditorRef.current.removeChild(jsonEditorRef.current.children[0])
    }
    setForceRefresh(forceRefresh + 1)
  }

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedReport(event.target.value as string)
  }

  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <Card>
          <Box p={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack mb="20px" spacing={1.5}>
              <Typography variant='h3'>Reports Workflow</Typography>
              <Typography color={({ palette }) => palette.text.secondary}>Report workflow configuration:</Typography>
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedReport}
                label="Report Type"
                onChange={handleChange}
              >
                {getReportConfigurationListState.reportConfigurations.map((config) => (
                  <MenuItem key={config.id} value={config.id}>{config.reportType} ({config.visible ? 'Visible' : 'Hidden'})</MenuItem>
                ))}
              </Select>
            </FormControl>
            </Stack>
          </Box>
          <Box p={2} sx={{ flex: 1, position: 'relative' }}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <div
                  className="jsoneditor-react-container"
                  ref={jsonEditorRef}
                  style={{ height: '50vh' }}
                />
              </Grid>
              <Grid item container sx={{ marginTop: '2rem' }}>
                <Grid item xs={8}>
                  {error && (
                    <Alert severity="error">{error}</Alert>
                  )}
                </Grid>
                <Grid item container xs={4} justifyContent="flex-end">
                  <Button variant="contained" color="secondary" disabled={getReportConfigurationListState.loading !== 'loaded'} onClick={handleRefreshClicked}>Refresh</Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box >
      <AnilityBackdrop open={loading} />
    </>
  )
}

export default ReportsWorkflow
