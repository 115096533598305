import { AppBar, Box, Divider, Toolbar, Typography } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { UserMenu } from './user-menu'

type TopbarProps = {
  Open: boolean;
  OnClose?: () => void;
  OnDrawerToggleClick?: () => void;
};

export const Topbar = (props: TopbarProps) => {
  const { isAuthenticated, user } = useAuth0()
  if (!isAuthenticated) {
    return null
  }

  return props.Open
    ? (
      <AppBar
        position="relative"
        sx={{
          height: '64px', // fixed height for topbar
          backgroundColor: 'white',
          boxShadow: '0px 0px 0px 1px #DBE0E7'
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', columnGap: '1.25rem', paddingY: '0.75rem' }}>
          <IconButton
            size="large"
            edge="start"
            color="default"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={props.OnDrawerToggleClick}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}><Typography color={(theme) => theme.palette.text.primary}>Welcome <strong>{(user?.name || user?.email)}</strong></Typography></Box>
          <Divider orientation="vertical" flexItem />
          <UserMenu />
        </Toolbar>
      </AppBar>
      )
    : (
      <></>
      )
}
