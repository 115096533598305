import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'

export interface MarkWorkspaceAsDone {
  reportWorkspaceId: string;
  status: string;
  onSuccess?: () => void;
  onError?: (error: RejectValue) => void;
}

interface MarkWorkspaceAsDoneState {
  loading: LoadingType;
  error: string;
}

const initialState: MarkWorkspaceAsDoneState = {
  loading: 'idle',
  error: ''
}

export const markWorkspaceAsDone = createAsyncThunk<
  void,
  MarkWorkspaceAsDone,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: RejectValue;
  }
>('report-workspace/markWorkspaceAsDone', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()

  try {
    const data = { ...request, onSuccess: undefined }
    await axios.patch<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${request.reportWorkspaceId}`,
      data,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` }
      }
    )
    request.onSuccess?.()
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    let errorMsg = getGenericErrorMessage(appSettingsState)
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      errorMsg = errors.response.data.title
    }
    const errorDetail = {
      statusCode: errors.response?.status,
      message: errorMsg
    }
    request.onError?.(errorDetail)

    return thunkAPI.rejectWithValue(errorDetail)
  }
})

export const markWorkspaceAsDoneSlice = createSlice({
  name: 'mark-workspace-as-done',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(markWorkspaceAsDone.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(markWorkspaceAsDone.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(markWorkspaceAsDone.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message ?? action.error.message ?? ''
    })
  }
})

export const { resetLoadingStatus } = markWorkspaceAsDoneSlice.actions
export default markWorkspaceAsDoneSlice.reducer
