import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { getGenericErrorMessage } from '../../../utils/functions'
import { RejectValue } from '../../../interfaces/reject-value'

interface DeleteReportWorkspaceFileAttachmentState {
  loading: LoadingType
  error: string
}

const initialState: DeleteReportWorkspaceFileAttachmentState = {
  loading: 'idle',
  error: ''
}

interface DeleteReportWorkspaceFileAttachment {
  reportWorkspaceId: string
  fileName: string
  onSuccess?: () => void
}

export const deleteReportWorkspaceFileAttachment = createAsyncThunk<
  void,
  DeleteReportWorkspaceFileAttachment,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: RejectValue;
  }
>('report-workspace/delete-report-workspace-file-attachment', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()
  try {
    await axios.delete(`${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${request.reportWorkspaceId}/file-attachments`,
      {
        data: request,
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'application/json'
        },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    let errorMsg = getGenericErrorMessage(appSettingsState)
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      errorMsg = errors.response.data.title
    }
    const errorDetail = {
      statusCode: errors.response?.status,
      message: errorMsg
    }
    return thunkAPI.rejectWithValue(errorDetail)
  }
})

export const deleteReportWorkspaceFileAttachmentSlice = createSlice({
  name: 'delete-report-workspace-file-attachment',
  initialState,
  reducers: {
    resetDeleteReportWorkspaceFileAttachment: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(deleteReportWorkspaceFileAttachment.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(deleteReportWorkspaceFileAttachment.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(deleteReportWorkspaceFileAttachment.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetDeleteReportWorkspaceFileAttachment } = deleteReportWorkspaceFileAttachmentSlice.actions
export default deleteReportWorkspaceFileAttachmentSlice.reducer
