import { Cancel, CheckCircle } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'

export interface ValidationChecksProps {
  validationChecksPassed: boolean
}

export const ValidationChecks = ({
  validationChecksPassed
}: ValidationChecksProps) => {
  return (
    <Grid container alignItems="center" pb={1}>
      <Typography variant="h5" marginRight={0.5}>Validation Checks Passed:</Typography>
      {validationChecksPassed
        ? <CheckCircle fontSize='small' sx={(theme) => ({ color: theme.palette.success.main })} />
        : <Cancel fontSize='small' sx={(theme) => ({ color: theme.palette.error.main })} />}
    </Grid>
  )
}
