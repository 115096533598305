import { Grid, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { ReportWorkspaceDetails } from '../get-report-workspace-details-slice'

const SeqLogs = ({ reportWorkspaceDetails }: {
  reportWorkspaceDetails: ReportWorkspaceDetails
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Log Date</TableCell>
                <TableCell>Seq Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportWorkspaceDetails?.seqPermanentLinkLogs?.map((l) => (
                <TableRow key={`${l.logDate}-${l.seqLogPermanentLinks}`}>
                  <TableCell>{l.logDate}</TableCell>
                  <TableCell>
                    <Link target="_blank" href={l.seqLogPermanentLinks}>
                      {l.seqLogPermanentLinks}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  )
}

export default SeqLogs
