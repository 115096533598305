import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'

export interface RunReportWorkspaceInputStage {
  reportWorkspaceId: string;
  onSuccess?: () => void;
  onFinal?: () => void;
}

interface RunReportWorkspaceInputStageState {
  loading: LoadingType;
  error: string;
}

const initialState: RunReportWorkspaceInputStageState = {
  loading: 'idle',
  error: ''
}

export const runReportWorkspaceInputStage = createAsyncThunk<
  void,
  RunReportWorkspaceInputStage,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('report-workspace/input', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    await axios.post<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${request.reportWorkspaceId}/input`,
      {},
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  } finally {
    request.onFinal?.()
  }
})

export const runReportWorkspaceInputStageSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    resetInputStageLoadingStatus: (state) => {
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(runReportWorkspaceInputStage.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(runReportWorkspaceInputStage.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(runReportWorkspaceInputStage.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetInputStageLoadingStatus } = runReportWorkspaceInputStageSlice.actions
export default runReportWorkspaceInputStageSlice.reducer
