import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../utils/types'
import axios from 'axios'
import { AppDispatch, RootState } from '../../store/store'

interface AnalystUsersState {
  items: AnalystUsersItem[]
  loading: LoadingType
  error: string
}

export interface AnalystUsersItem {
  value: number
  label: string
}

const initialState: AnalystUsersState = {
  items: [],
  loading: 'idle',
  error: ''
}

export interface TeamMemberListItemPayload {
  onSuccess?: () => void
}
export const getAnalystUsers = createAsyncThunk<
AnalystUsersItem[],
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('assessmentRequest/getAnalystUsers', async (_, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<AnalystUsersItem[]>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/analyst-users`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )

    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getAnalystUsersSlice = createSlice({
  name: 'get-analyst-users',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalystUsers.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getAnalystUsers.fulfilled, (state, { payload }) => {
      state.items = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getAnalystUsers.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export default getAnalystUsersSlice.reducer
