import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { LoadingType } from '../../../utils/types'
import { AppDispatch, RootState } from '../../../store/store'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'

interface InviteEntityAssessedUserState {
  loading: LoadingType;
  error: string;
}

const initialState: InviteEntityAssessedUserState = {
  loading: 'idle',
  error: ''
}

interface InviteEntityAssessedUserParams {
  entityAssessedId: number,
  firstName: string
  lastName: string,
  email: string,
  onSuccess?: () => void
}

export const inviteEntityAssessedUser = createAsyncThunk<
  void,
  InviteEntityAssessedUserParams,
  {
    dispatch: AppDispatch
    state: RootState,
    rejectValue: RejectValue
  }
>(
  'customer/inviteEntityAssessedUser',
  async (request: InviteEntityAssessedUserParams, thunkAPI) => {
    const { tokenState, appSettingsState } = thunkAPI.getState()

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE}/ui/admin/entityAssessed/${request.entityAssessedId}/invite-entity-assessed-user`, request, {
        validateStatus: (status) => status < 400,
        headers: { Authorization: `Bearer ${tokenState.token}` }
      })
      request.onSuccess?.()
    } catch (error) {
      const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
      if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
        return thunkAPI.rejectWithValue({ message: errors.response.data.title })
      }

      return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
    }
  }
)

export const inviteEntityAssessedUserSlice = createSlice({
  name: 'invite-entity-assessed-user',
  initialState,
  reducers: {
    resetInviteEntityAssessedUser: (state) => {
      state.error = ''
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(inviteEntityAssessedUser.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(inviteEntityAssessedUser.fulfilled, (state) => {
      state.loading = 'loaded'
    })
    builder.addCase(inviteEntityAssessedUser.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetInviteEntityAssessedUser } = inviteEntityAssessedUserSlice.actions
export default inviteEntityAssessedUserSlice.reducer
