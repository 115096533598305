/* eslint-disable no-unused-vars */
import { Route, Routes } from 'react-router-dom'
import { DashboardLayout } from '../../components/dashboard-layout'
import navigation from '../../_navMenu'
import { ReportWorkspace } from './report-workspace'
import Summary from './summary'
import OrderedAssessments from './ordered-assessments/ordered-assessments'
import CompletedAssessments from './completed-assessments/completed-assessments'
import ExpiredAssessments from './expired-assessments/expired-assessments'
import OnHoldAssessments from './on-hold-assessments/on-hold-assessments'
import { UnderReview } from './under-review-assessments'

export const Report = () => {
  const menuItems = navigation
  return (
    <DashboardLayout mainContainerProps={{ p: 0, display: 'flex', flexGrow: 1 }}
      menuItems={menuItems}>
      <Routes>
        <Route
          path='/summary'
          element={<Summary />}
        />
        <Route
          path='/report-workspace/*'
          element={<ReportWorkspace />}
        />
        <Route
          path='/ordered-assessments'
          element={<OrderedAssessments />}
        />
        <Route
          path='/under-review-assessments/*'
          element={<UnderReview />}
        />
        <Route
          path='/completed-assessments'
          element={<CompletedAssessments />}
        />
        <Route
          path='/on-hold-assessments'
          element={<OnHoldAssessments />}
        />
        <Route
          path='/expired-assessments'
          element={<ExpiredAssessments />}
        />
      </Routes>
    </DashboardLayout>
  )
}
