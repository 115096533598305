import { Button, Dialog, DialogActions, DialogContent, DialogProps, Fab, Grid, Typography } from '@mui/material'
import MDEditor, { commands } from '@uiw/react-md-editor'
import { useEffect, useState } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { AnilityBackdrop } from './anility-backdrop'
import { formatDate, limitNumberOfLines, sanitizeBulletList } from '../utils/functions'
import rehypeExternalLinks from 'rehype-external-links'

interface SpecialRequirementsDialogProps extends DialogProps {
  title?: string
  lastUpdatedLabel?: string,
  lastUpdatedBy?: string,
  lastUpdatedOn?: string,
  actionButtonLabel?: string,
  value?: string,
  onActionButtonClick?: (value: string)=>void,
  editorLineCountLimit?: number,
  isLoading: boolean,
  isEditable?: boolean
}

export default function SpecialRequirementsDialog ({
  onActionButtonClick,
  lastUpdatedBy,
  lastUpdatedOn,
  isLoading,
  value,
  editorLineCountLimit,
  title,
  lastUpdatedLabel,
  actionButtonLabel,
  isEditable,
  ...dialogProps
}: SpecialRequirementsDialogProps) {
  const getProcessedDraftValue = () => value?.trim() ? value : '- '

  const [draftValue, setDraftValue] = useState(getProcessedDraftValue())
  const [previewValue, setPreviewValue] = useState(value ?? '')

  useEffect(() => {
    setDraftValue(getProcessedDraftValue())
    setPreviewValue(value ?? '')
  }, [value])

  const handleActionButtonClick = () => {
    onActionButtonClick?.(previewValue)
  }

  const handleEditorChange = (value: string | undefined) => {
    let processedValue = limitNumberOfLines(value!, editorLineCountLimit ?? 4).trimStart()

    if (processedValue === '-' || processedValue.trim() === '') {
      processedValue = '- '
    }

    if (!processedValue.startsWith('- ')) {
      if (processedValue.startsWith('-')) {
        processedValue = processedValue.replace('-', '- ')
      } else {
        processedValue = '- ' + processedValue
      }
    }

    setDraftValue(processedValue)
    setPreviewValue(sanitizeBulletList(processedValue))
  }

  return <>
    <Dialog fullWidth maxWidth="md"
      sx={{
        zIndex: 2,
        '& .MuiPaper-root[role=dialog]': {
          width: '700px'
        }
      }}
      {...(dialogProps)}
      className={`special-requirements-dialog
      ${dialogProps.className}`}
    >
      <Grid container flexDirection="column" alignItems="flex-end">
        <Fab style={{ borderRadius: 0, marginRight: '24px', marginTop: '16px' }} color="primary" size="small" onClick={(e) => dialogProps.onClose?.(e, 'backdropClick')}>
          <CloseRoundedIcon fontSize="small" />
        </Fab>
      </Grid>
      <DialogContent>
        <Grid container paddingY={1}>
          <Grid item xs={6} alignItems="center">
            <Typography variant="h2">{ title ?? 'Special Requirements'}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex' }} alignItems="flex-end" alignContent="flex-end" flexDirection="column">
            <Typography variant="subtitle2" color={(theme) => theme.palette.grey[600]}>{ lastUpdatedLabel ?? 'Last Updated' }:</Typography>
            <Typography variant="subtitle2">{ lastUpdatedOn != null ? formatDate(lastUpdatedOn, 'DD/MM/YYYY') : '--' } { lastUpdatedBy ?? '--'}</Typography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            '& .editor-editor ~ .editor-preview': {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            },
            '& .editor-editor': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0
            }
          }}
        >
          <MDEditor
            className="editor-preview"
            value={previewValue ?? ''}
            extraCommands={[]}
            preview="preview"
            textareaProps={{
              disabled: true
            }}
            height={200}
            hideToolbar
            data-color-mode="light"
            previewOptions={{
              rehypePlugins: [() => rehypeExternalLinks({ target: '_blank', rel: ['noopener', 'noreferrer'] })]
            }}
          />
          { (isEditable) && <MDEditor
            className="editor-editor"
            value={draftValue}
            height={150}
            onChange={handleEditorChange}
            textareaProps={{
              disabled: false
            }}
            preview="edit"
            data-color-mode="light"
            commands={[commands.bold, commands.italic, commands.strikethrough, commands.link, commands.code]}
            extraCommands={[]}
          /> }
        </Grid>
      </DialogContent>
      { (isEditable) &&
        <DialogActions sx={{ paddingY: '16px', justifyContent: 'center' }}>
          <Button variant="contained" disabled={draftValue === value} sx={{ minWidth: '318px' }} onClick={handleActionButtonClick}>{ actionButtonLabel ?? 'Save' }</Button>
        </DialogActions> }
    </Dialog>
    <AnilityBackdrop open={isLoading} />
  </>
}
