import { Route, Routes } from 'react-router-dom'
import ReportWorkspaceList from './report-workspace-list'
import ReportWorkspaceView from './report-workspace-view'

export const ReportWorkspace = () => {
  return (
    <Routes>
      <Route
        path='*'
        element={<ReportWorkspaceList />}
      />
      <Route
        path='/view/:id'
        element={<ReportWorkspaceView />}
      />
    </Routes>
  )
}
