import { AnilityScopes } from './interfaces/anility-scopes'
import { MenuListItemProps } from './components/menu-list-item'
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import PauseCircleOutline from '@mui/icons-material/PauseCircleOutline'
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import TimeOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import { Help } from '@mui/icons-material'

export interface IBadge {
  color: string;
  text: string;
}

const _navMenu: MenuListItemProps[] = [
  {
    type: 'NavGroup',
    title: 'Analyst Tools',
    permissions: [AnilityScopes.Read.Reports],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Summary',
    to: '/report/summary',
    icon: <ArticleOutlinedIcon sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Read.Reports],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Ordered',
    to: '/report/ordered-assessments',
    icon: <SystemUpdateAltOutlinedIcon sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Read.Reports],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Under Review',
    to: '/report/under-review-assessments',
    icon: <FindInPageIcon sx={{ color: 'white' }} />,
    state: { status: 'UnderReview' },
    permissions: [AnilityScopes.Read.Reports],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Clarifications Required',
    to: '/report/under-review-assessments',
    icon: <Help sx={{ color: 'white' }} />,
    state: { status: 'ClarificationsRequired' },
    permissions: [AnilityScopes.Read.Reports],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'In Progress',
    to: '/report/report-workspace',
    icon: <PublishedWithChangesOutlinedIcon sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Read.Reports],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Completed',
    to: '/report/completed-assessments',
    icon: <CheckBoxOutlinedIcon sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Read.Reports],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'On Hold',
    to: '/report/on-hold-assessments',
    icon: <PauseCircleOutline sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Read.Reports],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Expired / Declined',
    to: '/report/expired-assessments',
    icon: <TimeOffOutlinedIcon sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Read.Reports],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Analysts',
    to: '/analyst-tools/analysts',
    icon: <PieChartOutlineOutlinedIcon sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Read.AnalystUsers],
    condition: 'All',
    show: true
  },
  {
    type: 'NavGroup',
    title: 'CRM Tools',
    permissions: [AnilityScopes.Read.Customers, AnilityScopes.Read.EntityAssessed],
    condition: 'Any',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Customers',
    to: '/crm-tools/customers',
    icon: <GroupOutlinedIcon sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Read.Customers],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Entities Assessed',
    to: '/crm-tools/entities-assessed',
    icon: <BusinessOutlinedIcon sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Read.EntityAssessed],
    condition: 'All',
    show: true
  },
  {
    type: 'NavGroup',
    title: 'Developer Tools',
    permissions: [AnilityScopes.Read.ReportConfiguration, AnilityScopes.Write.Events],
    condition: 'Any',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Configuration',
    icon: <SettingsOutlinedIcon sx={{ color: 'white' }} />,
    to: '/developer-tools/report-configuration/reports-workflow',
    permissions: [AnilityScopes.Read.ReportConfiguration],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Base Models',
    to: '/developer-tools/base-models',
    icon: <AssignmentOutlinedIcon sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Read.ReportConfiguration],
    condition: 'All',
    show: true
  },
  {
    type: 'NavItem',
    title: 'Jobs',
    to: '/developer-tools/jobs',
    icon: <AssignmentOutlinedIcon sx={{ color: 'white' }} />,
    permissions: [AnilityScopes.Write.Events],
    condition: 'All',
    show: true
  }
]

export default _navMenu
