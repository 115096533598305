import { Alert, Grid, TextField, useTheme } from '@mui/material'
import { CustomDialogForm } from '../../../../components/custom-dialog-form'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { updateCustomerSubscriptionInformation } from './update-customer-subscription-information-slice'
import { AnilityBackdrop } from '../../../../components/anility-backdrop'
import { CustomerDetails, getCustomerDetails } from '../get-customer-details-slice'
import { formatDate } from '../../../../utils/functions'

interface CustomerSubscriptionInformationDialogProps {
  handleClose: () => void;
  customerDetails: CustomerDetails;
  open: boolean;
}

const CustomerSubscriptionInformationDialog = ({
  handleClose,
  open,
  customerDetails
}: CustomerSubscriptionInformationDialogProps) => {
  const [totalSubscribed, setTotalSubscribed] = useState(customerDetails.totalEntitiesSubscribed)
  const [renewalDate, setRenewalDate] = useState(formatDate(customerDetails.nextRenewDate, 'YYYY-MM-DD'))
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const { updateCustomerSubscriptionInformationState } = useAppSelector(state => state)
  const { error } = updateCustomerSubscriptionInformationState

  useEffect(() => {
    setTotalSubscribed(customerDetails.totalEntitiesSubscribed)
    setRenewalDate(formatDate(customerDetails.nextRenewDate, 'YYYY-MM-DD'))
  }, [customerDetails.id])

  const handleTotalSubscribedChange = (event: any) => {
    const inputValue = event.target.value
    const numericValue = inputValue.replace(/\D/g, '')

    if (/^0\d+$/.test(numericValue)) {
      setTotalSubscribed(0)
    } else {
      setTotalSubscribed(numericValue)
    }
  }

  const handleRenewalDateChange = (event: any) => {
    const inputValue = event.target.value
    setRenewalDate(inputValue)
  }

  const handleSubmit = () => {
    dispatch(updateCustomerSubscriptionInformation({
      nextRenewDate: renewalDate,
      totalEntitiesSubscribed: totalSubscribed,
      customerId: customerDetails.id,
      onSuccess: () => {
        dispatch(getCustomerDetails({
          customerId: customerDetails.id
        }))
        handleClose()
      }
    }))
  }

  return (
    <>
      <CustomDialogForm
        handleSubmit={handleSubmit}
        maxWidth={800}
        disablePrimary={!renewalDate}
        onClose={() => {
          handleClose()
        }}
        primaryText='Submit'
        title='Subscription Information'
        open={open}
        showCloseDialogIcon={true}
      >
        <Grid item container pt={1} pb={4}>
          <Grid typography='body1' item xs={12}>
            You may update the customer subscription details below
          </Grid>
        </Grid>
        <Grid container spacing={2} pb={1} pt={3}>
          <Grid item xs={12} pb={2}>
            <TextField
              sx={{ width: '80%' }}
              id="total-subscribed"
              label="Total Entities Subscribed"
              type="text"
              onChange={handleTotalSubscribedChange}
              value={totalSubscribed}
              InputLabelProps={{
                shrink: true,
                style: { color: theme.palette.primary.main }
              }}
            />
          </Grid>
          <Grid item xs={12} pb={2}>
            <TextField
              sx={{ width: '80%' }}
              id="date"
              label="Next Renewal Date"
              value={renewalDate}
              onChange={handleRenewalDateChange}
              type="date"
              InputProps={{
                endAdornment: null
              }}
              InputLabelProps={{
                shrink: true,
                style: { color: theme.palette.primary.main }
              }} />

          </Grid>
        </Grid>
        {error && (
          <Grid container pt={1} pb={1}>
            <Grid item xs={12} display='flex' justifyContent='center'>
              <Alert sx={{ width: '80%' }} severity='error'>{error}</Alert>
            </Grid>
          </Grid>
        )}
      </CustomDialogForm>
      <AnilityBackdrop open={updateCustomerSubscriptionInformationState.loading === 'loading'} />
    </>
  )
}

export default CustomerSubscriptionInformationDialog
