import React, { ChangeEvent, MouseEvent } from 'react'

import { TablePagination as MuiTablePagination } from '@mui/material'

export interface Page {
  totalCount: number
  size: number
  number: number
}

export interface TablePaginationProps {
  page: Page;
  onTablePageChange: (e: MouseEvent<HTMLButtonElement> | null, pageNumber: number) => void
  onTablePageSizeChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const TablePagination = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) => {
  return (
    <MuiTablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={page?.totalCount}
      rowsPerPage={page.size}
      page={page.number - 1}
      onPageChange={onTablePageChange}
      onRowsPerPageChange={onTablePageSizeChange}
    />
  )
}
