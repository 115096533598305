import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { getGenericErrorMessage } from '../../../utils/functions'
import { RejectValue } from '../../../interfaces/reject-value'

interface SetSelectedEntityAssessedState {
  loading: LoadingType;
  error: string;
}

const initialState: SetSelectedEntityAssessedState = {
  loading: 'idle',
  error: ''
}

interface SetSelectedEntityAssessedParams {
  entityAssessedId: number
  entityAssessedUserId: number
  onSuccess?: () => void
}

export const setSelectedEntityAssessed = createAsyncThunk<
  void,
  SetSelectedEntityAssessedParams,
  {
    dispatch: AppDispatch
    state: RootState,
    rejectValue: RejectValue
  }
>(
  'entityAssessed/setSelected',
  async (request: SetSelectedEntityAssessedParams, thunkAPI) => {
    const { tokenState, appSettingsState } = thunkAPI.getState()

    try {
      await axios.patch<{}>(`${process.env.REACT_APP_API_BASE}/ui/admin/entityAssessed/set-selected-entity-assessed/${request.entityAssessedId}/entity-assessed-user/${request.entityAssessedUserId}`, request, {
        validateStatus: (status) => status < 400,
        headers: { Authorization: `Bearer ${tokenState.token}` }
      })
      request.onSuccess?.()
    } catch (error) {
      const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
      if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
        return thunkAPI.rejectWithValue({ message: errors.response.data.title })
      }

      return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
    }
  }
)

export const setSelectedEntityAssessedSlice = createSlice({
  name: 'set-selected-entity-assessed',
  initialState,
  reducers: {
    resetSelectedEntityAssessed: (state) => {
      state.error = ''
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedEntityAssessed.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(setSelectedEntityAssessed.fulfilled, (state) => {
      state.loading = 'loaded'
    })
    builder.addCase(setSelectedEntityAssessed.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export default setSelectedEntityAssessedSlice.reducer
export const { resetSelectedEntityAssessed } = setSelectedEntityAssessedSlice.actions
