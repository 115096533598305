import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { LoadingType } from '../../../utils/types'
import { AppDispatch, RootState } from '../../../store/store'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'

export interface SetAssessmentRequestSubmissionReview {
  assessmentRequestId: number
  isSubmissionReviewCompleted: boolean
  onSuccess?: () => void
}

interface SetAssessmentRequestSubmissionReviewState {
  loading: LoadingType
  error: string
}

const initialState: SetAssessmentRequestSubmissionReviewState = {
  loading: 'idle',
  error: ''
}

export const setAssessmentRequestSubmissionReviewCompleted = createAsyncThunk<
  void,
  SetAssessmentRequestSubmissionReview,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: RejectValue;
  }
>('report/setAssessmentRequestSubmissionReviewCompleted', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()
  try {
    await axios.patch<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}/submission-review-completed`,
      request,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'application/json'
        },
        validateStatus: (status) => status < 400
      }
    )

    request.onSuccess?.()
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const setAssessmentRequestSubmissionReviewCompletedSlice = createSlice({
  name: 'set-assessment-request-submission-review-completed',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setAssessmentRequestSubmissionReviewCompleted.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(setAssessmentRequestSubmissionReviewCompleted.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(setAssessmentRequestSubmissionReviewCompleted.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetLoadingStatus } = setAssessmentRequestSubmissionReviewCompletedSlice.actions
export default setAssessmentRequestSubmissionReviewCompletedSlice.reducer
