import { useState, useEffect } from 'react'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { useAppSelector } from './hooks'
import { useAuth0 } from '@auth0/auth0-react'

interface IJWT {
  permissions: string[];
}

export const AllPermissions = (permissions: boolean[]) => permissions.reduce((p, c) => p && c === true, true)
export const AnyPermissions = (permissions: boolean[]) => permissions.reduce((p, c) => p || c === true, false)

export function usePermission (permission: string[]) {
  const { isLoading } = useAuth0()
  const [hasPermission, setHasPermission] = useState(
    Array<boolean>(permission.length)
  )
  const token = useAppSelector((state) => state.tokenState.token)

  useEffect(() => {
    if (isLoading) return
    if (token !== '') {
      const jwt: IJWT = jwt_decode(token)
      const result = permission.map(
        (p) => jwt.permissions.findIndex((jp) => jp === p) >= 0
      )
      setHasPermission(result)
    }
  }, [isLoading, token])

  return hasPermission
}
