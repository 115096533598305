import { Alert, AlertColor, Card, CardContent, Stack } from '@mui/material'

export interface Notification {
  message: string
  severity: AlertColor
}

interface NotificationMessageProps {
  items: Notification[]
}

export const NotificationMessage = ({
  items
}: NotificationMessageProps) => {
  if (!items.length) {
    return null
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={0.5} pl={2} pr={2} pt={1} pb={0}>
          {items.map((i) => <Alert key={i.message} severity={i.severity}>{i.message}</Alert>)}
        </Stack>
      </CardContent>
    </Card >
  )
}
