import { Route, Routes } from 'react-router-dom'
import { UnderReviewAssessments } from './under-review-assessments'
import SubmissionReviewWorkspace from './submission-review-workspace'
import FinancialExtractionTool from './financial-extraction-tool/financial-extraction-tool'

export const UnderReview = () => {
  return (
    <Routes>
      <Route
        path='*'
        element={<UnderReviewAssessments />}
      />
      <Route
        path='/workspace/:id'
        element={<SubmissionReviewWorkspace />}
      />
      <Route
        path='/workspace/:id/extraction-tool'
        element={<FinancialExtractionTool />}
      />
    </Routes>
  )
}
