import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface SidebarState {
    sidebarUnfoldable: boolean
    sidebarShow: boolean
}

// Define the initial state using that type
const initialState: SidebarState = {
  sidebarUnfoldable: false,
  sidebarShow: true
}

export const sidebarSlice = createSlice({
  name: 'sidebarSlice',
  initialState,
  reducers: {
    setSidebar: (state, action: PayloadAction<SidebarState>) => {
      state.sidebarShow = action.payload.sidebarShow
      state.sidebarUnfoldable = action.payload.sidebarUnfoldable
    }
  }
})

export const { setSidebar } = sidebarSlice.actions

export default sidebarSlice.reducer
