import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { usePermission } from '../hooks/use-permission'
import { AnilityScopes } from '../interfaces/anility-scopes'
import { useAuth0 } from '@auth0/auth0-react'
import { useRole } from '../hooks/use-role'
import { isCustomerAccountManager } from '../utils/functions'
import { useLogger } from '../hooks/use-logger'
import { useSearchParams } from 'react-router-dom'

export const AppIndex = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, error } = useAuth0()
  const [adminDashboard] = usePermission([
    AnilityScopes.Read.AdminDashboard
  ])
  const roles = useRole()
  const navigate = useNavigate()
  const isCustomerAccountManagerOnly = isCustomerAccountManager(roles)
  const { logger } = useLogger('Admin.AppIndex')
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (isLoading) return

    if (error && error.message === 'Please verify your email before logging in.') {
      navigate('/verify-email')
      return
    }
    if (error && error.message === 'client requires organization membership, but user does not belong to any organization') {
      navigate('/login-error')
      return
    }

    const invitation = searchParams.get('invitation') ?? undefined
    const organization = searchParams.get('organization') ?? undefined

    if (!isAuthenticated) {
      logger?.info('User is not authenticated, redirecting to login page', {})
      loginWithRedirect(
        {
          redirectUri: window.location.origin,
          appState: { returnTo: window.location.pathname },
          organization,
          invitation
        })
    }

    if (adminDashboard === undefined) return
    // redirect based on permission scope
    if (adminDashboard) {
      if (isCustomerAccountManagerOnly) {
        navigate('/crm-tools/customers')
      } else {
        navigate('/report/summary')
      }
    } else {
      navigate('/unauthorized')
    }
  }, [isLoading, isAuthenticated, adminDashboard, navigate, loginWithRedirect, isCustomerAccountManagerOnly, logger])
  return <></>
}
