import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../../store/store'
import { LoadingType } from '../../../../utils/types'

interface UploadCustomerSubsidiarySignedOutreachLetterState {
  loading: LoadingType;
  error: string;
}

const initialState: UploadCustomerSubsidiarySignedOutreachLetterState = {
  loading: 'idle',
  error: ''
}

export interface UploadCustomerSubsidiarySignedOutreachLetter {
  customerSubsidiaryId: number;
  customerId: number;
  signedOutreachLetter: File;
  onSuccess?: () => void;
  onFinal?: () => void;
}

export const uploadCustomerSubsidiarySignedOutreachLetter = createAsyncThunk<
  void,
  UploadCustomerSubsidiarySignedOutreachLetter,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('customer/uploadCustomerSubsidiarySignedOutreachLetter', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    const formData = new FormData()
    formData.append('signedOutreachLetter', request.signedOutreachLetter)
    formData.append('customerSubsidiaryId', request.customerSubsidiaryId.toString())
    await axios.post<null>(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${request.customerId}/subsidiary/${request.customerSubsidiaryId}/upload-signed-outreach-letter`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  } finally {
    request.onFinal?.()
  }
})

export const uploadCustomerSubsidiarySignedOutreachLetterSlice = createSlice({
  name: 'upload-customer-subsidiary-signed-outreach-letter',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(uploadCustomerSubsidiarySignedOutreachLetter.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(uploadCustomerSubsidiarySignedOutreachLetter.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(uploadCustomerSubsidiarySignedOutreachLetter.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = uploadCustomerSubsidiarySignedOutreachLetterSlice.actions
export default uploadCustomerSubsidiarySignedOutreachLetterSlice.reducer
