import { Typography } from '@mui/material'
import React from 'react'
import { TerminatePage } from '../components/terminate-page'

export function WidthError () {
  return <TerminatePage title='It looks like we don’t support this screen size.'>
    <Typography variant="body1">
    The minimum screen size is 1024 pixels. Please try using a larger screen or try zooming out your browser.
    </Typography>
  </TerminatePage>
}
