import { FormControlLabel, Grid, Link, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { CustomDialogForm } from '../../../../components/custom-dialog-form'
import { runReportWorkspaceStages, resetLoadingStatus as resetRunReportWorkspaceStagesLoadingStatus } from '../run-report-workspace-stages-slice'
import { getReportWorkspacesDetails } from '../get-report-workspace-details-slice'
import { getReportWorkspaces } from '../get-report-workspace-list-slice'
import { generateReportWorkspacePdf, resetLoadingStatus as resetGenerateReportWorkspacePdfLoadingStatus } from '../generate-report-workspace-pdf-slice'
import { secureFileDownload } from '../../../../utils/functions'
import { useEventListener } from '../../../../hooks/use-event-listener'
import { ReportWorkspaceAttachFilesDialog } from './report-workspace-attach-files-dialog'
import { ActionMenu, createContextMenuItem } from '../../../../components/action-menu'
import { AnilityScopes as FiableScopes } from '../../../../interfaces/anility-scopes'
import { MenuListItemProps } from '../../../../components/menu-list-item'

type AutoDownloadStatus = 'Idle' | 'In Progress' | 'Complete'

export const ReportWorkspaceViewActions = () => {
  const { id } = useParams()
  const { getReportWorkspaceDetailsState, tokenState } =
    useAppSelector((state) => state)

  const [showReportWorkspaceAttachFilesDialog, setShowReportWorkspaceAttachFilesDialog] = useState(false)
  const [showReloadDataConfirmationDialog, setShowReloadDataConfirmationDialog] = useState(false)
  const [autoDownloadStatus, setAutoDownloadStatus] = useState<AutoDownloadStatus>('Idle')
  const dispatch = useAppDispatch()

  const { reportWorkspaceDetails } = getReportWorkspaceDetailsState
  const assessmentLocked = !reportWorkspaceDetails || reportWorkspaceDetails.status === 'Done' || reportWorkspaceDetails.isDeleted
  const { token } = tokenState
  const hasAdditionalDocuments = reportWorkspaceDetails?.reportAdditionalDocuments?.some(doc => doc.documentLink) ?? false

  useEffect(() => {
    if (id !== reportWorkspaceDetails?.id) {
      setAutoDownloadStatus('Idle')
      return
    }
    if (autoDownloadStatus === 'In Progress' && reportWorkspaceDetails?.reportUrl) {
      setAutoDownloadStatus('Complete')
      secureFileDownload(reportWorkspaceDetails.reportUrl, token)
    }
  }, [reportWorkspaceDetails?.reportUrl, id, reportWorkspaceDetails?.id, autoDownloadStatus])

  const handleReloadDataClick = useCallback(() => {
    if (!reportWorkspaceDetails || assessmentLocked) {
      return
    }

    if (!reportWorkspaceDetails.reportUrl) {
      handleReloadDataConfirmationClick()
    } else {
      setShowReloadDataConfirmationDialog(true)
    }
  }, [reportWorkspaceDetails])

  const handleReloadWorkspaceData = (event: MessageEvent) => {
    if (event.origin === reportWorkspaceDetails?.baseUrl && event.data.methodToCall === 'reloadReportWorkspaceData') {
      handleReloadDataClick()
    }
  }

  useEventListener('message', handleReloadWorkspaceData)

  const handleGeneratePdfClick = useCallback(() => {
    if (!reportWorkspaceDetails) {
      return
    }

    dispatch(generateReportWorkspacePdf({
      htmlEndpoint: reportWorkspaceDetails.htmlEndpoint,
      reportWorkspaceId: reportWorkspaceDetails.id,
      additionalDocuments: reportWorkspaceDetails.reportAdditionalDocuments,
      onSuccess: () => {
        setAutoDownloadStatus('In Progress')
      },
      onFinal: () => {
        dispatch(resetRunReportWorkspaceStagesLoadingStatus())
        dispatch(getReportWorkspacesDetails({
          reportWorkspaceId: reportWorkspaceDetails.id
        }))
        dispatch(
          getReportWorkspaces({})
        )
      }
    }))
  }, [reportWorkspaceDetails])

  const handleDownloadPdfClick = useCallback((reportUrl?: string) => {
    if (!reportUrl) {
      return
    }

    secureFileDownload(reportUrl, token)
  }, [token])

  const handleReloadDataConfirmationClick = useCallback(() => {
    if (!reportWorkspaceDetails) {
      return
    }

    setShowReloadDataConfirmationDialog(false)
    dispatch(runReportWorkspaceStages({
      actionName: 'reload data',
      reportWorkspaceId: reportWorkspaceDetails.id,
      stages: ['input'],
      onFinal: () => {
        dispatch(resetGenerateReportWorkspacePdfLoadingStatus())
        dispatch(getReportWorkspacesDetails({
          reportWorkspaceId: reportWorkspaceDetails.id
        }))
        dispatch(
          getReportWorkspaces({})
        )
      }
    }))
  }, [reportWorkspaceDetails])

  const handleAttachFilesClick = () => setShowReportWorkspaceAttachFilesDialog(true)

  const handleAttachFilesClose = () => setShowReportWorkspaceAttachFilesDialog(false)

  const reloadDataConfirmationDialog = (
    <CustomDialogForm
      open={showReloadDataConfirmationDialog}
      primaryText="Yes"
      handleSubmit={handleReloadDataConfirmationClick}
      onClose={() => setShowReloadDataConfirmationDialog(false)}
      title="Do you want to proceed with reloading the data?"
      secondaryText="Cancel">
      The PDF has been generated for this report. Reloading input value invalidates that report.
    </CustomDialogForm>
  )

  const generateReportDocumentList = (): MenuListItemProps[] => {
    const permission: string[] = [FiableScopes.Read.Reports]
    const availableReports: MenuListItemProps[] = []

    if (reportWorkspaceDetails?.reportUrl) {
      availableReports.push(createContextMenuItem(reportWorkspaceDetails.reportType, <></>, permission, () => handleDownloadPdfClick(reportWorkspaceDetails.reportUrl), true))
    }

    const reportWorkspaceDocuments = reportWorkspaceDetails?.reportAdditionalDocuments?.filter(_ => _.documentLink)

    reportWorkspaceDocuments?.forEach(item =>
      availableReports.push(createContextMenuItem(item.documentName, <></>, permission, () => handleDownloadPdfClick(item.documentLink), true))
    )

    return availableReports
  }

  const handleDownloadIconClick = () => {
    if (!hasAdditionalDocuments) {
      handleDownloadPdfClick(reportWorkspaceDetails?.reportUrl)
    }
  }

  return (
    <>
      <Grid container columnGap={4} justifyContent="flex-start" alignItems="flex-start" mt={2}>
        <FormControlLabel
          label={<Typography variant="label" color={(theme) => theme.palette.text.secondary}>Customer Assessment Request</Typography>}
          labelPlacement="top"
          sx={{
            margin: 0,
            alignItems: 'flex-start',
            '& .MuiLink-root': {
              marginTop: 1
            }
          }}
          control={
            <Link mb={1} target="_blank" href={`${reportWorkspaceDetails?.requestLink}?source=report_workspace`} rel="opener">
              {`${reportWorkspaceDetails?.customerName}`}
            </Link>}
        />
        <FormControlLabel
          label={<Typography variant="label" color={(theme) => theme.palette.text.secondary}>Entity Assessed Response</Typography>}
          labelPlacement="top"
          sx={{
            margin: 0,
            alignItems: 'flex-start',
            '& .MuiLink-root': {
              marginTop: 1
            }
          }}
          control={
            <Link mb={1} target="_blank" href={`${reportWorkspaceDetails?.responseLink}?source=report_workspace`} rel="opener">
              {`${reportWorkspaceDetails?.targetCompanyName}`}
            </Link>}
        />
        <FormControlLabel
          label={<Typography variant="label" color={(theme) => theme.palette.text.secondary}>Workspace Model</Typography>}
          labelPlacement="top"
          sx={{
            margin: 0,
            alignItems: 'flex-start',
            '& .MuiLink-root': {
              marginTop: 1
            }
          }}
          control={
            <Link mb={1} target="_blank" href={reportWorkspaceDetails?.inProgressModelLink}>
              <OpenInNewOutlinedIcon fontSize="small" color="primary" />
            </Link>}
        />
        <FormControlLabel
          label={<Typography variant="label" color={(theme) => theme.palette.text.secondary}>Generate PDF</Typography>}
          labelPlacement="top"
          sx={{
            margin: 0,
            alignItems: 'flex-start',
            '& .MuiLink-root': {
              marginTop: 1
            }
          }}
          title="Generate PDF"
          style={{ pointerEvents: 'none' }}
          control={
            <Grid mb={1} container columnGap={2}>
              <Link
                component="button"
                disabled={assessmentLocked}
                onClick={handleGeneratePdfClick}
                style={{ pointerEvents: 'auto' }}
                display="flex"
                alignItems="center"
                title="Generate PDF"
              >
                <PictureAsPdfOutlinedIcon fontSize="small" color={!assessmentLocked ? 'primary' : 'disabled'} />
              </Link>
              <Link
                component="button"
                onClick={handleDownloadIconClick}
                style={{ pointerEvents: 'auto' }}
                display="flex"
                alignItems="center"
                title="Download PDF"
              >
                {!hasAdditionalDocuments
                  ? <CloudDownloadOutlinedIcon fontSize="small" color={reportWorkspaceDetails?.reportUrl ? 'primary' : 'disabled'} />
                  : <ActionMenu
                    buttonIcon={<CloudDownloadOutlinedIcon fontSize='small' color={reportWorkspaceDetails?.reportUrl ? 'primary' : 'disabled'} />}
                    isContextMenuDisabled={false}
                    contextMenuIndicatorMarginLeft={1.3}
                    index={0}
                    iconButtonStyle={{ padding: 0 }}
                    menuListItems={generateReportDocumentList()}
                    itemLength={generateReportDocumentList().length} />}

              </Link>
            </Grid>}
        />
        <FormControlLabel
          label={<Typography variant="label" color={(theme) => theme.palette.text.secondary}>Attach Files</Typography>}
          labelPlacement="top"
          sx={{
            margin: 0,
            alignItems: 'flex-start',
            '& .MuiLink-root': {
              marginTop: 1
            }
          }}
          title="Attach files that will be visible to customers"
          control={
            <Link
              mb={1}
              component="button"
              onClick={handleAttachFilesClick}>
              <CloudUploadOutlinedIcon fontSize="small" color="primary" />
            </Link>}
        />
        <FormControlLabel
          label={<Typography variant="label" color={(theme) => theme.palette.text.secondary}>Reload Data</Typography>}
          labelPlacement="top"
          sx={{
            margin: 0,
            alignItems: 'flex-start',
            '& .MuiLink-root': {
              marginTop: 1
            }
          }}
          title="Reload data input in the model"
          control={
            <Link
              mb={1}
              component="button"
              disabled={assessmentLocked}
              onClick={handleReloadDataClick}>
              <RefreshOutlinedIcon fontSize="small" color={!assessmentLocked ? 'primary' : 'disabled'} />
            </Link>}
        />
        <FormControlLabel
          label={<Typography variant="label" color={(theme) => theme.palette.text.secondary}>Assigned to</Typography>}
          labelPlacement="top"
          style={{ pointerEvents: 'none' }}
          sx={{
            margin: 0,
            alignItems: 'flex-start'
          }}
          title="Assigned to"
          control={
            <Typography mt={1} mb={1}>{reportWorkspaceDetails?.assignedAnalyst?.label}</Typography>
          }
        />
        <FormControlLabel
          label={<Typography variant="label" color={(theme) => theme.palette.text.secondary}>Report Type</Typography>}
          labelPlacement="top"
          style={{ pointerEvents: 'none' }}
          sx={{
            margin: 0,
            alignItems: 'flex-start'
          }}
          title="Report Type"
          control={
            <Typography mt={1}>{reportWorkspaceDetails?.reportConfigurationId}</Typography>
          }
        />
      </Grid>
      {reloadDataConfirmationDialog}
      {!!reportWorkspaceDetails && (
        <ReportWorkspaceAttachFilesDialog
          reportWorkspaceId={reportWorkspaceDetails.id} assessmentLocked={assessmentLocked}
          open={showReportWorkspaceAttachFilesDialog} onClose={handleAttachFilesClose} />
      )}
    </>
  )
}
