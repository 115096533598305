import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios, { AxiosError } from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { BaseAssessmentRequestItem } from '../../interfaces/base-assessment-request-item'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'

export interface GetCompletedAssessmentRequestsState {
  items: CompletedAssessmentRequestsItem[]
  pageSize: number
  pageNumber: number
  totalCount: number
  searchText: string
  loading: LoadingType
  error: string
}

export interface CompletedAssessmentRequestsItem extends BaseAssessmentRequestItem{
  timeElapsedSinceOrdering: number
  entityAssessedResponseTime: number
  timeTakenToCreateAssessment: number
  timeTakenToDeliverAssessment: number
  assessmentReportLink: string
  completedDateUtc: string
  reportWorkspaceId: string
}

export interface GetCompletedAssessmentRequests {
  items: CompletedAssessmentRequestsItem[]
  pageNumber: number
  totalCount: number
}

export const DEFAULT_PAGE_SIZE = 10

const initialState: GetCompletedAssessmentRequestsState = {
  items: [],
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
  totalCount: 0,
  searchText: '',
  loading: 'idle',
  error: ''
}

export interface GetCompletedAssessmentRequestsParams {
  status: string
  customerId?: string
  entityAssessedId?: number
}

export const getCompletedAssessmentRequests = createAsyncThunk<
  GetCompletedAssessmentRequests,
  GetCompletedAssessmentRequestsParams,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: RejectValue
  }
>('report/getCompletedAssessmentRequests', async (request, thunkAPI) => {
  const { tokenState, getCompletedAssessmentRequestsState, appSettingsState } = thunkAPI.getState()
  const params = new URLSearchParams()
  const { pageNumber, pageSize, searchText } = getCompletedAssessmentRequestsState

  params.append('pageNumber', pageNumber.toString())
  params.append('pageSize', pageSize.toString())
  params.append('assessmentRequestStatus', request.status)
  params.append('search', searchText)
  params.append('customerId', request.customerId ?? '')
  params.append('entityAssessedId', request.entityAssessedId?.toString() ?? '')

  try {
    const response = await axios.get<GetCompletedAssessmentRequests>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests?${params.toString()}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const getCompletedAssessmentRequestsSlice = createSlice({
  name: 'get-completed-assessment-requests',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
      state.loading = 'idle'
    },
    resetLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.loading = 'idle'
      state.error = ''
      if (action.payload) {
        state.pageSize = initialState.pageSize
        state.pageNumber = initialState.pageNumber
        state.searchText = initialState.searchText
        state.items = initialState.items
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCompletedAssessmentRequests.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getCompletedAssessmentRequests.fulfilled, (state, { payload }) => {
      state.items = payload.items
      state.pageNumber = payload.pageNumber
      state.totalCount = payload.totalCount
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getCompletedAssessmentRequests.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { setSearchText, setPageSize, setPageNumber, resetLoadingStatus } = getCompletedAssessmentRequestsSlice.actions
