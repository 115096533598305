import React from 'react'
import { Card, CardContent, Typography, Grid, Box } from '@mui/material'
import './terminate-page.scss'
import { Logo } from './logo.component'

interface TerminatePageProps {
    title?: string,
    heroImage?: string
    heroImageHires?: string
    heroImageWidth?: number,
    heroImageHeight?: number,
    hideLogo?: boolean
}

export function TerminatePage (props: React.PropsWithChildren<TerminatePageProps>) {
  return <div className="terminate-page-container">
        <Grid
            flexDirection="column"
            alignItems="stretch"
            sx={{ margin: '0 auto', alignItems: 'center', display: 'flex', flexDirection: 'column' }}
        >
            {(props.hideLogo === undefined || !props.hideLogo) && <Box sx={{ padding: '50px 50px 0px 50px' }}>
                <Logo sx={{ margin: 'auto' }} p={4} size="large" />
            </Box>}
            <Grid
                container
                direction="column"
                justifyContent="center"
                sx={{ height: '100%', justifyContent: 'flex-start', width: '100%' }}
            >
                <Grid container item flexDirection="row" justifyContent="center">
                    <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                        <Card variant="outlined" sx={{ minHeight: 330, width: '100%' }}>
                            <CardContent className="card-content"
                                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    pt={4}
                                >
                                    <img
                                        src={props.heroImage === undefined ? '/img/hour-glass.png' : props.heroImage}
                                        srcSet={props.heroImageHires === undefined ? '/img/hour-glass-hires.png 2x' : props.heroImageHires + ' 2x'}
                                        width={props.heroImageWidth === undefined ? '132' : props.heroImageWidth}
                                        height={props.heroImageHeight === undefined ? '155' : props.heroImageHeight} alt="loading logo" />
                                </Box>
                                {!!props.title && <Box display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    justifySelf="center"
                                    pl={4} pr={4} pt={4} sx={{ textAlign: 'center' }}>
                                    <Typography variant="h2">
                                        {props.title}
                                    </Typography>
                                </Box>}
                                {!!props.children && <Box display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    justifySelf="center"
                                    pl={3} pr={3} pt={2} pb={2} sx={{ textAlign: 'center' }}>
                                    <div>
                                        {props.children}
                                    </div>
                                </Box>}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
}
