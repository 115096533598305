import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { formatDate, formatDuration, secureFileDownload } from '../../../utils/functions'
import { ExpiredAssessmentRequestsItem, getExpiredAssessmentRequests, resetLoadingStatus, setPageNumber, setPageSize, setSearchText } from './get-expired-assessment-requests-slice'
import PaginatedTable, { Column } from '../../../components/paginated-table'
import { MenuListItemProps } from '../../../components/menu-list-item'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { InternalNotes } from '../../../components/internal-notes'
import { setSelectedAssessmentInternalNote } from '../../../components/set-internal-notes-slice'
import { usePermission } from '../../../hooks/use-permission'
import { ActionMenu } from '../../../components/action-menu'
import { InternalNoteContextMenuItem, ReactivateDeclinedAssessmentContextMenuItem } from '../components/assessments-action-menu'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { AssessmentDialog } from '../../interfaces/assessment-dialog'
import { reactivateDeclinedAssessmentRequest } from './reactivate-declined-assessment-request-slice'
import { AssessmentFooter } from '../components/assessment-footer'
import { TableHeader } from '../../../components/table-header'

export const columns: Column[] = [
  {
    id: 'assignedAnalyst',
    label: 'Assigned To',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'orderDate',
    label: 'Date Ordered',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'reportConfigurationId',
    label: 'Assessment Type',
    minWidth: 90
  },
  {
    id: 'customer',
    label: 'Customer Name',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'entityAssessed',
    label: 'Entity Assessed Name',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'contractName',
    label: 'Assessment Subject',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 150
  },
  {
    id: 'nominatedContactName',
    label: 'Contact Name',
    minWidth: 125,
    className: 'wrapped-text'
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    minWidth: 125
  },
  {
    id: 'nominatedUserEmailAddress',
    label: 'Email Address',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'timeElapsedSinceOrdering',
    label: 'Time Elapsed Since Ordering',
    minWidth: 150,
    className: 'wrapped-text'
  }
]

const ExpiredAssessments = () => {
  const dispatch = useAppDispatch()
  const { getExpiredAssessmentRequestsState, reactivateDeclinedAssessmentRequestState, tokenState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount, searchText } = getExpiredAssessmentRequestsState
  const loading = getExpiredAssessmentRequestsState.loading === 'loading' || reactivateDeclinedAssessmentRequestState.loading === 'loading'

  const [localSearch, setLocalSearch] = useState(searchText)
  const [showInternalNotes, setShowInternalNotes] = useState(false)
  const [writeInternalNotes, writeAssessment] = usePermission([AnilityScopes.Write.InternalNotes, AnilityScopes.Write.Assessment])
  const [showReactiveConfirmationDialog, setShowReactiveConfirmationDialog] = useState<AssessmentDialog>(
    {
      show: false,
      assessmentRequestId: undefined,
      assessmentSubject: ''
    }
  )

  useEffect(() => {
    dispatch(resetLoadingStatus(true))
  }, [])

  useEffect(() => {
    if (getExpiredAssessmentRequestsState.loading === 'idle') {
      dispatch(getExpiredAssessmentRequests({
        status: 'Expired'
      }))
    }
  }, [getExpiredAssessmentRequestsState.loading])

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(event.target.value)
  }

  const handleResetSearch = () => {
    setLocalSearch('')
    dispatch(setSearchText(''))
  }

  const handleSearch = () => {
    dispatch(setSearchText(localSearch))
  }

  const formattedExpiredAssessments = items.map((item) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      assignedAnalyst: item.assignedAnalyst?.label,
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering)
    }
  })

  const handleInternalNotesClick = (item: ExpiredAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const handleReactivateDeclinedAssessmentClick = useCallback(() => {
    if (!showReactiveConfirmationDialog.assessmentRequestId) {
      return
    }

    dispatch(reactivateDeclinedAssessmentRequest({
      assessmentRequestId: showReactiveConfirmationDialog.assessmentRequestId,
      onSuccess: () => {
        dispatch(getExpiredAssessmentRequests({
          status: 'Expired'
        }))
        setShowReactiveConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })
      }
    }))
  }, [showReactiveConfirmationDialog.assessmentRequestId])

  const generateContextMenu = (item: ExpiredAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReactivateDeclinedAssessmentContextMenuItem({
        show: writeAssessment && item.status === 'Declined',
        onClick: () => setShowReactiveConfirmationDialog({
          show: true,
          assessmentRequestId: item.id,
          assessmentSubject: item.contractName
        })
      })
    ]
  }

  const page = { totalCount, size: pageSize, number: pageNumber }

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()

    params.append('search', searchText)
    params.append('status', 'Expired')

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/export?${params.toString()}`, tokenState.token)
  }

  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <PaginatedTable
          columns={columns}
          items={formattedExpiredAssessments}
          headerComponent={
            <TableHeader
              title="Expired / Declined"
              description="Assessments that have been declined by entity assessed or expired by analyst"
              searchValue={localSearch}
              onSearchTextChange={handleSearchTextChange}
              onSearchClick={handleSearch}
              onResetSearchClick={handleResetSearch}
              onExportButtonClick={handleExportButtonClick}
            />
          }
          footerComponent={
            <AssessmentFooter
              page={page}
              onTablePageChange={handlePageChange}
              onTablePageSizeChange={handlePageSizeChange}
            />
          }
          renderMenuRowActions={(item, index, itemLength) => {
            return (
              <ActionMenu
                isContextMenuDisabled={false}
                contextMenuIndicatorMarginLeft={0.5}
                menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
            )
          }}
        />
      </Box >
      {writeInternalNotes && <InternalNotes open={showInternalNotes} handleClose={() => setShowInternalNotes(false)} />}
      <AnilityBackdrop open={loading} />
      {writeAssessment && showReactiveConfirmationDialog.show && <CustomDialogForm
        open={showReactiveConfirmationDialog.show}
        maxWidth={500}
        primaryText="Yes"
        handleSubmit={handleReactivateDeclinedAssessmentClick}
        onClose={() => setShowReactiveConfirmationDialog({
          show: false,
          assessmentRequestId: undefined,
          assessmentSubject: ''
        })}
        title="Do you want to proceed with reactivating this declined assessment?"
        secondaryText="Cancel">
        <Typography component='p' pb={1}>This assessment will revert to its original status.</Typography>
        <Typography component='span'>
          Assessment Subject: &nbsp;
          <Typography component='span' variant='h5'>
            {showReactiveConfirmationDialog?.assessmentSubject}
          </Typography>
        </Typography>
      </CustomDialogForm>}
    </>
  )
}

export default ExpiredAssessments
