import { Alert, Box, Grid, Typography } from '@mui/material'
import { CustomDialogForm } from './custom-dialog-form'
import { useEffect, useState, MouseEvent } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { AnilityBackdrop } from './anility-backdrop'
import { setInternalNotes } from './set-internal-notes-slice'
import MDEditor, { PreviewType, commands } from '@uiw/react-md-editor'
import { getAssessmentRequestsInfo } from '../pages/crm-tools/customers/get-assessment-requests-info-slice'

interface InternalNotesProps {
  handleClose: () => void
  open: boolean
}

export const InternalNotes = ({
  handleClose,
  open
}: InternalNotesProps) => {
  const dispatch = useAppDispatch()
  const { setInternalNotesState, getAssessmentRequestsInfoState } = useAppSelector(state => state)
  const { selectedInternalNote, error } = setInternalNotesState
  const { assessmentRequestInfo } = getAssessmentRequestsInfoState
  const [internalNote, setInternalNote] = useState('')
  const [localInternalNote, setLocalInternalNote] = useState('')
  const [editorType, setEditorType] = useState<PreviewType>('preview')
  const defaultText = '- '
  const handleSubmit = () => {
    dispatch(setInternalNotes({
      assessmentRequestId: setInternalNotesState.selectedInternalNote!,
      internalNotes: localInternalNote,
      onSuccess: () => {
        dispatch(getAssessmentRequestsInfo({
          assessmentRequestId: selectedInternalNote!,
          onSuccess: () => {
            setEditorType('preview')
            setLocalInternalNote('')
          }
        }))
      }
    }))
  }

  const toggleEditorMode = (event: MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget.tagName.toLowerCase() !== 'textarea') {
      setEditorType('edit')
    }
  }

  const toolbarCommands = [
    commands.codePreview,
    commands.codeEdit,
    commands.fullscreen
  ]

  useEffect(() => {
    if (selectedInternalNote) {
      dispatch(getAssessmentRequestsInfo({
        assessmentRequestId: selectedInternalNote!
      }))
    }
  }, [selectedInternalNote])
  useEffect(() => {
    if (assessmentRequestInfo) {
      setInternalNote(assessmentRequestInfo?.internalNotes!)
    }
  }, [assessmentRequestInfo])

  return (
    <>
      <CustomDialogForm
        handleSubmit={handleSubmit}
        minWidth={700}
        disablePrimary={!localInternalNote}
        onClose={() => {
          handleClose()
          setEditorType('preview')
          setLocalInternalNote('')
        }}
        primaryText='Save'
        title='Internal notes'
        open={open}
        showCloseDialogIcon={true}
        applyFixedWidth={true}
      >
        <Grid container rowGap={2} pb={1} pt={3}>
          <Grid item container xs={12} rowGap={1}>
            <Grid item xs={12} data-color-mode="light">
              <Typography variant='h5' pb={2} align='left'>Previous Notes</Typography>
              <Box className='scroll-down-container'>
                <MDEditor
                  value={internalNote}
                  extraCommands={[]}
                  preview='preview'
                  textareaProps={{
                    disabled: true
                  }}
                  height={200}
                  hideToolbar
                />
              </Box>
            </Grid>
            <Grid item xs={12} data-color-mode="light" pt={2}>
              <Box onClick={toggleEditorMode}>
                <Typography variant='h5' pb={2} align='left'>New Note</Typography>
                <MDEditor
                  value={localInternalNote || defaultText}
                  extraCommands={toolbarCommands}
                  preview={editorType}
                  height={150}
                  onChange={(val) => {
                    setLocalInternalNote(val!)
                  }}
                  textareaProps={{
                    disabled: false
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {error && (
          <Grid container pt={1} pb={1}>
            <Grid item xs={12} display='flex' justifyContent='center'>
              <Alert sx={{ width: '80%' }} severity='error'>{error}</Alert>
            </Grid>
          </Grid>
        )}
      </CustomDialogForm>
      <AnilityBackdrop open={setInternalNotesState.loading === 'loading' || getAssessmentRequestsInfoState.loading === 'loading'} />

    </>
  )
}
