import * as yup from 'yup'
import { CustomerSubsidiaryDetails } from '../pages/crm-tools/customers/customer-subsidiary-details/customer-subsidiary'

export const customerSubsidiaryDetailValidationSchema = yup.object<Partial<Record<keyof CustomerSubsidiaryDetails, yup.AnySchema>>>({
  name: yup.string().required('Please enter subsidiary name'),
  domainName: yup.string().required('Please enter domain name').matches(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/, 'Please enter a valid domain'),
  isSsoEnabled: yup.boolean(),
  auth0ConnectionId: yup.string()
    .when('isSsoEnabled', {
      is: true,
      then: yup.string()
        .required('Please enter Enterprise Connection')
        .trim()
    })
})
