import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { getGenericErrorMessage } from '../../../utils/functions'

interface ConflictError {
  message: string;
}

interface ResendInviteCustomerUserState {
  loading: LoadingType;
  error: string;
}

const initialState: ResendInviteCustomerUserState = {
  loading: 'idle',
  error: ''
}

interface ResendInviteCustomerUserParams {
  customerUserId: number;
  onSuccess?: () => void
}

export const resendInviteCustomerUser = createAsyncThunk<
  void,
  ResendInviteCustomerUserParams,
  {
    dispatch: AppDispatch
    state: RootState,
    rejectValue: ConflictError
  }
>(
  'customer/resendInviteCustomerUser',
  async (request: ResendInviteCustomerUserParams, thunkAPI) => {
    const { tokenState, appSettingsState } = thunkAPI.getState()

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/resend-invite-customer-user/${request.customerUserId}`, request, {
        validateStatus: (status) => status < 400,
        headers: { Authorization: `Bearer ${tokenState.token}` }
      })
      request.onSuccess?.()
    } catch (error) {
      const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
      if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
        return thunkAPI.rejectWithValue({ message: errors.response.data.title })
      }

      return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
    }
  }
)

export const resendInviteCustomerUserSlice = createSlice({
  name: 'resend-invite-customer-user',
  initialState,
  reducers: {
    resetResendInviteCustomerUser: (state) => {
      state.error = ''
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resendInviteCustomerUser.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(resendInviteCustomerUser.fulfilled, (state) => {
      state.loading = 'loaded'
    })
    builder.addCase(resendInviteCustomerUser.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export default resendInviteCustomerUserSlice.reducer
export const { resetResendInviteCustomerUser } = resendInviteCustomerUserSlice.actions
