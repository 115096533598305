import React from 'react'
import { Box, Card, CardContent, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'

interface ActionStepProps {
  stepLabel: string
  title: string
  items?: string[] | CustomElement[]
  endControl?: React.ReactElement
}

export interface CustomElement {
  id: number
  element: any
}

export const ActionStep = ({
  stepLabel,
  title,
  items,
  endControl,
  children
}: React.PropsWithChildren<ActionStepProps>) => {
  return (
    <Card>
      <CardContent>
        <Box pl={2} pt={2} pr={2}>
          <Grid container columnGap={2}>
            <Grid item justifyContent="flex-start">
              <Typography variant="label" color={(theme) => theme.palette.text.secondary}>{stepLabel}</Typography>
            </Grid>
            <Grid container item xs justifyContent="flex-start" flexDirection="column">
              <Typography variant="label" color={(theme) => theme.palette.text.primary} fontWeight={700}>{title}</Typography>
              <List sx={{
                color: (theme) => theme.palette.text.secondary,
                listStyleType: 'disc',
                pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item'
                }
              }}>
                {items?.map((item: string | CustomElement) => {
                  const key = typeof item === 'object' ? item.id : item
                  const value = typeof item === 'object' ? item.element : item
                  return (<ListItem key={key} sx={{ p: 0 }}>
                    <ListItemText sx={{
                      '& .MuiListItemText-primary': {
                        color: (theme) => theme.palette.text.secondary,
                        fontWeight: '400',
                        padding: 0
                      }
                    }}>{value}</ListItemText>
                  </ListItem>)
                })}
              </List>
              {children}
            </Grid>
            {endControl && (<Grid container item xs={2.5} justifyContent="flex-end" alignItems="flex-start">
              {endControl}
            </Grid>)}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}
