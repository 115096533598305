import React, { ChangeEvent, useEffect, useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import Box from '@mui/material/Box'
import { TabTypes } from './entity-assessed-tabs'
import { useAppDispatch } from '../../../../hooks/hooks'
import { setSearchText as setOrderedSearchText, resetLoadingStatus as resetOrderedLoadingStatus } from '../../../report/ordered-assessments/get-ordered-assessment-requests-slice'
import { setSearchText as setUnderReviewSearchText, resetLoadingStatus as resetUnderReviewLoadingStatus } from '../../../report/under-review-assessments/get-under-review-assessment-requests-slice'
import { setSearchText as setInProgressSearchText, resetLoadingStatus as resetInProgressLoadingStatus } from '../../../report/report-workspace/get-report-workspace-list-slice'
import { setSearchText as setCompletedSearchText, resetLoadingStatus as resetCompletedLoadingStatus } from '../../../report/completed-assessments/get-completed-assessment-requests-slice'
import { setSearchText as setOnHoldSearchText, resetLoadingStatus as resetOnHoldLoadingStatus } from '../../../report/on-hold-assessments/get-on-hold-assessment-requests-slice'
import { setSearchText as setExpiredSearchText, resetLoadingStatus as resetExpiredLoadingStatus } from '../../../report/expired-assessments/get-expired-assessment-requests-slice'
import { usePermission } from '../../../../hooks/use-permission'
import { AnilityScopes } from '../../../../interfaces/anility-scopes'
import { InternalNotes } from '../../../../components/internal-notes'
import { EntityAssessedOrderedAssessments } from './entity-assessed-ordered-assessments'
import { EntityAssessedInProgressReports } from './entity-assessed-in-progress-reports'
import { EntityAssessedCompletedAssessments } from './entity-assessed-completed-assessments'
import TabPanel from '../../../../components/tab-panel'
import { EntityAssessedUnderReviewAssessments } from './entity-assessed-under-review-assessments'
import { EntityAssessedOnHoldAssessments } from './entity-assessed-on-hold-assessments'
import { EntityAssessedExpiredAssessments } from './entity-assessed-expired-assessments'

interface EntityAssessedDetailsAssessmentProps {
  entityAssessedId: number
}

const EntityAssessedDetailsAssessment = ({ entityAssessedId }: EntityAssessedDetailsAssessmentProps) => {
  const dispatch = useAppDispatch()

  const [selectedTab, setSelectedTab] = useState<string>(TabTypes.Ordered)
  const [orderedAssessmentsSearchText, setOrderedAssessmentSearchText] = useState<string>('')
  const [underReviewAssessmentsSearchText, setUnderReviewAssessmentSearchText] = useState<string>('')
  const [clarificationsRequiredAssessmentsSearchText, setClarificationsRequiredAssessmentSearchText] = useState<string>('')
  const [inProgressAssessmentsSearchText, setInProgressAssessmentSearchText] = useState<string>('')
  const [completedAssessmentsSearchText, setCompletedAssessmentSearchText] = useState<string>('')
  const [onHoldAssessmentsSearchText, setOnHoldAssessmentSearchText] = useState<string>('')
  const [expiredAssessmentsSearchText, setExpiredAssessmentSearchText] = useState<string>('')
  const [showInternalNotes, setShowInternalNotes] = useState(false)
  const [writeInternalNotes] = usePermission([AnilityScopes.Write.InternalNotes])

  const resetTabLoadingStates = () => {
    setOrderedAssessmentSearchText('')
    dispatch(resetOrderedLoadingStatus(true))

    setUnderReviewAssessmentSearchText('')
    setClarificationsRequiredAssessmentSearchText('')
    dispatch(resetUnderReviewLoadingStatus(true))

    setInProgressAssessmentSearchText('')
    dispatch(resetInProgressLoadingStatus(true))

    setCompletedAssessmentSearchText('')
    dispatch(resetCompletedLoadingStatus(true))

    setOnHoldAssessmentSearchText('')
    dispatch(resetOnHoldLoadingStatus(true))

    setExpiredAssessmentSearchText('')
    dispatch(resetExpiredLoadingStatus(true))
  }

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue)
    resetTabLoadingStates()
  }

  const handleOrderedAssessmentSearchClick = () => {
    dispatch(setOrderedSearchText(orderedAssessmentsSearchText))
  }
  const handleOrderedAssessmentSearchResetClick = () => {
    setOrderedAssessmentSearchText('')
    dispatch(setOrderedSearchText(''))
  }
  const handleOrderedAssessmentSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOrderedAssessmentSearchText(event.target.value)
  }

  const handleUnderReviewSearchClick = () => {
    dispatch(setUnderReviewSearchText(underReviewAssessmentsSearchText))
  }
  const handleUnderReviewSearchResetClick = () => {
    setUnderReviewAssessmentSearchText('')
    dispatch(setUnderReviewSearchText(''))
  }
  const handleUnderReviewSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUnderReviewAssessmentSearchText(event.target.value)
  }

  const handleClarificationsRequiredSearchClick = () => {
    dispatch(setUnderReviewSearchText(clarificationsRequiredAssessmentsSearchText))
  }
  const handleClarificationsRequiredSearchResetClick = () => {
    setClarificationsRequiredAssessmentSearchText('')
    dispatch(setUnderReviewSearchText(''))
  }
  const handleClarificationsRequiredSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setClarificationsRequiredAssessmentSearchText(event.target.value)
  }

  const handleInProgressSearchClick = () => {
    dispatch(setInProgressSearchText(inProgressAssessmentsSearchText))
  }
  const handleInProgressSearchResetClick = () => {
    setInProgressAssessmentSearchText('')
    dispatch(setInProgressSearchText(''))
  }
  const handleInProgressSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInProgressAssessmentSearchText(event.target.value)
  }

  const handleCompletedAssessmentSearchClick = () => {
    dispatch(setCompletedSearchText(completedAssessmentsSearchText))
  }
  const handleCompletedAssessmentSearchResetClick = () => {
    setCompletedAssessmentSearchText('')
    dispatch(setCompletedSearchText(''))
  }
  const handleCompletedAssessmentSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCompletedAssessmentSearchText(event.target.value)
  }

  const handleOnHoldSearchClick = () => {
    dispatch(setOnHoldSearchText(onHoldAssessmentsSearchText))
  }
  const handleOnHoldSearchResetClick = () => {
    setOnHoldAssessmentSearchText('')
    dispatch(setOnHoldSearchText(''))
  }
  const handleOnHoldSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOnHoldAssessmentSearchText(event.target.value)
  }

  const handleExpiredSearchClick = () => {
    dispatch(setExpiredSearchText(expiredAssessmentsSearchText))
  }
  const handleExpiredSearchResetClick = () => {
    setExpiredAssessmentSearchText('')
    dispatch(setExpiredSearchText(''))
  }
  const handleExpiredSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExpiredAssessmentSearchText(event.target.value)
  }

  useEffect(() => {
    resetTabLoadingStates()
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="entity-assessed-assessment-tabs">
          <Tab label="Ordered" value={TabTypes.Ordered} sx={{ textTransform: 'none', width: 150 }} />
          <Tab label="Under Review" value={TabTypes.UnderReview} sx={{ textTransform: 'none', width: 150 }} />
          <Tab label="Clarifications Required" value={TabTypes.ClarificationsRequired} sx={{ textTransform: 'none', width: 150 }} />
          <Tab label="In Progress" value={TabTypes.InProgress} sx={{ textTransform: 'none', width: 150 }} />
          <Tab label="Completed" value={TabTypes.Completed} sx={{ textTransform: 'none', width: 150 }} />
          <Tab label="On Hold" value={TabTypes.OnHold} sx={{ textTransform: 'none', width: 150 }} />
          <Tab label="Expired / Declined" value={TabTypes.Expired} sx={{ textTransform: 'none', width: 150 }} />
        </Tabs>
      </Box>
      <TabPanel selectedTab={selectedTab} value={TabTypes.Ordered}>
        <EntityAssessedOrderedAssessments
          entityAssessedId={entityAssessedId}
          searchValue={orderedAssessmentsSearchText}
          onSearchClick={handleOrderedAssessmentSearchClick}
          onResetSearchClick={handleOrderedAssessmentSearchResetClick}
          onSearchTextChange={handleOrderedAssessmentSearchTextChange}
          setShowInternalNotes={setShowInternalNotes}
        />
      </TabPanel>
      <TabPanel selectedTab={selectedTab} value={TabTypes.UnderReview}>
        <EntityAssessedUnderReviewAssessments
          status="UnderReview"
          entityAssessedId={entityAssessedId}
          searchValue={underReviewAssessmentsSearchText}
          onSearchClick={handleUnderReviewSearchClick}
          onResetSearchClick={handleUnderReviewSearchResetClick}
          onSearchTextChange={handleUnderReviewSearchTextChange}
          setShowInternalNotes={setShowInternalNotes}
        />
      </TabPanel>
      <TabPanel selectedTab={selectedTab} value={TabTypes.ClarificationsRequired}>
        <EntityAssessedUnderReviewAssessments
          status="ClarificationsRequired"
          entityAssessedId={entityAssessedId}
          searchValue={clarificationsRequiredAssessmentsSearchText}
          onSearchClick={handleClarificationsRequiredSearchClick}
          onResetSearchClick={handleClarificationsRequiredSearchResetClick}
          onSearchTextChange={handleClarificationsRequiredSearchTextChange}
          setShowInternalNotes={setShowInternalNotes}
        />
      </TabPanel>
      <TabPanel selectedTab={selectedTab} value={TabTypes.InProgress}>
        <EntityAssessedInProgressReports
          entityAssessedId={entityAssessedId}
          searchValue={inProgressAssessmentsSearchText}
          onSearchClick={handleInProgressSearchClick}
          onResetSearchClick={handleInProgressSearchResetClick}
          onSearchTextChange={handleInProgressSearchTextChange}
          setShowInternalNotes={setShowInternalNotes}
        />
      </TabPanel>
      <TabPanel selectedTab={selectedTab} value={TabTypes.Completed}>
        <EntityAssessedCompletedAssessments
          entityAssessedId={entityAssessedId}
          searchValue={completedAssessmentsSearchText}
          onSearchClick={handleCompletedAssessmentSearchClick}
          onResetSearchClick={handleCompletedAssessmentSearchResetClick}
          onSearchTextChange={handleCompletedAssessmentSearchTextChange}
          setShowInternalNotes={setShowInternalNotes}
        />
      </TabPanel>
      <TabPanel selectedTab={selectedTab} value={TabTypes.OnHold}>
        <EntityAssessedOnHoldAssessments
          entityAssessedId={entityAssessedId}
          searchValue={onHoldAssessmentsSearchText}
          onSearchClick={handleOnHoldSearchClick}
          onResetSearchClick={handleOnHoldSearchResetClick}
          onSearchTextChange={handleOnHoldSearchTextChange}
          setShowInternalNotes={setShowInternalNotes}
        />
      </TabPanel>
      <TabPanel selectedTab={selectedTab} value={TabTypes.Expired}>
        <EntityAssessedExpiredAssessments
          entityAssessedId={entityAssessedId}
          searchValue={expiredAssessmentsSearchText}
          onSearchClick={handleExpiredSearchClick}
          onResetSearchClick={handleExpiredSearchResetClick}
          onSearchTextChange={handleExpiredSearchTextChange}
          setShowInternalNotes={setShowInternalNotes}
        />
      </TabPanel>
      {writeInternalNotes && <InternalNotes open={showInternalNotes} handleClose={() => setShowInternalNotes(false)} />}
    </Box>
  )
}

export default EntityAssessedDetailsAssessment
