import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { LoadingType } from '../../../utils/types'
import { AppDispatch, RootState } from '../../../store/store'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'

interface UpdateEntityAssessedSpecialRequirementsState {
  loading: LoadingType;
  error: string;
}

const initialState: UpdateEntityAssessedSpecialRequirementsState = {
  loading: 'idle',
  error: ''
}

interface UpdateEntityAssessedSpecialRequirementsParams {
  entityAssessedId: number
  specialRequirements?: string
  onSuccess?: () => void
}

export const updateEntityAssessedSpecialRequirements = createAsyncThunk<
  void,
  UpdateEntityAssessedSpecialRequirementsParams,
  {
    dispatch: AppDispatch
    state: RootState,
    rejectValue: RejectValue
  }
>(
  'entityAssessed/updateEntityAssessedSpecialRequirements',
  async (request: UpdateEntityAssessedSpecialRequirementsParams, thunkAPI) => {
    const { tokenState, appSettingsState } = thunkAPI.getState()

    try {
      await axios.patch(`${process.env.REACT_APP_API_BASE}/ui/admin/entityAssessed/${request.entityAssessedId}/special-requirements`, request, {
        validateStatus: (status) => status < 400,
        headers: { Authorization: `Bearer ${tokenState.token}` }
      })
      request.onSuccess?.()
    } catch (error) {
      const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
      if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
        return thunkAPI.rejectWithValue({ message: errors.response.data.title })
      }

      return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
    }
  }
)

export const updateEntityAssessedSpecialRequirementsSlice = createSlice({
  name: 'update-entity-assessed-special-requirements',
  initialState,
  reducers: {
    resetUpdateEntityAssessedSpecialRequirements: (state) => {
      state.error = ''
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateEntityAssessedSpecialRequirements.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(updateEntityAssessedSpecialRequirements.fulfilled, (state) => {
      state.loading = 'loaded'
    })
    builder.addCase(updateEntityAssessedSpecialRequirements.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message ?? ''
    })
  }
})

export const { resetUpdateEntityAssessedSpecialRequirements } = updateEntityAssessedSpecialRequirementsSlice.actions
export default updateEntityAssessedSpecialRequirementsSlice.reducer
