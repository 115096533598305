import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { LoadingType } from '../../../utils/types'
import { AppDispatch, RootState } from '../../../store/store'
import { getGenericErrorMessage } from '../../../utils/functions'
import { RejectValue } from '../../../interfaces/reject-value'

export interface SetAssessmentRequestFinancialStatementPreparation {
  assessmentRequestId: number
  isFinancialStatementPrepared: boolean
  onSuccess?: () => void
}

interface SetAssessmentRequestFinancialStatementPreparationState {
  loading: LoadingType
  error: string
}

const initialState: SetAssessmentRequestFinancialStatementPreparationState = {
  loading: 'idle',
  error: ''
}

export const setAssessmentRequestFinancialStatementPreparation = createAsyncThunk<
  void,
  SetAssessmentRequestFinancialStatementPreparation,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: RejectValue;
  }
>('report/setAssessmentRequestFinancialStatementPreparation', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()
  try {
    await axios.patch<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}/financial-statement-preparation`,
      request,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'application/json'
        },
        validateStatus: (status) => status < 400
      }
    )

    request.onSuccess?.()
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const setAssessmentRequestFinancialStatementPreparationSlice = createSlice({
  name: 'set-assessment-request-financial-statement-preparation',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setAssessmentRequestFinancialStatementPreparation.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(setAssessmentRequestFinancialStatementPreparation.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(setAssessmentRequestFinancialStatementPreparation.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetLoadingStatus } = setAssessmentRequestFinancialStatementPreparationSlice.actions
export default setAssessmentRequestFinancialStatementPreparationSlice.reducer
