import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'

export interface RunReportWorkspaceOutputStage {
  reportWorkspaceId: string;
  onSuccess?: () => void;
  onFinal?: () => void;
}

interface RunReportWorkspaceOutputStageState {
  loading: LoadingType;
  error: string;
}

const initialState: RunReportWorkspaceOutputStageState = {
  loading: 'idle',
  error: ''
}

export const runReportWorkspaceOutputStage = createAsyncThunk<
  void,
  RunReportWorkspaceOutputStage,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('report-workspace/save', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    await axios.post<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${request.reportWorkspaceId}/output`,
      {},
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  } finally {
    request.onFinal?.()
  }
})

export const runReportWorkspaceOutputStageSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(runReportWorkspaceOutputStage.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(runReportWorkspaceOutputStage.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(runReportWorkspaceOutputStage.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = runReportWorkspaceOutputStageSlice.actions
export default runReportWorkspaceOutputStageSlice.reducer
