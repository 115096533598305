import humanizeDuration from 'humanize-duration'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import axios from 'axios'
import showdown from 'showdown'
import { AnilityRoles } from '../interfaces/anility-roles'
import { AppSettingsState } from '../components/admin-app-settings-slice'

dayjs.extend(advancedFormat)

export const formatDate = (date?: string, format?: string) => {
  return date ? dayjs(date).format(format || 'YYYY/MM/DD') : ''
}

export const formatDuration = (milliSeconds: number) => {
  const result = humanizeDuration(milliSeconds, { units: ['h', 'm'], round: true, largest: 2 })
  return result
}

export const isAdmin = (roles: string[]) => {
  return roles.includes(AnilityRoles.Admin)
}

export const isSuperAnalyst = (roles: string[]) => {
  return roles.includes(AnilityRoles.SuperAnalyst)
}

export const isCustomerAccountManager = (roles: string[]) => {
  return roles.includes(AnilityRoles.CustomerAccountManager)
}

export const hasAnalystRole = (roles: string[]) => {
  return roles.includes(AnilityRoles.Analyst)
}

export const isAnalystRoleOnly = (roles: string[]) => {
  return hasAnalystRole(roles) && !(isAdmin(roles) || isSuperAnalyst(roles))
}

export const secureFileDownload = async (url: string, token: string) => {
  try {
    const result = await axios.get<Blob>(url,
      {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob'
      }
    )
    const filename = result.headers['content-disposition']!
      .split('filename=')[1]
      .split(';')[0]
    const href = URL.createObjectURL(result.data)
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', filename.replaceAll('"', ''))
    document.body.appendChild(link)
    link.click()
    // cleanup
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  } catch (e) {
    if (e instanceof Error || e instanceof ErrorEvent) {
      console.error('Download failed for {url}', {
        url
      })
    }
  }
}

export const extractEmailDomain = (email: string) => {
  return email ? email.split('@')[1] : ''
}

export const isEmailOutsideValidDomains = (emailDomain: string, validDomains: string[]) => {
  const domainRegex = /^[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return !!emailDomain &&
      emailDomain.includes('.') &&
      emailDomain.split('.').length > 1 && domainRegex.test(emailDomain) &&
      validDomains.length > 0 && !validDomains.includes(emailDomain)
}

export const limitNumberOfLines = (str: string, limit: number) => {
  const lines = str.split(/\r?\n/)

  const split = lines
    .slice(0, limit)

  return split.join('\r\n')
}

export const removeEmptyEntries = (str: string, defaultAnalystComments: string) => {
  const split = str.split(/\r?\n/)
    .filter(l => l.trim() && l.trim() !== defaultAnalystComments.trim())
  return split.join('\r\n')
}

export const sanitizeBulletList = (value: string): string => {
  const lines = value.split(/\r?\n/)
    .filter(line => !['', '-'].includes(line.trim()))
    .map(line => {
      line = line.trim()
      if (line.startsWith('- ')) {
        return line
      } else if (line.startsWith('-')) {
        return line.replace('-', '- ')
      }
      return '- ' + line
    })
  return lines.join('\r\n')
}

export const getLines = (str: string): string[] => {
  return str
    .split(/\r?\n/)
}

export const getGenericErrorMessage = (appSettingsState: AppSettingsState) => {
  const { settings } = appSettingsState
  return `An unknown error occurred. Please contact ${settings?.supportEmail}`
}

export const markdownToHtml = (text: string) => {
  const converter = new showdown.Converter({
    openLinksInNewWindow: true,
    strikethrough: true
  })

  // convert the markdown text to html
  return converter.makeHtml(text)
}
