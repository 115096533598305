import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../store/store'
import { LoadingType } from '../utils/types'

interface AdminAppSettings {
  currentEnvironment: string;
  alaresEnabled: boolean;
  asicEnabled: boolean;
  seniorAnalystCalendlyUrl: string;
  productVersion: string;
  seqServerUrl: string;
  seqApiKey: string;
  financialExtractionToolUrl: string;
  supportEmail: string
}

export interface AppSettingsState {
  settings: AdminAppSettings | undefined,
  loading: LoadingType,
  error: string
}

const initialState: AppSettingsState = {
  settings: undefined,
  loading: 'idle',
  error: ''
}

export const getAdminAppSettings = createAsyncThunk<AdminAppSettings, void, {
  dispatch: AppDispatch,
  state: RootState
}>(
  'ui/settings', async (_, thunkAPI) => {
    const { tokenState } = thunkAPI.getState()
    if (!tokenState.token) {
      return thunkAPI.rejectWithValue({ error: 'error' })
    }

    try {
      const response = await axios.get<AdminAppSettings>(`${process.env.REACT_APP_API_BASE}/ui/settings`,
        {
          validateStatus: (status) => status < 500,
          headers: { Authorization: `Bearer ${tokenState.token}` }
        })
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue({ error })
    }
  })

export const adminAppSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminAppSettings.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(
      getAdminAppSettings.fulfilled, (state, { payload }) => {
        state.settings = payload
        state.loading = 'loaded'
      })
    builder.addCase(
      getAdminAppSettings.rejected, (state, action) => {
        state.loading = 'error'
        state.error = action.error.message || ''
      })
  }
})
