import { Typography } from '@mui/material'
import React from 'react'
import { TerminatePage } from '../components/terminate-page'

export function VerifyEmail () {
  return <TerminatePage title='Email is not verified'>
    <Typography variant="body1">
      Please verify your email before logging in.
    </Typography>
  </TerminatePage>
}
