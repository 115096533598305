import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { LoadingType } from '../../../../utils/types'
import { AppDispatch, RootState } from '../../../../store/store'

interface DeleteCustomerSubsidiarySignedOutreachLetterState {
  loading: LoadingType;
  error: string;
}

const initialState: DeleteCustomerSubsidiarySignedOutreachLetterState = {
  loading: 'idle',
  error: ''
}

export interface DeleteCustomerSubsidiarySignedOutreachLetter {
  customerId: number;
  customerSubsidiaryId: number;
  onSuccess?: () => void;
  onFinal?: () => void;
}

export const deleteCustomerSubsidiarySignedOutreachLetter = createAsyncThunk<
  void,
  DeleteCustomerSubsidiarySignedOutreachLetter,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('customer/deleteCustomerSubsidiarySignedOutreachLetter', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    await axios.delete(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${request.customerId}/subsidiary/${request.customerSubsidiaryId}/delete-signed-outreach-letter`,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`
        },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  } finally {
    request.onFinal?.()
  }
})

export const deleteCustomerSubsidiarySignedOutreachLetterSlice = createSlice({
  name: 'delete-customer-subsidiary-signed-outreach-letter',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(deleteCustomerSubsidiarySignedOutreachLetter.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(deleteCustomerSubsidiarySignedOutreachLetter.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(deleteCustomerSubsidiarySignedOutreachLetter.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = deleteCustomerSubsidiarySignedOutreachLetterSlice.actions
export default deleteCustomerSubsidiarySignedOutreachLetterSlice.reducer
