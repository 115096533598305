export const TabTypes = {
  Ordered: 'ordered',
  UnderReview: 'underReview',
  ClarificationsRequired: 'clarificationsRequired',
  InProgress: 'inProgress',
  Completed: 'completed',
  OnHold: 'onHold',
  Expired: 'expired',
  ActivityLog: 'activityLog'
}
