import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NavigateNext } from '@mui/icons-material'
import { Alert, Autocomplete, Box, Breadcrumbs, Button, Card, CardContent, Grid, Link, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography, useTheme } from '@mui/material'

import { DropdownOption } from '../../../../interfaces/dropdown-option'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { AnilityBackdrop } from '../../../../components/anility-backdrop'
import { getFinancialStatementFiles } from './get-financial-statement-files-slice'
import { getSubmissionReviewWorkspace } from '../get-submission-review-workspace-slice'
import { Cell, FinancialStatementTablesInfo, getFinancialStatementTables, resetFinancialStatementTables, Row } from './get-financial-statement-tables-slice'

const FinancialExtractionTool = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const {
    getSubmissionReviewWorkspaceState,
    getFinancialStatementFilesState,
    getFinancialStatementTablesState
  } = useAppSelector((state) => state)
  const submissionReviewWorkspace = getSubmissionReviewWorkspaceState.submissionReviewWorkspace
  const loading = getSubmissionReviewWorkspaceState.loading === 'loading' || getFinancialStatementFilesState.loading === 'loading' ||
    getFinancialStatementTablesState.loading === 'loading'
  const theme = useTheme()
  const assessmentRequestId = Number(id)
  const [selectedFinancialStatementTable, setSelectedFinancialStatementTable] = useState<FinancialStatementTablesInfo | null>(null)
  const files = getFinancialStatementFilesState.financialStatement?.jsonFiles?.map(({ value, label }: any) => { return { value, label } }) ?? []
  const financialStatementTables = getFinancialStatementTablesState.response?.financialStatementTables ?? []
  const { error: financialStatementTablesError } = getFinancialStatementTablesState
  const selectedTableLabels = selectedFinancialStatementTable?.label.split('|')

  useEffect(() => {
    if (!assessmentRequestId || isNaN(assessmentRequestId)) {
      navigate(-1)
    } else {
      dispatch(getSubmissionReviewWorkspace({
        assessmentRequestId
      }))
      dispatch(getFinancialStatementFiles({
        assessmentRequestId
      }))
      dispatch(resetFinancialStatementTables())
    }
  }, [assessmentRequestId])

  const handleSelectFileOnChange = (item: DropdownOption | null) => {
    if (item) {
      dispatch(getFinancialStatementTables({
        assessmentRequestId,
        fileNameWithBasePath: item.value.toString()
      }))
      setSelectedFinancialStatementTable(null)
    } else {
      setSelectedFinancialStatementTable(null)
      dispatch(resetFinancialStatementTables())
    }
  }

  const handleSelectFinancialStatementTables = (item: FinancialStatementTablesInfo | null) => {
    setSelectedFinancialStatementTable(item || null)
  }

  const handleCopyToClipboardClick = (id: string) => {
    return (e: React.MouseEvent) => {
      const el = document.getElementById(id)
      const range = document.createRange()
      const sel = window.getSelection()
      if (range && sel && el) {
        sel?.removeAllRanges()
        range.selectNodeContents(el)
        sel?.addRange(range)
      }
      document.execCommand('Copy')
    }
  }

  const autoCompleteStyles: SxProps<Theme> = {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '.MuiOutlinedInput-notchedOutline': {
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderRadius: 0
      },
      '.MuiAutocomplete-input': {
        paddingBottom: '5px'
      }
    }
  }

  return (
    <Box p={3} sx={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      position: 'relative'
    }}>
      <Breadcrumbs sx={{ marginBottom: '1.5rem' }}
        separator={<NavigateNext
          sx={{ color: theme.palette.primary.main, width: '1.125rem', height: '1.125rem' }} />}>
        <Link
          underline='hover'
          color={theme.palette.text.secondary}
          onClick={() => navigate('/report/summary')}>
          Home
        </Link>
        <Link
          underline='hover'
          color={theme.palette.text.secondary}
          onClick={() => navigate('/report/under-review-assessments')}>
          Under Review
        </Link>
        <Link
          underline='hover'
          color={theme.palette.text.secondary}
          onClick={() => navigate(`/report/under-review-assessments/workspace/${assessmentRequestId}`)}>
          {submissionReviewWorkspace?.contractName}
        </Link>
        <Typography
          color={theme.palette.text.secondary}>
          Financial Statements
        </Typography>
      </Breadcrumbs>
      <Card sx={{ height: '100%' }}>
        <CardContent sx={{ padding: 3 }}>
          <Typography variant="h3">Financial Extraction Tool</Typography>
          {financialStatementTablesError &&
            <Grid container>
              <Grid item xs={6} pt={2}>
                <Alert severity='error'>{financialStatementTablesError}</Alert>
              </Grid>
            </Grid>
          }

          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12} lg={9}>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    options={files}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(_, value) => handleSelectFileOnChange(value)}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder={'Please select a file'}
                      />
                    )}
                    sx={autoCompleteStyles}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={financialStatementTables}
                    value={selectedFinancialStatementTable}
                    onChange={(_, value) => handleSelectFinancialStatementTables(value)}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.tableId}>
                          {option.label}
                        </li>
                      )
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder={'Please select tables'}
                      />
                    )}
                    sx={autoCompleteStyles}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!!selectedFinancialStatementTable &&
          <Box sx={{ mt: 3 }}>
            <Grid container direction="row" columnGap={3}>
              <Grid item>
                <Button variant='contained'
                        onClick={handleCopyToClipboardClick(selectedFinancialStatementTable.tableId)}>
                  Copy To Clipboard
                </Button>
              </Grid>
              <Grid item>
                {selectedTableLabels?.map((label) => <Typography variant="body2" key={label}>{label}</Typography>)}
              </Grid>
            </Grid>
            <TableContainer sx={{ mt: 3 }}>
              <Table stickyHeader aria-label="sticky table" id={selectedFinancialStatementTable.tableId}>
                <TableHead>
                  <TableRow>
                    {selectedFinancialStatementTable?.header?.cells?.map((cell: Cell) => (
                      <TableCell
                        key={cell.id}
                        sx={{
                          '&.MuiTableCell-root':
                          {
                            fontWeight: 'bold',
                            backgroundColor: 'transparent',
                            color: `${theme.palette.text.primary}`,
                            borderBottom: `2px solid  ${theme.palette.background.default}`,
                            borderTop: `2px solid  ${theme.palette.background.default}`
                          }
                        }}
                      >
                        {cell.text}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
              {selectedFinancialStatementTable?.data?.map((row : Row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.cells[0].rowIndex}>
                    {row.cells?.map((cell: Cell) => {
                      const isBold = cell.text?.toLowerCase().indexOf('total') >= 0
                      return (
                        <TableCell
                          sx={{
                            '&.MuiTableCell-root':
                            {
                              fontWeight: isBold ? 'bold' : 'normal',
                              borderBottom: `1px solid  ${theme.palette.background.default}`
                            }
                          }
                        }
                          key={cell.id}>
                          {cell.formattedText}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
              </Table>
            </TableContainer>
          </Box>
          }
        </CardContent>
      </Card>
      <AnilityBackdrop open={loading} />
    </Box>
  )
}

export default FinancialExtractionTool
