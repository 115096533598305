import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'

interface ReportConfiguration {
  id: string;
  reportType: string;
  visible: boolean;
  description: string;
  financialViabilityAssessmentBaseModelUrl: string;
}

interface GetReportConfigurationsListState {
  reportConfigurations: ReportConfiguration[]
  loading: LoadingType
  error: string
}

const initialState: GetReportConfigurationsListState = {
  reportConfigurations: [],
  loading: 'idle',
  error: ''
}

export const getReportConfigurationsList = createAsyncThunk<
  ReportConfiguration[],
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('report-configuration/getReportConfigurationsList', async (_, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<ReportConfiguration[]>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportconfigurations`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getReportConfigurationsListSlice = createSlice({
  name: 'get-report-configurations-list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReportConfigurationsList.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getReportConfigurationsList.fulfilled, (state, { payload }) => {
      state.reportConfigurations = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getReportConfigurationsList.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export default getReportConfigurationsListSlice.reducer
