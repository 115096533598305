import React, { useCallback } from 'react'
import { Link } from '@mui/material'
import { useAppSelector } from '../hooks/hooks'
import { secureFileDownload } from '../utils/functions'

export const DownloadLink = ({
  url,
  children
}: React.PropsWithChildren<DownloadLinkProps>) => {
  const { token } = useAppSelector((state) => state.tokenState)

  const handleClick = useCallback(async (e: React.MouseEvent) => {
    e.stopPropagation()
    await secureFileDownload(url, token)
  }, [url, token])

  return (
    <Link component="button" variant="link" onClick={handleClick}>{children}</Link>
  )
}

export interface DownloadLinkProps {
  url: string
}
