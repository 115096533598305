import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { AnalystUsersItem } from '../get-analyst-users-slice'
import { useAppDispatch } from '../../../hooks/hooks'
import { assignAnalystUserToAssessmentRequests } from './assign-analyst-to-assessment-requests-slice'

interface AnalystUserListProps {
  id: number
  index: number
  options: AnalystUsersItem[]
  value: AnalystUsersItem
  disabled: boolean
  onSuccess?: () => void
}
const AnalystUsersList = ({
  id,
  index,
  options,
  value,
  disabled,
  onSuccess
}: AnalystUserListProps) => {
  const dispatch = useAppDispatch()
  const handleAssignedAnalystChange = (item: AnalystUsersItem) => {
    if (item && options.some((option) => option.value === item.value)) {
      dispatch(assignAnalystUserToAssessmentRequests({
        assessmentRequestId: id,
        analystUserId: item.value,
        onSuccess: () => {
          onSuccess?.()
        }
      }))
    }
  }
  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      key={`${id}-${index}`}
      options={options}
      value={value}
      onChange={(event, newValue) => handleAssignedAnalystChange(newValue)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={!value?.value ? 'Please select' : ''}
        />
      )}
      sx={{
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderRadius: '0px'
        }
      }}
    />

  )
}

export default AnalystUsersList
