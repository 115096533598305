import { Route, Routes } from 'react-router-dom'
import EntityAssessedList from './entity-assessed-list'
import EntityAssessedDetails from './entity-assessed-details'

export const EntitiesAssessed = () => {
  return (
    <Routes>
      <Route
        path='*'
        element={<EntityAssessedList />}
      />
       <Route
        path='/:id'
        element={<EntityAssessedDetails />}
      />
    </Routes>
  )
}
