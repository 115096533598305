import React, { useEffect, useState } from 'react'
import { useMediaQuery, ThemeProvider, CssBaseline, Box, BoxProps } from '@mui/material'
import { AnilityTheme } from '../theme/anility-dark-theme'
import { WidthError } from '../pages/width-error'
import { Sidebar } from './sidebar'
import { Topbar } from './topbar'
import { Footer } from './footer'
import { MenuListItemProps } from './menu-list-item'
import { useLocation } from 'react-router-dom'

export type AppLayoutProps = {
  hideTopbar?: boolean;
  hideMenu?: boolean;
  mainContainerProps?: BoxProps
  menuItems: MenuListItemProps[]
};

const AppLayout = ({ mainContainerProps, menuItems, ...props }: React.PropsWithChildren<AppLayoutProps>) => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const drawerWidth = 260
  const location = useLocation()
  const currentState = location.state as Record<string, any>
  function handleDrawerToggleClick () {
    setSidebarOpen(!sidebarOpen)
  }

  useEffect(() => {
    setSidebarOpen(!currentState?.closeSideBar)
  }, [currentState?.closeSideBar])

  return (
    <Box
      sx={{
        display: 'flex',
        minWidth: 1024,
        maxWidth: 1920,
        minHeight: '100%',
        margin: '0 auto',
        flexWrap: 'nowrap'
      }}
    >
      <CssBaseline />
      <Sidebar menuItems={menuItems} drawerWidth={drawerWidth} open={sidebarOpen} hideMenu={props.hideMenu} onClose={() => setSidebarOpen(false)} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          minHeight: '100%',
          overflow: 'auto'
        }}
      >
        <Topbar Open={!props.hideTopbar} OnDrawerToggleClick={handleDrawerToggleClick} />
        <Box component="main" p={3} {...mainContainerProps} marginBottom="auto">
          {props.children}
        </Box>
        <Footer />
      </Box>
    </Box>
  )
}

export function Layout (props: React.PropsWithChildren<AppLayoutProps>) {
  const minWidth = useMediaQuery('(min-width:1024px)')
  return (
    <>
      <Box sx={{ display: minWidth ? 'initial' : 'none' }}>
        <ThemeProvider theme={AnilityTheme}>
          <AppLayout {...props}>{props.children}</AppLayout>
        </ThemeProvider>
      </Box>
      {!minWidth && <WidthError />}
    </>
  )
}
