import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'

interface UploadPPSRState {
  loading: LoadingType;
  error: string;
}

const initialState: UploadPPSRState = {
  loading: 'idle',
  error: ''
}

export interface UploadPPSR {
  reportWorkspaceId: string;
  file: File;
  onSuccess?: () => void;
  onFinal?: () => void;
}

export const uploadPPSR = createAsyncThunk<
  void,
  UploadPPSR,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('report-workspace/ppsr-stage', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    const formData = new FormData()
    formData.append('PPSRReportFile', request.file)
    formData.append('reportWorkspaceId', request.reportWorkspaceId as string)
    await axios.post<null>(`${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${request.reportWorkspaceId}/upload-ppsr`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  } finally {
    request.onFinal?.()
  }
})

export const uploadPPSRSlice = createSlice({
  name: 'upload-ppsr',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(uploadPPSR.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(uploadPPSR.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(uploadPPSR.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = uploadPPSRSlice.actions
export default uploadPPSRSlice.reducer
