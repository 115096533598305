import { Link, Typography } from '@mui/material'
import { TerminatePage } from '../components/terminate-page'
import { useAuth0 } from '@auth0/auth0-react'

export function LoginError () {
  const { logout } = useAuth0()
  return <TerminatePage title='Login Error'>
    <Typography variant="body1">
      An error occurred during login. Click <Link component="button" onClick={() => logout({ returnTo: window.location.origin })}>here</Link> to try again.
    </Typography>
  </TerminatePage>
}
