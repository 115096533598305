import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { LoadingType } from '../../../utils/types'
import { AppDispatch, RootState } from '../../../store/store'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'

export interface SyncCrm {
  onSuccess?: () => void
}

interface SyncCrmState {
  loading: LoadingType
  error: string
}

const initialState: SyncCrmState = {
  loading: 'idle',
  error: ''
}

export const syncCrm = createAsyncThunk<
  void,
  SyncCrm,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: RejectValue
  }
>('jobs/sync-crm', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()
  try {
    await axios.post<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/jobs/sync-crm`,
      request,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'application/json'
        },
        validateStatus: (status) => status < 400
      }
    )

    request.onSuccess?.()
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const syncCrmSlice = createSlice({
  name: 'sync-crm',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(syncCrm.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(syncCrm.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(syncCrm.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})
