import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { LoadingType } from '../../../../utils/types'
import { AppDispatch, RootState } from '../../../../store/store'
import { CustomerSubsidiaryFormRequest } from './customer-subsidiary'
import { RejectValue } from '../../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../../utils/functions'

interface AddCustomerSubsidiaryState {
  loading: LoadingType;
  error: string;
}

const initialState: AddCustomerSubsidiaryState = {
  loading: 'idle',
  error: ''
}

export const addCustomerSubsidiary = createAsyncThunk<
  void,
  CustomerSubsidiaryFormRequest,
  {
    dispatch: AppDispatch
    state: RootState,
    rejectValue: RejectValue
  }
>(
  'customer/addCustomerSubsidiary',
  async (request: CustomerSubsidiaryFormRequest, thunkAPI) => {
    const { tokenState, appSettingsState } = thunkAPI.getState()

    try {
      const formData = new FormData()
      formData.append('customerId', request.customerId.toString())
      formData.append('name', request.name)
      formData.append('domain', request.domainName)
      formData.append('signedOutreachLetter', request.signedOutreachLetter!)
      formData.append('auth0ConnectionId', request.auth0ConnectionId ?? '')
      await axios.post(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${request.customerId}/add-customer-subsidiary`, formData, {
        validateStatus: (status) => status < 400,
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      request.onSuccess?.()
    } catch (error) {
      const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
      if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
        return thunkAPI.rejectWithValue({ message: errors.response.data.title })
      }

      return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
    }
  }
)

export const addCustomerSubsidiarySlice = createSlice({
  name: 'add-customer-subsidiary',
  initialState,
  reducers: {
    resetAddCustomerSubsidiary: (state) => {
      state.error = ''
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addCustomerSubsidiary.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(addCustomerSubsidiary.fulfilled, (state) => {
      state.loading = 'loaded'
    })
    builder.addCase(addCustomerSubsidiary.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetAddCustomerSubsidiary } = addCustomerSubsidiarySlice.actions
export default addCustomerSubsidiarySlice.reducer
