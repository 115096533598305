import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AnalystDetailsHeader } from './components/analyst-details-header'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { getAnalystDetails } from './get-analyst-details-slice'
import { setPageNumber } from './get-analyst-users-list-slice'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { AnalystDetailsSummary } from './components/analyst-details-summary'
import AnalystDetailsAssessment from './analyst-assessments/analyst-details-assessment'

const AnalystDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const {
    getAnalystDetailsState,
    getAnalystUsersState,
    assignAnalystUserToAssessmentRequestsState,
    getAnalystOrderedAssessmentRequestsState,
    getAnalystInProgressReportsState,
    getAnalystCompletedAssessmentRequestsState,
    updateExpiredAssessmentState
  } = useAppSelector(state => state)

  const loading = getAnalystDetailsState.loading === 'loading' ||
  getAnalystUsersState.loading === 'loading' ||
  assignAnalystUserToAssessmentRequestsState.loading === 'loading' ||
  getAnalystOrderedAssessmentRequestsState.loading === 'loading' ||
  getAnalystInProgressReportsState.loading === 'loading' ||
  getAnalystCompletedAssessmentRequestsState.loading === 'loading' ||
  updateExpiredAssessmentState.loading === 'loading'

  useEffect(() => {
    if (!id || isNaN(+id)) {
      navigate('..')
    } else {
      dispatch(getAnalystDetails({
        analystId: +id
      }))
      dispatch(setPageNumber(1))
    }
  }, [id])

  return (
        <Box p={4} sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          position: 'relative',
          rowGap: 2
        }}>
            <AnalystDetailsHeader analystDetails={getAnalystDetailsState.analystDetails}/>
            <AnalystDetailsSummary analystDetails={getAnalystDetailsState.analystDetails}/>
            <AnalystDetailsAssessment analystId={+(id ?? '')} />
            <AnilityBackdrop open={loading}/>
        </Box>
  )
}

export default AnalystDetails
