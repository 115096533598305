import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'

export interface GetReportWorkspaceFileAttachmentsState {
  items: ReportWorkspaceFileAttachment[]
  loading: LoadingType
  error: string
}

export interface ReportWorkspaceFileAttachment {
  reportWorkspaceId: string
  fileName: string
  fileSize: BigInt
  contentType: string
  lastModifiedDate: string
  downloadUrl: string
}

export const DEFAULT_PAGE_SIZE = 10

const initialState: GetReportWorkspaceFileAttachmentsState = {
  items: [],
  loading: 'idle',
  error: ''
}

interface GetReportWorkspaceFileAttachmentsParams {
  reportWorkspaceId: string
}

export const getReportWorkspaceFileAttachments = createAsyncThunk<
  ReportWorkspaceFileAttachment[],
  GetReportWorkspaceFileAttachmentsParams,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: RejectValue
  }
>('report/getReportWorkspaceFileAttachments', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()

  try {
    const response = await axios.get<ReportWorkspaceFileAttachment[]>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${request.reportWorkspaceId}/file-attachments`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const getReportWorkspaceFileAttachmentsSlice = createSlice({
  name: 'get-report-workspace-file-attachments',
  initialState,
  reducers: {
    resetGetReportWorkspaceFileAttachments: (state) => {
      state.loading = 'idle'
      state.error = ''
      state.items = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getReportWorkspaceFileAttachments.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getReportWorkspaceFileAttachments.fulfilled, (state, { payload }) => {
      state.items = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getReportWorkspaceFileAttachments.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message ?? ''
    })
  }
})

export const { resetGetReportWorkspaceFileAttachments } = getReportWorkspaceFileAttachmentsSlice.actions
export default getReportWorkspaceFileAttachmentsSlice.reducer
