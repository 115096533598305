import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PaginatedTable, Column } from '../../../../components/paginated-table'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { formatDate, formatDuration } from '../../../../utils/functions'
import { IReportWorkspaceListOptions } from '../../../report/report-workspace/get-report-workspace-list-slice'
import { TimeInfo } from '../../../report/components/time-info'
import { TableSearch, TableSearchProps } from '../../../../components/table-search'
import { TablePagination, TablePaginationProps } from '../../../../components/table-pagination'
import { ExpiredContextMenuItem, InternalNoteContextMenuItem, OnHoldAssessmentsContextMenuItem, ReassignAnalystContextMenuItem } from '../../../report/components/assessments-action-menu'
import { setSelectedAssessmentInternalNote } from '../../../../components/set-internal-notes-slice'
import { MenuListItemProps } from '../../../../components/menu-list-item'
import { usePermission } from '../../../../hooks/use-permission'
import { AnilityScopes } from '../../../../interfaces/anility-scopes'
import AnalystUsersList from '../../../report/analyst/analyst-users-list'
import { getAnalystInProgressReports, setPageNumber, setPageSize } from './get-analyst-in-progress-reports-slice'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../../../report/components/expire-assessment-confirmation-dialog'
import AssessmentOnHoldDialogForm, { AssessmentOnHoldDialogState } from '../../../report/components/assessment-on-hold-dialog-form'
import { ActionMenu } from '../../../../components/action-menu'

interface AnalystInProgressReportProps {
  analystId: number;
  searchValue: string;
  onSearchClick: () => void;
  onResetSearchClick: () => void;
  onSearchTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setShowInternalNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

const getTableCellStyle = (reportWorkspace: IReportWorkspaceListOptions) => ({
  color: reportWorkspace.status === 'Error' ? 'red' : 'initial',
  textDecoration: reportWorkspace.isDeleted ? 'line-through' : 'initial',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  cursor: reportWorkspace.status === 'Initializing' ? 'cursor' : 'pointer'
})

const SearchComponent = ({ searchValue, onSearchClick, onResetSearchClick, onSearchTextChange }: TableSearchProps) =>
  <Grid container justifyContent="flex-end" alignItems="flex-end">
    <TableSearch searchValue={searchValue} onSearchClick={onSearchClick} onResetSearchClick={onResetSearchClick} onSearchTextChange={onSearchTextChange} />
  </Grid>

const FooterComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <Grid container justifyContent='space-between'>
    <Grid item xs={8} py={2}>
      <TimeInfo />
    </Grid>
    <Grid item xs={4}>
      <TablePagination page={page}
        onTablePageChange={onTablePageChange}
        onTablePageSizeChange={onTablePageSizeChange} />
    </Grid>
  </Grid>

export const AnalystInProgressReports = ({
  analystId, searchValue,
  onSearchClick, onResetSearchClick, onSearchTextChange,
  setShowInternalNotes
}: AnalystInProgressReportProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { getAnalystInProgressReportsState, getAnalystUsersState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount } = getAnalystInProgressReportsState
  const [writeAssignment, writeAssessment] = usePermission([AnilityScopes.Write.Assignment, AnilityScopes.Write.Assessment])
  const [assignmentEnabledId, setAssignmentEnabledId] = useState<string | null>(null)
  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const [showAssessmentOnHoldDialogForm, setShowAssessmentOnHoldDialogForm] = useState<AssessmentOnHoldDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  useEffect(() => {
    if (getAnalystInProgressReportsState.loading === 'idle') {
      dispatch(getAnalystInProgressReports({
        pageSize,
        pageNumber,
        analystId
      }))
    }
  }, [getAnalystInProgressReportsState.loading, analystId])

  const handleExpireAssessmentSubmitSuccess = () => {
    if (!analystId) {
      return
    }
    dispatch(getAnalystInProgressReports({
      pageSize,
      pageNumber,
      analystId
    }))
    setAssignmentEnabledId(null)
  }

  const handleAssessmentOnHoldSubmitSuccess = () => {
    if (!analystId) {
      return
    }
    dispatch(getAnalystInProgressReports({
      pageSize,
      pageNumber,
      analystId
    }))
  }

  const handleInternalNotesClick = (item: IReportWorkspaceListOptions) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.assessmentRequestId!)
    )
  }

  const generateContextMenu = (item: IReportWorkspaceListOptions): MenuListItemProps[] => {
    return [
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.assessmentRequestId!
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => { setAssignmentEnabledId(item.id) }
      }),
      OnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => setShowAssessmentOnHoldDialogForm({
          show: true,
          assessmentRequestId: item.assessmentRequestId!
        })
      })
    ]
  }

  const handleSearch = () => {
    onSearchClick()
  }

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleViewReportWorkspace = (
    item: IReportWorkspaceListOptions
  ) => {
    if (item.status === 'Initializing') {
      return
    }
    navigate(`/report/report-workspace/view/${item.id}`)
  }

  const columns: Column[] = [{
    id: 'assignedAnalyst',
    label: 'Assigned To',
    minWidth: 220,
    className: 'wrapped-text',
    onRowClick: writeAssignment ? () => { } : undefined
  },
  {
    id: 'createdDate',
    label: 'Ordered Date',
    minWidth: 120,
    align: 'left'
  },
  {
    id: 'reportConfigurationId',
    label: 'Assessment Type',
    minWidth: 90
  },
  {
    id: 'customerName',
    label: 'Customer Name',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'contractName',
    label: 'Assessment Subject',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 150
  },
  {
    id: 'timeElapsedSinceOrdering',
    label: 'Time Elapsed Since Client Ordering',
    minWidth: 150
  },
  {
    id: 'entityAssessedResponseTime',
    label: 'Time Elapsed Since Entity Assessed Responded',
    minWidth: 190
  }]
  const formattedInProgressReports = items.map((item, index) => {
    return {
      ...item,
      createdDate: formatDate(item.createdDate),
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering),
      entityAssessedResponseTime: formatDuration(item.entityAssessedResponseTime),
      rowInlineStyles: getTableCellStyle(item),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label && item.assessmentRequestId,
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.assessmentRequestId!}
          index={index}
          value={item.assignedAnalyst!}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          onSuccess={() => {
            dispatch(getAnalystInProgressReports({
              pageSize,
              pageNumber,
              analystId
            }))
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label
    }
  })

  const page = useMemo(() => {
    return { totalCount, size: pageSize, number: pageNumber }
  }, [totalCount, pageSize, pageNumber])

  return (
    <>
      <PaginatedTable
        columns={columns}
        items={formattedInProgressReports}
        headerComponent={
          <SearchComponent
            searchValue={searchValue}
            onSearchTextChange={onSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={onResetSearchClick} />}
        footerComponent={
          <FooterComponent
            page={page}
            onTablePageChange={handlePageChange}
            onTablePageSizeChange={handlePageSizeChange}
          />
        }
        renderMenuRowActions={(item, index, itemLength) => {
          return (
            <ActionMenu
              isContextMenuDisabled={!item.assessmentRequestId || item.status === 'Initializing'}
              contextMenuIndicatorMarginLeft={0.6}
              menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
          )
        }}
        onRowClick={handleViewReportWorkspace}
      />
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
      <AssessmentOnHoldDialogForm
        show={showAssessmentOnHoldDialogForm.show}
        assessmentRequestId={showAssessmentOnHoldDialogForm.assessmentRequestId}
        onSubmitSuccess={handleAssessmentOnHoldSubmitSuccess}
        onClose={() =>
          setShowAssessmentOnHoldDialogForm({
            show: false,
            assessmentRequestId: undefined
          })} />
    </>
  )
}
