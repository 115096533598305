import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { getGenericErrorMessage } from '../../../utils/functions'
import { RejectValue } from '../../../interfaces/reject-value'

interface SendCustomerSSOInitialBatchInvitesState {
  result?: SendCustomerSSOInitialBatchInvites
  loading: LoadingType
  error: string
}

interface SendCustomerSSOInitialBatchInvites {
  success?: boolean
  successCount: number
  totalCount: number
}

const initialState: SendCustomerSSOInitialBatchInvitesState = {
  loading: 'idle',
  error: ''
}

interface SendCustomerSSOInitialBatchInvitesParams {
  customerId: number;
  onSuccess?: () => void
}

export const sendCustomerSSOInitialBatchInvites = createAsyncThunk<
  SendCustomerSSOInitialBatchInvites,
  SendCustomerSSOInitialBatchInvitesParams,
  {
    dispatch: AppDispatch
    state: RootState,
    rejectValue: RejectValue
  }
>(
  'customer/sendSSOInitialBatchInvites',
  async (request, thunkAPI) => {
    const { tokenState, appSettingsState } = thunkAPI.getState()

    try {
      const response = await axios.post<SendCustomerSSOInitialBatchInvites>(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${request.customerId}/send-sso-initial-batch-invites`, request, {
        validateStatus: (status) => status < 400,
        headers: { Authorization: `Bearer ${tokenState.token}` }
      })
      request.onSuccess?.()
      return response.data
    } catch (error) {
      const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
      if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
        return thunkAPI.rejectWithValue({ message: errors.response.data.title })
      }

      return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
    }
  }
)

export const sendCustomerSSOInitialBatchInvitesSlice = createSlice({
  name: 'send-customer-sso-initial-batch-invites',
  initialState,
  reducers: {
    resetSendCustomerSSOInitialBatchInvites: (state) => {
      state.result = undefined
      state.error = ''
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(sendCustomerSSOInitialBatchInvites.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(sendCustomerSSOInitialBatchInvites.fulfilled, (state, { payload }) => {
      state.loading = 'loaded'
      state.result = payload
      state.error = ''
    })
    builder.addCase(sendCustomerSSOInitialBatchInvites.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message ?? ''
      state.result = undefined
    })
  }
})

export default sendCustomerSSOInitialBatchInvitesSlice.reducer
export const { resetSendCustomerSSOInitialBatchInvites } = sendCustomerSSOInitialBatchInvitesSlice.actions
