import { useCallback } from 'react'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { setAssessmentRequestOnHoldStatus } from '../set-assessment-request-on-hold-status-slice'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { Box } from '@mui/material'

export const AssessmentRemoveOnHoldConfirmationDialog = ({ open, assessmentRequestId, status, onClose, onSubmitSuccess }: AssessmentRemoveOnHoldConfirmationDialogProps) => {
  const dispatch = useAppDispatch()
  const { setAssessmentRequestOnHoldStatusState } = useAppSelector(state => state)
  const isLoading = setAssessmentRequestOnHoldStatusState.loading === 'loading'
  const handleSubmit = useCallback(() => {
    if (!assessmentRequestId) {
      return
    }

    dispatch(setAssessmentRequestOnHoldStatus({
      assessmentRequestId,
      setToOnHold: false,
      onSuccess: () => {
        onSubmitSuccess?.()
        onClose()
      }
    }))
  }, [assessmentRequestId])

  return (
    <Box sx={{ flex: 1, position: 'relative' }}>
      <CustomDialogForm
        open={open}
        maxWidth={600}
        primaryText="Yes"
        handleSubmit={handleSubmit}
        onClose={onClose}
        title="Do you want to proceed with removing this assessment from on hold?"
        secondaryText="Cancel">
        {`This assessment will revert to its original status: ${status}.`}
      </CustomDialogForm>
      <AnilityBackdrop open={isLoading} />
    </Box>

  )
}

export interface AssessmentRemoveOnHoldConfirmationDialogProps {
  open: boolean
  assessmentRequestId?: number
  status: string
  onClose: () => void
  onSubmitSuccess?: () => void
}

export interface AssessmentRemoveOnHoldConfirmationDialogState {
  assessmentRequestId?: number
  show: boolean
  status: string
}
