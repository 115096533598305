import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EntityAssessedDetailsHeader } from './components/entity-assessed-details-header'
import { EntityAssessedDetailsSummary } from './components/entity-assessed-details-summary'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { getEntityAssessedDetails } from './get-entity-assessed-details-slice'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import EntityAssessedDetailsAssessment from './entity-assessed-assessments/entity-assessed-details-assessment'
import { setPageNumber } from './get-entity-assessed-users-slice'
import { usePermission } from '../../../hooks/use-permission'
import { AnilityScopes as FiableScopes } from '../../../interfaces/anility-scopes'
import SpecialRequirementsDialog from '../../../components/special-requirements-dialog'
import { resetUpdateEntityAssessedSpecialRequirements, updateEntityAssessedSpecialRequirements } from './update-entity-assessed-special-requirements'

const EntityAssessedDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [writeEntityAssessed, readReports, readEntityAssessed] = usePermission([FiableScopes.Write.EntityAssessed, FiableScopes.Read.Reports, FiableScopes.Read.EntityAssessed])
  const [showSpecialRequirementsDialog, setShowSpecialRequirementsDialog] = useState(false)
  const isViewProfileVisible = readEntityAssessed

  const {
    getEntityAssessedDetailsState,
    getOrderedAssessmentRequestsState,
    getUnderReviewAssessmentRequestsState,
    getReportWorkspacesState,
    getCompletedAssessmentRequestsState,
    getOnHoldAssessmentState,
    getExpiredAssessmentRequestsState,
    getAnalystUsersState,
    assignAnalystUserToAssessmentRequestsState,
    updateExpiredAssessmentState,
    reactivateDeclinedAssessmentRequestState,
    updateEntityAssessedSpecialRequirementsState
  } = useAppSelector(state => state)

  const loading = getEntityAssessedDetailsState.loading === 'loading' ||
    getOrderedAssessmentRequestsState.loading === 'loading' ||
    getUnderReviewAssessmentRequestsState.loading === 'loading' ||
    getReportWorkspacesState.loading === 'loading' ||
    getCompletedAssessmentRequestsState.loading === 'loading' ||
    getOnHoldAssessmentState.loading === 'loading' ||
    getExpiredAssessmentRequestsState.loading === 'loading' ||
    getAnalystUsersState.loading === 'loading' ||
    assignAnalystUserToAssessmentRequestsState.loading === 'loading' ||
    updateExpiredAssessmentState.loading === 'loading' ||
    reactivateDeclinedAssessmentRequestState.loading === 'loading'
  const entityAssessedDetails = getEntityAssessedDetailsState.entityAssessedDetails
  const srLastModifiedByAnalystUser = `${entityAssessedDetails?.srLastModifiedByAnalystUser?.firstName ?? ''} ${entityAssessedDetails?.srLastModifiedByAnalystUser?.lastName ?? ''}`.trim()

  const refreshEntityAssessedDetails = () => {
    if (!id || isNaN(+id)) return

    dispatch(getEntityAssessedDetails({
      entityAssessedId: +id
    }))
    dispatch(setPageNumber(1))
    dispatch(resetUpdateEntityAssessedSpecialRequirements())
  }

  const handleViewProfile = () => {
    if (getEntityAssessedDetailsState.entityAssessedDetails?.profileLink) {
      window.open(getEntityAssessedDetailsState.entityAssessedDetails?.profileLink, '_blank')
    }
  }

  const handleSpecialRequirementsButtonClick = () => {
    setShowSpecialRequirementsDialog(true)
  }

  const handleSpecialRequirementsClose = () => {
    setShowSpecialRequirementsDialog(false)
  }

  const handleSpecialRequirementsActionButtonClick = async (value: string) => {
    if (!id || isNaN(+id)) return

    dispatch(updateEntityAssessedSpecialRequirements({
      entityAssessedId: +id,
      specialRequirements: value,
      onSuccess: () => {
        dispatch(getEntityAssessedDetails({
          entityAssessedId: +id
        }))
        setShowSpecialRequirementsDialog(false)
      }
    }))
  }

  useEffect(() => {
    if (!id || isNaN(+id)) {
      navigate('..')
    } else {
      refreshEntityAssessedDetails()
    }
  }, [id])

  return (
    <Box p={4} sx={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      position: 'relative',
      rowGap: 2
    }}>
      <EntityAssessedDetailsHeader
        writeEntityAssessed={writeEntityAssessed}
        entityAssessedDetails={entityAssessedDetails}
        onViewProfile={isViewProfileVisible ? handleViewProfile : undefined}
        onSpecialRequirementsButtonClick={handleSpecialRequirementsButtonClick}
      />
      <EntityAssessedDetailsSummary entityAssessedDetails={entityAssessedDetails} refreshEntityAssessedDetails={refreshEntityAssessedDetails} />
      {readReports && id && (<EntityAssessedDetailsAssessment entityAssessedId={+id} />)}
      <SpecialRequirementsDialog isLoading={updateEntityAssessedSpecialRequirementsState.loading === 'loading'} lastUpdatedOn={entityAssessedDetails?.srLastModifiedDate} lastUpdatedBy={srLastModifiedByAnalystUser} open={showSpecialRequirementsDialog} onClose={handleSpecialRequirementsClose} onActionButtonClick={handleSpecialRequirementsActionButtonClick} value={entityAssessedDetails?.specialRequirements} isEditable={writeEntityAssessed} />
      <AnilityBackdrop open={loading} />
    </Box>
  )
}

export default EntityAssessedDetails
