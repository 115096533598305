import { Route, Routes } from 'react-router-dom'
import CustomerList from './customer-list'
import CustomerDetails from './customer-details'

export const Customers = () => {
  return (
    <Routes>
      <Route
        path='*'
        element={<CustomerList />}
      />
      <Route
        path='/:id'
        element={<CustomerDetails />}
      />
    </Routes>
  )
}
