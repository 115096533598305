import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'

interface GetEntityAssessedUsersDomainState {
  entityAssessedDetails?: EntityAssessedUsersDomain
  loading: LoadingType
  error: string
}

export interface EntityAssessedUsersDomain {
  validDomains: string[]
}

const initialState: GetEntityAssessedUsersDomainState = {
  entityAssessedDetails: undefined,
  loading: 'idle',
  error: ''
}

interface GetEntityAssessedDetailsParams {
  entityAssessedId: number
}

export const getEntityAssessedUsersDomainList = createAsyncThunk<
  EntityAssessedUsersDomain,
  GetEntityAssessedDetailsParams,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('entityAssessed/getEntityAssessedUsersDomainList', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<EntityAssessedUsersDomain>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/entityAssessed/${request.entityAssessedId}/usersDomainList`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getEntityAssessedUsersDomainListSlice = createSlice({
  name: 'get-entity-assessed-users-domains',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEntityAssessedUsersDomainList.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getEntityAssessedUsersDomainList.fulfilled, (state, { payload }) => {
      state.entityAssessedDetails = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getEntityAssessedUsersDomainList.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = getEntityAssessedUsersDomainListSlice.actions
