
import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PaginatedTable } from '../../../../components/paginated-table'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { formatDate, formatDuration } from '../../../../utils/functions'
import { getReportWorkspaces, IReportWorkspaceListOptions, setPageNumber, setPageSize } from '../../../report/report-workspace/get-report-workspace-list-slice'
import { TimeInfo } from '../../../report/components/time-info'
import { TableSearch, TableSearchProps } from '../../../../components/table-search'
import { TablePagination, TablePaginationProps } from '../../../../components/table-pagination'
import { ExpiredContextMenuItem, InternalNoteContextMenuItem, OnHoldAssessmentsContextMenuItem, ReassignAnalystContextMenuItem } from '../../../report/components/assessments-action-menu'
import { setSelectedAssessmentInternalNote } from '../../../../components/set-internal-notes-slice'
import { MenuListItemProps } from '../../../../components/menu-list-item'
import { usePermission } from '../../../../hooks/use-permission'
import { AnilityScopes } from '../../../../interfaces/anility-scopes'
import AnalystUsersList from '../../../report/analyst/analyst-users-list'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../../../report/components/expire-assessment-confirmation-dialog'
import AssessmentOnHoldDialogForm, { AssessmentOnHoldDialogState } from '../../../report/components/assessment-on-hold-dialog-form'
import { ActionMenu } from '../../../../components/action-menu'
import { columns } from '../../../report/report-workspace/report-workspace-list'

interface EntityAssessedInProgressReportProps {
  entityAssessedId: number;
  searchValue: string;
  onSearchClick: () => void;
  onResetSearchClick: () => void;
  onSearchTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setShowInternalNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

const getTableCellStyle = (reportWorkspace: IReportWorkspaceListOptions) => ({
  color: reportWorkspace.status === 'Error' ? 'red' : 'initial',
  textDecoration: reportWorkspace.isDeleted ? 'line-through' : 'initial',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  cursor: reportWorkspace.status === 'Initializing' ? 'cursor' : 'pointer'
})

const SearchComponent = ({ searchValue, onSearchClick, onResetSearchClick, onSearchTextChange }: TableSearchProps) =>
  <Grid container justifyContent="flex-end" alignItems="flex-end">
    <TableSearch searchValue={searchValue} onSearchClick={onSearchClick} onResetSearchClick={onResetSearchClick} onSearchTextChange={onSearchTextChange} />
  </Grid>

const FooterComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <Grid container justifyContent='space-between'>
    <Grid item xs={8} py={2}>
      <TimeInfo />
    </Grid>
    <Grid item xs={4}>
      <TablePagination page={page}
        onTablePageChange={onTablePageChange}
        onTablePageSizeChange={onTablePageSizeChange} />
    </Grid>
  </Grid>

export const EntityAssessedInProgressReports = ({
  entityAssessedId, searchValue,
  onSearchClick, onResetSearchClick, onSearchTextChange,
  setShowInternalNotes
}: EntityAssessedInProgressReportProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { getReportWorkspacesState, getAnalystUsersState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount } = getReportWorkspacesState
  const [writeAssignment, writeAssessment] = usePermission([AnilityScopes.Write.Assignment, AnilityScopes.Write.Assessment])
  const [assignmentEnabledId, setAssignmentEnabledId] = useState<string | null>(null)
  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const [showAssessmentOnHoldDialogForm, setShowAssessmentOnHoldDialogForm] = useState<AssessmentOnHoldDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const loadInProgressAssessmentsInEntityAssessed = () => {
    dispatch(getReportWorkspaces({
      entityAssessedId
    }))
  }

  useEffect(() => {
    if (getReportWorkspacesState.loading === 'idle') {
      loadInProgressAssessmentsInEntityAssessed()
    }
  }, [getReportWorkspacesState.loading, entityAssessedId])

  const handleExpireAssessmentSubmitSuccess = () => {
    if (!entityAssessedId) {
      return
    }
    loadInProgressAssessmentsInEntityAssessed()
    setAssignmentEnabledId(null)
  }

  const handleAssessmentOnHoldSubmitSuccess = () => {
    if (!entityAssessedId) {
      return
    }
    loadInProgressAssessmentsInEntityAssessed()
  }

  const handleInternalNotesClick = (item: IReportWorkspaceListOptions) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.assessmentRequestId!)
    )
  }

  const generateContextMenu = (item: IReportWorkspaceListOptions): MenuListItemProps[] => {
    return [
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.assessmentRequestId!
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => { setAssignmentEnabledId(item.id) }
      }),
      OnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => setShowAssessmentOnHoldDialogForm({
          show: true,
          assessmentRequestId: item.assessmentRequestId!
        })
      })
    ]
  }

  const handleSearch = () => {
    onSearchClick()
  }

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleViewReportWorkspace = (
    item: IReportWorkspaceListOptions
  ) => {
    if (item.status === 'Initializing') {
      return
    }
    navigate(`/report/report-workspace/view/${item.id}`)
  }

  const formattedColumns = columns.map((item) => {
    return {
      ...item,
      onRowClick: item.id === 'assignedAnalyst' && writeAssignment ? () => { } : undefined
    }
  })

  const formattedInProgressReports = items.map((item, index) => {
    return {
      ...item,
      createdDate: formatDate(item.createdDate),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label && item.assessmentRequestId,
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.assessmentRequestId!}
          index={index}
          value={item.assignedAnalyst!}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          onSuccess={() => {
            loadInProgressAssessmentsInEntityAssessed()
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label,
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering),
      entityAssessedResponseTime: formatDuration(item.entityAssessedResponseTime),
      rowInlineStyles: getTableCellStyle(item)
    }
  })

  const page = useMemo(() => {
    return { totalCount, size: pageSize, number: pageNumber }
  }, [totalCount, pageSize, pageNumber])

  return (
    <>
      <PaginatedTable
        columns={formattedColumns}
        items={formattedInProgressReports}
        headerComponent={
          <SearchComponent
            searchValue={searchValue}
            onSearchTextChange={onSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={onResetSearchClick} />}
        footerComponent={
          <FooterComponent
            page={page}
            onTablePageChange={handlePageChange}
            onTablePageSizeChange={handlePageSizeChange}
          />
        }
        renderMenuRowActions={(item, index, itemLength) => {
          return (
            <ActionMenu
              isContextMenuDisabled={!item.assessmentRequestId || item.status === 'Initializing'}
              contextMenuIndicatorMarginLeft={0.6}
              menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
          )
        }}
        onRowClick={handleViewReportWorkspace}
      />
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
      <AssessmentOnHoldDialogForm
        show={showAssessmentOnHoldDialogForm.show}
        assessmentRequestId={showAssessmentOnHoldDialogForm.assessmentRequestId}
        onSubmitSuccess={handleAssessmentOnHoldSubmitSuccess}
        onClose={() =>
          setShowAssessmentOnHoldDialogForm({
            show: false,
            assessmentRequestId: undefined
          })} />
    </>
  )
}
