import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import {
  getReportWorkspaces,
  IReportWorkspaceListOptions,
  setPageNumber,
  setPageSize,
  setSearchText,
  showDeleted,
  resetLoadingStatus,
  setShowUnassigned,
  setShowAssignedToCurrentUser,
  DEFAULT_PAGE_SIZE
} from './get-report-workspace-list-slice'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { resetInputStageLoadingStatus } from './run-report-workspace-input-stage-slice'
import { softDeleteReportWorkspace } from './soft-delete-report-workspace-slice'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { formatDate, formatDuration, isAdmin, isSuperAnalyst, hasAnalystRole, isAnalystRoleOnly } from '../../../utils/functions'
import { ExpiredContextMenuItem, InternalNoteContextMenuItem, OnHoldAssessmentsContextMenuItem, ReassignAnalystContextMenuItem, TriggerCreateEventContextMenuItem } from '../components/assessments-action-menu'
import { MenuListItemProps } from '../../../components/menu-list-item'
import { Column, PaginatedTable } from '../../../components/paginated-table'
import { Box, FormControlLabel, Grid, IconButton, Stack, Switch, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import { usePermission } from '../../../hooks/use-permission'
import { TableSearch, TableSearchProps } from '../../../components/table-search'
import { InternalNotes } from '../../../components/internal-notes'
import { setSelectedAssessmentInternalNote } from '../../../components/set-internal-notes-slice'
import { getAnalystUsers } from '../get-analyst-users-slice'
import AnalystUsersList from '../analyst/analyst-users-list'
import { ReportWorkspaceTabs } from './report-workspace-tabs'
import TabPanel from '../../../components/tab-panel'
import { useRole } from '../../../hooks/use-role'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../components/expire-assessment-confirmation-dialog'
import { TriggerManualCreatedEventConfirmationDialog, TriggerManualCreatedEventConfirmationDialogState } from '../components/trigger-manual-created-event-confirmation-dialog'
import AssessmentOnHoldDialogForm, { AssessmentOnHoldDialogState } from '../components/assessment-on-hold-dialog-form'
import { ActionMenu } from '../../../components/action-menu'
import { AssessmentFooter } from '../components/assessment-footer'

const getTableCellStyle = (reportWorkspace: IReportWorkspaceListOptions) => ({
  color: reportWorkspace.status === 'Error' ? 'red' : 'initial',
  textDecoration: reportWorkspace.isDeleted ? 'line-through' : 'initial',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  cursor: reportWorkspace.status === 'Initializing' ? 'cursor' : 'pointer'
})

type SoftDeleteFVADialogConfirmationState = {
  show: boolean
  reportWorkspaceId: string
}

interface TopInfoComponentProps extends TableSearchProps {
  readDeletedAssessments: boolean
  showDeletedChecked: boolean
  onDeletedCheckedChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export const columns: Column[] = [
  {
    id: 'assignedAnalyst',
    label: 'Assigned To',
    minWidth: 220,
    className: 'wrapped-text'
  },
  {
    id: 'createdDate',
    label: 'Assessment Created',
    minWidth: 120,
    align: 'left'
  },
  {
    id: 'reportConfigurationId',
    label: 'Assessment Type',
    minWidth: 90
  },
  {
    id: 'customerName',
    label: 'Customer Name',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'targetCompanyName',
    label: 'Entity Assessed',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'contractName',
    label: 'Assessment Subject',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 150
  },
  {
    id: 'timeElapsedSinceOrdering',
    label: 'Time Elapsed Since Client Ordering',
    minWidth: 150
  },
  {
    id: 'entityAssessedResponseTime',
    label: 'Time Elapsed Since Entity Assessed Responded',
    minWidth: 190
  },
  {
    id: 'softDeleteAssessment',
    label: ''
  }
]

const ReportWorkspaceList = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const roles = useRole()

  const isAnalystRole = isAnalystRoleOnly(roles)

  const defaultTab = useMemo(() => { return isAnalystRole ? ReportWorkspaceTabs.AssignedToCurrentUser : ReportWorkspaceTabs.All }, [isAnalystRole])

  const {
    getReportWorkspacesState, softDeleteReportWorkspaceState, updateExpiredAssessmentState, getAnalystUsersState, assignAnalystUserToAssessmentRequestsState, triggerManualCreatedEventState
  } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount, loading: getReportWorkspaceLoading } =
    getReportWorkspacesState

  const [readDeletedAssessments, writeInternalNotes, writeAssignment, writeAssessment] = usePermission([AnilityScopes.Read.DeletedAssessments,
    AnilityScopes.Write.InternalNotes, AnilityScopes.Write.Assignment, AnilityScopes.Write.Assessment])

  const [allSearchText, setAllSearchText] = useState('')
  const [unassignedSearchText, setUnassignedSearchText] = useState('')
  const [assignedToCurrentUserSearchText, setAssignedToCurrentUserSearchText] = useState('')

  const [allShowDelete, setAllShowDelete] = useState(false)
  const [unAssignedShowDelete, setUnassignedShowDelete] = useState(false)
  const [assignedToCurrentUserShowDelete, setAssignedToCurrentUserShowDelete] = useState(false)

  const [allReportPageNumber, setAllReportPageNumber] = useState(1)
  const [unassignedReportPageNumber, setUnassignedPageNumber] = useState(1)
  const [assignedToCurrentUserPageNumber, setAssignedToCurrentUserPageNumber] = useState(1)

  const [selectedTab, setSelectedTab] = useState<string>(defaultTab)
  const [assignmentEnabledId, setAssignmentEnabledId] = useState<string | null>(null)

  const [showSoftDeleteFVAConfirmationDialog, setShowSoftDeleteFVAConfirmationDialog] = useState<SoftDeleteFVADialogConfirmationState>(
    {
      show: false,
      reportWorkspaceId: ''
    }
  )
  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const [showTriggerManualCreatedEventConfirmationDialog, setShowTriggerManualCreatedEventConfirmationDialog] = useState<TriggerManualCreatedEventConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const [showAssessmentOnHoldDialogForm, setShowAssessmentOnHoldDialogForm] = useState<AssessmentOnHoldDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const [showInternalNotes, setShowInternalNotes] = useState(false)
  const isSoftDeleteFVALoading = softDeleteReportWorkspaceState.loading === 'loading'
  const loading = getReportWorkspaceLoading === 'loading' ||
    isSoftDeleteFVALoading || updateExpiredAssessmentState.loading === 'loading' ||
    assignAnalystUserToAssessmentRequestsState.loading === 'loading' || getAnalystUsersState.loading === 'loading' ||
    triggerManualCreatedEventState.loading === 'loading'

  const resetTabLoadingStates = () => {
    dispatch(resetLoadingStatus(false))
  }

  useEffect(() => {
    dispatch(setSearchText(''))
    dispatch(setShowUnassigned(defaultTab === ReportWorkspaceTabs.Unassigned))
    dispatch(setShowAssignedToCurrentUser(defaultTab === ReportWorkspaceTabs.AssignedToCurrentUser))
    dispatch(showDeleted(false))
    dispatch(setPageNumber(1))
    dispatch(setPageSize(DEFAULT_PAGE_SIZE))
  }, [defaultTab])

  useEffect(() => {
    dispatch(resetLoadingStatus(true))
  }, [])

  useEffect(() => {
    getReportWorkspacesState.loading === 'idle' && dispatch(
      getReportWorkspaces({})
    )
  }, [dispatch, getReportWorkspacesState.loading])

  useEffect(() => {
    if (getAnalystUsersState.loading === 'idle') {
      dispatch(getAnalystUsers())
    }
  }, [getAnalystUsersState.loading])

  const handleViewReportWorkspace = (
    reportWorkspace: IReportWorkspaceListOptions
  ) => {
    if (reportWorkspace.status === 'Done' || reportWorkspace.status === 'Initializing') {
      return
    }
    dispatch(resetInputStageLoadingStatus())
    navigate(`view/${reportWorkspace.id}`)
  }

  const handleExpireAssessmentSubmitSuccess = () => {
    resetTabLoadingStates()
    setAssignmentEnabledId(null)
  }

  const handleAssessmentOnHoldSubmitSuccess = () => {
    resetTabLoadingStates()
  }

  const handleInternalNotesClick = (item: IReportWorkspaceListOptions) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.assessmentRequestId!))
  }

  const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
    setSelectedTab(tabValue)
    dispatch(setShowUnassigned(tabValue === ReportWorkspaceTabs.Unassigned))
    dispatch(setShowAssignedToCurrentUser(tabValue === ReportWorkspaceTabs.AssignedToCurrentUser))

    if (tabValue === ReportWorkspaceTabs.All) {
      dispatch(setSearchText(allSearchText))
      dispatch(showDeleted(allShowDelete))
      dispatch(setPageNumber(allReportPageNumber))
    }
    if (tabValue === ReportWorkspaceTabs.Unassigned) {
      dispatch(setSearchText(unassignedSearchText))
      dispatch(showDeleted(unAssignedShowDelete))
      dispatch(setPageNumber(unassignedReportPageNumber))
    }
    if (tabValue === ReportWorkspaceTabs.AssignedToCurrentUser) {
      dispatch(setSearchText(assignedToCurrentUserSearchText))
      dispatch(showDeleted(assignedToCurrentUserShowDelete))
      dispatch(setPageNumber(assignedToCurrentUserPageNumber))
    }

    resetTabLoadingStates()
  }

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    const currentPageNumber = selectedPage + 1
    if (selectedTab === ReportWorkspaceTabs.All) {
      setAllReportPageNumber(currentPageNumber)
    }
    if (selectedTab === ReportWorkspaceTabs.Unassigned) {
      setUnassignedPageNumber(currentPageNumber)
    }
    if (selectedTab === ReportWorkspaceTabs.AssignedToCurrentUser) {
      setAssignedToCurrentUserPageNumber(currentPageNumber)
    }
    dispatch(setPageNumber(currentPageNumber))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleShowDeletedChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (selectedTab === ReportWorkspaceTabs.All) {
      setAllShowDelete(checked)
    }
    if (selectedTab === ReportWorkspaceTabs.Unassigned) {
      setUnassignedShowDelete(checked)
    }
    if (selectedTab === ReportWorkspaceTabs.AssignedToCurrentUser) {
      setAssignedToCurrentUserShowDelete(checked)
    }

    dispatch(showDeleted(!getReportWorkspacesState.showDeleted))
  }

  const handleSoftDeleteFVA = (reportWorkspaceId: string) => {
    setShowSoftDeleteFVAConfirmationDialog({
      show: false,
      reportWorkspaceId
    })

    dispatch(softDeleteReportWorkspace({
      reportWorkspaceId,
      onSuccess: () => {
        resetTabLoadingStates()
      }
    }))
  }

  const handleFVASearchTextOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    selectedTab === ReportWorkspaceTabs.All && setAllSearchText(event.target.value)
    selectedTab === ReportWorkspaceTabs.Unassigned && setUnassignedSearchText(event.target.value)
    selectedTab === ReportWorkspaceTabs.AssignedToCurrentUser && setAssignedToCurrentUserSearchText(event.target.value)
  }

  const handleFVAResetSearch = () => {
    selectedTab === ReportWorkspaceTabs.All && setAllSearchText('')
    selectedTab === ReportWorkspaceTabs.Unassigned && setUnassignedSearchText('')
    selectedTab === ReportWorkspaceTabs.AssignedToCurrentUser && setAssignedToCurrentUserSearchText('')

    dispatch(setSearchText(''))
  }

  const handleFVASearch = () => {
    selectedTab === ReportWorkspaceTabs.All && dispatch(setSearchText(allSearchText))
    selectedTab === ReportWorkspaceTabs.Unassigned && dispatch(setSearchText(unassignedSearchText))
    selectedTab === ReportWorkspaceTabs.AssignedToCurrentUser && dispatch(setSearchText(assignedToCurrentUserSearchText))
  }

  const generateContextMenu = (item: IReportWorkspaceListOptions): MenuListItemProps[] => {
    return [
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.assessmentRequestId!
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => {
          setAssignmentEnabledId(item.id)
        }
      }),
      OnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => setShowAssessmentOnHoldDialogForm({
          show: true,
          assessmentRequestId: item.assessmentRequestId!
        })
      }),
      TriggerCreateEventContextMenuItem({
        onClick: () => setShowTriggerManualCreatedEventConfirmationDialog({
          show: true,
          assessmentRequestId: item.assessmentRequestId!
        })
      })
    ]
  }

  const softDeleteFVAConfirmationDialog = (
    <CustomDialogForm
      open={showSoftDeleteFVAConfirmationDialog.show}
      primaryText="Yes"
      handleSubmit={() => handleSoftDeleteFVA(showSoftDeleteFVAConfirmationDialog.reportWorkspaceId)}
      onClose={() => setShowSoftDeleteFVAConfirmationDialog({
        ...showSoftDeleteFVAConfirmationDialog,
        show: false
      })}
      title="Do you want to proceed with deleting assessment?"
      secondaryText="Cancel">
      This assessment will be moved to archive.
    </CustomDialogForm>
  )

  const getTabRoles = () => {
    if ((isAdmin(roles) || isSuperAnalyst(roles)) && hasAnalystRole(roles)) {
      return [{ id: ReportWorkspaceTabs.All, label: 'All' },
        { id: ReportWorkspaceTabs.Unassigned, label: 'Unassigned' },
        { id: ReportWorkspaceTabs.AssignedToCurrentUser, label: 'Assigned To Me' }
      ]
    }
    if ((isAdmin(roles) || isSuperAnalyst(roles)) && !hasAnalystRole(roles)) {
      return [{ id: ReportWorkspaceTabs.All, label: 'All' },
        { id: ReportWorkspaceTabs.Unassigned, label: 'Unassigned' }
      ]
    }
    if (isAnalystRoleOnly(roles)) {
      return [{ id: ReportWorkspaceTabs.AssignedToCurrentUser, label: 'Assigned To Me' }]
    }
    return []
  }

  const renderDynamicTabs = () => {
    const tabs: Record<string, string>[] = getTabRoles()

    return tabs.map(({ id, label }) =>
      (
      <Tab
        key={id}
        value={id}
        label={label}
        id={`tab-${id}`}
        aria-controls={`tabpanel-${id}`}
        sx={{ textTransform: 'none', width: 200 }}
      />
      ))
  }

  const formattedColumns = columns.map((item) => {
    return {
      ...item,
      onRowClick: item.id === 'assignedAnalyst' && writeAssignment ? () => { } : undefined
    }
  })

  const formattedReportWorkspaceList = items.map((item, index) => {
    return {
      ...item,
      createdDate: formatDate(item.createdDate),
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering),
      entityAssessedResponseTime: formatDuration(item.entityAssessedResponseTime),
      rowInlineStyles: getTableCellStyle(item),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label && item.assessmentRequestId,
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.assessmentRequestId!}
          index={index}
          value={item.assignedAnalyst!}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          onSuccess={() => {
            resetTabLoadingStates()
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label,
      softDeleteAssessment: !item.isDeleted
        ? <Tooltip title="Soft delete assessment">
          <IconButton
            sx={{ color: 'red' }}
            onClick={(event) => {
              setShowSoftDeleteFVAConfirmationDialog({
                reportWorkspaceId: item.id,
                show: true
              })
              event.stopPropagation()
            }
            }>
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
        : <></>
    }
  })
  const page = { totalCount, size: pageSize, number: pageNumber }

  return (
    <Box p={2} sx={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      position: 'relative'
    }}>
      <Stack spacing={1.5} sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Typography variant='h3'>In Progress</Typography>
        <Typography color={({ palette }) => palette.text.secondary}>Entity assessed has accepted the assessment request</Typography>
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="report-workspace-tabs">
          {renderDynamicTabs()}
        </Tabs>
      </Box>
      <TabPanel id={`report-workspace-${ReportWorkspaceTabs.All}`} selectedTab={selectedTab} value={ReportWorkspaceTabs.All}>
        <PaginatedTable
          columns={formattedColumns}
          items={formattedReportWorkspaceList}
          headerComponent={
            <HeaderComponent
              readDeletedAssessments={readDeletedAssessments}
              showDeletedChecked={allShowDelete}
              onDeletedCheckedChange={handleShowDeletedChange}
              searchValue={allSearchText}
              onSearchTextChange={handleFVASearchTextOnChange}
              onSearchClick={handleFVASearch}
              onResetSearchClick={handleFVAResetSearch}
            />}
          footerComponent={
            <AssessmentFooter
              page={page}
              onTablePageChange={handlePageChange}
              onTablePageSizeChange={handlePageSizeChange}
            />}
          renderMenuRowActions={(item, index) => {
            return (
              <ActionMenu
                isContextMenuDisabled={!item.assessmentRequestId || item.status === 'Initializing'}
                contextMenuIndicatorMarginLeft={0.6}
                menuListItems={generateContextMenu(item)} index={index} itemLength={items.length} />
            )
          }}
          onRowClick={handleViewReportWorkspace}
        />
      </TabPanel>
      <TabPanel id={`report-workspace-${ReportWorkspaceTabs.Unassigned}`} selectedTab={selectedTab} value={ReportWorkspaceTabs.Unassigned}>
        <PaginatedTable
          columns={formattedColumns}
          items={formattedReportWorkspaceList}
          headerComponent={
            <HeaderComponent
              readDeletedAssessments={readDeletedAssessments}
              showDeletedChecked={unAssignedShowDelete}
              onDeletedCheckedChange={handleShowDeletedChange}
              searchValue={unassignedSearchText}
              onSearchTextChange={handleFVASearchTextOnChange}
              onSearchClick={handleFVASearch}
              onResetSearchClick={handleFVAResetSearch}
            />}
          footerComponent={
            <AssessmentFooter
              page={page}
              onTablePageChange={handlePageChange}
              onTablePageSizeChange={handlePageSizeChange}
            />}
          renderMenuRowActions={(item, index) => {
            return (
              <ActionMenu
                isContextMenuDisabled={!item.assessmentRequestId || item.status === 'Initializing'}
                contextMenuIndicatorMarginLeft={0.6}
                menuListItems={generateContextMenu(item)} index={index} itemLength={items.length} />
            )
          }}
          onRowClick={handleViewReportWorkspace}
        />
      </TabPanel>
      <TabPanel id={`report-workspace-${ReportWorkspaceTabs.AssignedToCurrentUser}`} selectedTab={selectedTab} value={ReportWorkspaceTabs.AssignedToCurrentUser}>
        <PaginatedTable
          columns={formattedColumns}
          items={formattedReportWorkspaceList}
          headerComponent={
            <HeaderComponent
              readDeletedAssessments={readDeletedAssessments}
              showDeletedChecked={assignedToCurrentUserShowDelete}
              onDeletedCheckedChange={handleShowDeletedChange}
              searchValue={assignedToCurrentUserSearchText}
              onSearchTextChange={handleFVASearchTextOnChange}
              onSearchClick={handleFVASearch}
              onResetSearchClick={handleFVAResetSearch}
            />}
          footerComponent={
            <AssessmentFooter
              page={page}
              onTablePageChange={handlePageChange}
              onTablePageSizeChange={handlePageSizeChange}
            />}
          renderMenuRowActions={(item, index) => {
            return (
              <ActionMenu
                isContextMenuDisabled={!item.assessmentRequestId || item.status === 'Initializing'}
                contextMenuIndicatorMarginLeft={0.6}
                menuListItems={generateContextMenu(item)} index={index} itemLength={items.length} />
            )
          }}
          onRowClick={handleViewReportWorkspace}
        />
      </TabPanel>
      <AnilityBackdrop open={loading} />
      {softDeleteFVAConfirmationDialog}
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
      <TriggerManualCreatedEventConfirmationDialog
        open={showTriggerManualCreatedEventConfirmationDialog.show}
        assessmentRequestId={showTriggerManualCreatedEventConfirmationDialog.assessmentRequestId}
        onClose={() => setShowTriggerManualCreatedEventConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
      />
      <AssessmentOnHoldDialogForm
        show={showAssessmentOnHoldDialogForm.show}
        assessmentRequestId={showAssessmentOnHoldDialogForm.assessmentRequestId}
        onSubmitSuccess={handleAssessmentOnHoldSubmitSuccess}
        onClose={() =>
          setShowAssessmentOnHoldDialogForm({
            show: false,
            assessmentRequestId: undefined
          })} />
      {writeInternalNotes && <InternalNotes open={showInternalNotes} handleClose={() => setShowInternalNotes(false)} />}
    </Box>
  )
}

export default ReportWorkspaceList

const HeaderComponent = ({
  readDeletedAssessments, showDeletedChecked, onDeletedCheckedChange,
  searchValue, onSearchClick, onResetSearchClick, onSearchTextChange
}: TopInfoComponentProps) =>
  <Grid container justifyContent="flex-end" alignItems="flex-end">
    {readDeletedAssessments && (
      <FormControlLabel
        label="Show deleted"
        sx={{ marginTop: 1, marginRight: 2.5 }}
        control={
          <Switch
            title="Display deleted assessments"
            checked={showDeletedChecked}
            onChange={onDeletedCheckedChange}
          />}
      />
    )}
    <TableSearch searchValue={searchValue} onSearchClick={onSearchClick} onResetSearchClick={onResetSearchClick} onSearchTextChange={onSearchTextChange} />
  </Grid>
