import { Grid } from '@mui/material'
import { TimeInfo } from './time-info'
import { TablePagination, TablePaginationProps } from '../../../components/table-pagination'

export const AssessmentFooter = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <Grid container justifyContent='space-between'>
    <Grid item xs={8} py={2}>
      <TimeInfo />
    </Grid>
    <Grid item xs={4}>
      <TablePagination page={page}
        onTablePageChange={onTablePageChange}
        onTablePageSizeChange={onTablePageSizeChange} />
    </Grid>
  </Grid>
