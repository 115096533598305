import { useEffect } from 'react'

export const useEventListener = (eventName: string, handler: any, element = window) => {
  useEffect(() => {
    element.addEventListener(eventName, handler)

    return () => {
      element.removeEventListener(eventName, handler)
    }
  }, [eventName, element, handler])
}
