import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'

interface GetAnalystDetailsState {
  analystDetails?: AnalystDetails
  loading: LoadingType
  error: string
}

export interface AnalystDetails {
  id: number
  firstName: string
  lastName: string
  fullName: string
  phoneNumber: string
  auth0Id: string
  email: string
  status: string
  createdAt: string
  orderedAssessments: number
  inProgressAssessments: number
  averageAssessmentTimeMs: number
}

const initialState: GetAnalystDetailsState = {
  analystDetails: undefined,
  loading: 'idle',
  error: ''
}

interface GetAnalystDetailsParams {
  analystId: number
}

export const getAnalystDetails = createAsyncThunk<
  AnalystDetails,
  GetAnalystDetailsParams,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('Analyst/getAnalystDetails', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<AnalystDetails>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/analystUsers/${request.analystId}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getAnalystDetailsSlice = createSlice({
  name: 'get-analysts-details',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalystDetails.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getAnalystDetails.fulfilled, (state, { payload }) => {
      state.analystDetails = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getAnalystDetails.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = getAnalystDetailsSlice.actions
