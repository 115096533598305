import React from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

export interface SummaryCardProps {
  title?: string
  value: number | string | React.ReactElement
  description?: string
  minWidth?: number | string
  minHeight?: number | string
  valueVariant?: Variant
  customButton?: React.ReactElement
}

export const SummaryCard = ({
  title,
  value,
  description,
  minWidth,
  minHeight,
  valueVariant,
  customButton
}: SummaryCardProps) => {
  return (
    <Grid item xs>
      <Card sx={{ minWidth: minWidth || '16.25rem', width: 'auto', minHeight: minHeight || '15rem', height: 'auto', aspectRatio: '13/12' }}>
        <CardContent sx={{ position: 'relative', height: '100%' }}>
          {customButton && <Box style={{ position: 'absolute', right: 0, top: 0 }} >
            {customButton}
          </Box>}
          <Grid container flexDirection="column" height="100%" p={2}>
            <Grid item container justifyContent="center" alignItems="flex-start" flex={1}>
              <Typography variant="h5" textAlign="center" sx={{ whiteSpace: 'pre-wrap' }}>{title}</Typography>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" flex={3}>
              <Typography variant={valueVariant ?? 'h1'} textAlign="center">{value}</Typography>
            </Grid>
            <Grid item container justifyContent="center" alignItems="flex-start" flex={1}>
              <Typography variant="body2" color={(theme) => theme.palette.text.secondary} textAlign="center">
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
