import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { AppDispatch, RootState } from '../../../../store/store'
import { CustomerSubsidiaryState, EditCustomerSubsidiaryFormRequest } from './customer-subsidiary'
import { RejectValue } from '../../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../../utils/functions'

const initialState: CustomerSubsidiaryState = {
  loading: 'idle',
  error: ''
}

export const updateCustomerSubsidiary = createAsyncThunk<
  void,
  EditCustomerSubsidiaryFormRequest,
  {
    dispatch: AppDispatch
    state: RootState,
    rejectValue: RejectValue
  }
>(
  'customer/updateCustomerSubsidiary',
  async ({ customerId, ...rest }: EditCustomerSubsidiaryFormRequest, thunkAPI) => {
    const { tokenState, appSettingsState } = thunkAPI.getState()

    try {
      await axios.patch(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${customerId}/subsidiary/${rest.id}/update-customer-subsidiary`, rest, {
        validateStatus: (status) => status < 400,
        headers: { Authorization: `Bearer ${tokenState.token}` }
      })
      rest.onSuccess?.()
    } catch (error) {
      const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
      if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
        return thunkAPI.rejectWithValue({ message: errors.response.data.title })
      }

      return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
    }
  }
)

export const updateCustomerSubsidiarySlice = createSlice({
  name: 'update-customer-subsidiary',
  initialState,
  reducers: {
    resetUpdateCustomerSubsidiary: (state) => {
      state.error = ''
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateCustomerSubsidiary.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(updateCustomerSubsidiary.fulfilled, (state) => {
      state.loading = 'loaded'
    })
    builder.addCase(updateCustomerSubsidiary.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetUpdateCustomerSubsidiary } = updateCustomerSubsidiarySlice.actions
export default updateCustomerSubsidiarySlice.reducer
