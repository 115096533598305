import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'

interface UploadSignedOutreachLetterState {
  loading: LoadingType;
  error: string;
}

const initialState: UploadSignedOutreachLetterState = {
  loading: 'idle',
  error: ''
}

export interface UploadSignedOutreachLetter {
  customerId: number;
  file: File;
  onSuccess?: () => void;
  onFinal?: () => void;
}

export const uploadSignedOutreachLetter = createAsyncThunk<
  void,
  UploadSignedOutreachLetter,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('customer/uploadSignedOutreachLetter', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    const formData = new FormData()
    formData.append('signedOutreachLetter', request.file)
    formData.append('customerId', request.customerId.toString())
    await axios.post<null>(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${request.customerId}/upload-signed-outreach-letter`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  } finally {
    request.onFinal?.()
  }
})

export const uploadSignedOutreachLetterSlice = createSlice({
  name: 'upload-signed-outreach-letter',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(uploadSignedOutreachLetter.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(uploadSignedOutreachLetter.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(uploadSignedOutreachLetter.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = uploadSignedOutreachLetterSlice.actions
export default uploadSignedOutreachLetterSlice.reducer
