import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import TeamMemberInvitation from '../../../interfaces/team-member-invitation'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { getGenericErrorMessage } from '../../../utils/functions'

interface ConflictError {
  message: string;
}

interface InviteCustomerUserState {
  loading: LoadingType;
  error: string;
}

const initialState: InviteCustomerUserState = {
  loading: 'idle',
  error: ''
}

interface InviteCustomerUserParams extends TeamMemberInvitation {
  customerId: number;
  onSuccess?: () => void
}

export const inviteCustomerUser = createAsyncThunk<
  void,
  InviteCustomerUserParams,
  {
    dispatch: AppDispatch
    state: RootState,
    rejectValue: ConflictError
  }
>(
  'customer/inviteCustomerUser',
  async (request: InviteCustomerUserParams, thunkAPI) => {
    const { tokenState, appSettingsState } = thunkAPI.getState()

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${request.customerId}/invite-customer-user`, request, {
        validateStatus: (status) => status < 400,
        headers: { Authorization: `Bearer ${tokenState.token}` }
      })
      request.onSuccess?.()
    } catch (error) {
      const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
      if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
        return thunkAPI.rejectWithValue({ message: errors.response.data.title })
      }

      return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
    }
  }
)

export const inviteCustomerUserSlice = createSlice({
  name: 'invite-customer-user',
  initialState,
  reducers: {
    resetInviteCustomerUser: (state) => {
      state.error = ''
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(inviteCustomerUser.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(inviteCustomerUser.fulfilled, (state) => {
      state.loading = 'loaded'
    })
    builder.addCase(inviteCustomerUser.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export default inviteCustomerUserSlice.reducer
export const { resetInviteCustomerUser } = inviteCustomerUserSlice.actions
