import { useEffect } from 'react'
import { Alert, Box, Card, CardContent, Grid, Link, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { AnilityBackdrop } from '../../components/anility-backdrop'
import { getReportConfigurationsList } from './report-configuration/get-report-configurations-list-slice'

export const BaseModels = () => {
  const dispatch = useAppDispatch()
  const { getReportConfigurationListState } = useAppSelector((state) => state)
  const loading = getReportConfigurationListState.loading === 'loading'
  const error = getReportConfigurationListState.error

  useEffect(() => {
    if (getReportConfigurationListState.loading === 'idle') {
      dispatch(getReportConfigurationsList())
    }
  }, [getReportConfigurationListState.loading])

  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <Box py={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack mb="20px" spacing={1.5}>
            <Typography variant='h3'>Base Models:</Typography>
          </Stack>
        </Box>
        <Box sx={{ flex: 1, position: 'relative' }}>
          <Grid container direction="row">
            <Grid item xs={12}>
              {getReportConfigurationListState.reportConfigurations.map((reportConfiguration) =>
              <Box p={1} key={reportConfiguration.reportType}>
              <Card>
              <CardContent>
                <Grid container>
                  <Grid container item xs={3} alignContent="center">
                    <Link target="_blank" href={reportConfiguration.financialViabilityAssessmentBaseModelUrl}>{reportConfiguration.reportType} ({reportConfiguration.visible ? 'Visible' : 'Hidden'})
                    </Link>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="paragraph" mt={0}>{reportConfiguration.description}</Typography>
                </Grid>
              </CardContent>
            </Card>
            </Box>)}
            </Grid>
            <Grid item container sx={{ marginTop: '2rem' }}>
              <Grid item xs={12}>
                {error && (
                  <Alert severity="error">{error}</Alert>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box >
      <AnilityBackdrop open={loading} />
    </>
  )
}
