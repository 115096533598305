import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PaginatedTable } from '../../../../components/paginated-table'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { formatDate, secureFileDownload } from '../../../../utils/functions'
import { getUnderReviewAssessmentRequests, setPageNumber, setPageSize, UnderReviewAssessmentRequestsItem } from '../../../report/under-review-assessments/get-under-review-assessment-requests-slice'
import { ExpiredContextMenuItem, InternalNoteContextMenuItem, OnHoldAssessmentsContextMenuItem, ReassignAnalystContextMenuItem } from '../../../report/components/assessments-action-menu'
import { setSelectedAssessmentInternalNote } from '../../../../components/set-internal-notes-slice'
import { MenuListItemProps } from '../../../../components/menu-list-item'
import { getAnalystUsers } from '../../../report/get-analyst-users-slice'
import AnalystUsersList from '../../../report/analyst/analyst-users-list'
import { usePermission } from '../../../../hooks/use-permission'
import { AnilityScopes as FiableScopes } from '../../../../interfaces/anility-scopes'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../../../report/components/expire-assessment-confirmation-dialog'
import AssessmentOnHoldDialogForm, { AssessmentOnHoldDialogState } from '../../../report/components/assessment-on-hold-dialog-form'
import { ActionMenu } from '../../../../components/action-menu'
import { getAssessmentColumns } from '../../../report/under-review-assessments/under-review-assessments'
import { AssessmentFooter } from '../../../report/components/assessment-footer'
import { TableHeader } from '../../../../components/table-header'

interface CustomerUnderReviewAssessmentsProps {
  status: 'UnderReview' | 'ClarificationsRequired'
  customerId: string;
  searchValue: string;
  onSearchClick: () => void;
  onResetSearchClick: () => void;
  onSearchTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setShowInternalNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

const getTableCellStyle = () => ({
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  cursor: 'pointer'
})

export const CustomerUnderReviewAssessments = ({ status, customerId, searchValue, onSearchClick, onResetSearchClick, onSearchTextChange, setShowInternalNotes }: CustomerUnderReviewAssessmentsProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { getUnderReviewAssessmentRequestsState, getAnalystUsersState, tokenState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount } = getUnderReviewAssessmentRequestsState
  const [writeAssignment, writeAssessment] = usePermission([FiableScopes.Write.Assignment, FiableScopes.Write.Assessment])
  const [assignmentEnabledId, setAssignmentEnabledId] = useState<number | null>(null)

  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const [showAssessmentOnHoldDialogForm, setShowAssessmentOnHoldDialogForm] = useState<AssessmentOnHoldDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const loadUnderReviewAssessmentsInCustomers = () => {
    dispatch(getUnderReviewAssessmentRequests({
      customerId,
      status
    }))
  }

  useEffect(() => {
    if (getUnderReviewAssessmentRequestsState.loading === 'idle') {
      loadUnderReviewAssessmentsInCustomers()
    }
  }, [getUnderReviewAssessmentRequestsState.loading, customerId])

  useEffect(() => {
    dispatch(getAnalystUsers())
  }, [customerId])

  const handleExpireAssessmentSubmitSuccess = () => {
    if (!customerId) {
      return
    }
    loadUnderReviewAssessmentsInCustomers()
    setAssignmentEnabledId(null)
  }

  const handleAssessmentOnHoldSubmitSuccess = () => {
    if (!customerId) {
      return
    }
    loadUnderReviewAssessmentsInCustomers()
  }

  const handleInternalNotesClick = (item: UnderReviewAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const generateContextMenu = (item: UnderReviewAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.id
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => { setAssignmentEnabledId(item.id) }
      }),
      OnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => setShowAssessmentOnHoldDialogForm({
          show: true,
          assessmentRequestId: item.id
        })
      })
    ]
  }

  const handleSearch = () => {
    onSearchClick()
  }

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleUnderReviewAssessmentDetailsClick = (
    underReviewAssessmentRequestDetails: UnderReviewAssessmentRequestsItem
  ) => {
    if (!underReviewAssessmentRequestDetails) {
      navigate(-1)
    }
    navigate(`/report/under-review-assessments/workspace/${underReviewAssessmentRequestDetails.id}`, {
      state: {
        status
      }
    })
  }

  const columns = getAssessmentColumns(status)

  const formattedColumns = columns.map((item) => {
    return {
      ...item,
      onRowClick: item.id === 'assignedAnalyst' && writeAssignment ? () => { } : undefined
    }
  })

  const formattedUnderReviewAssessments = items.map((item: UnderReviewAssessmentRequestsItem, index: number) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      submittedDate: formatDate(item.submittedDate),
      dateFirstClarificationSent: formatDate(item.dateFirstClarificationSent),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label,
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.id}
          index={index}
          value={item.assignedAnalyst!}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          onSuccess={() => {
            loadUnderReviewAssessmentsInCustomers()
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label,
      rowInlineStyles: getTableCellStyle()
    }
  })

  const page = useMemo(() => {
    return { totalCount, size: pageSize, number: pageNumber }
  }, [totalCount, pageSize, pageNumber])

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()

    params.append('status', status)
    params.append('search', searchValue)
    params.append('customerId', customerId)

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/export?${params.toString()}`, tokenState.token)
  }

  return (
    <>
      <PaginatedTable
        columns={formattedColumns}
        items={formattedUnderReviewAssessments}
        headerComponent={
          <TableHeader
            searchValue={searchValue}
            onSearchTextChange={onSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={onResetSearchClick}
            onExportButtonClick={handleExportButtonClick} />}
        footerComponent={
          <AssessmentFooter
            page={page}
            onTablePageChange={handlePageChange}
            onTablePageSizeChange={handlePageSizeChange}
          />
        }
        renderMenuRowActions={(item, index, itemLength) => {
          return (
            <ActionMenu
              isContextMenuDisabled={false}
              contextMenuIndicatorMarginLeft={0.6}
              menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
          )
        }}
        onRowClick={handleUnderReviewAssessmentDetailsClick}
      />
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
      <AssessmentOnHoldDialogForm
        show={showAssessmentOnHoldDialogForm.show}
        onSubmitSuccess={handleAssessmentOnHoldSubmitSuccess}
        assessmentRequestId={showAssessmentOnHoldDialogForm.assessmentRequestId}
        onClose={() =>
          setShowAssessmentOnHoldDialogForm({
            show: false,
            assessmentRequestId: undefined
          })} />
    </>
  )
}
