import { useCallback } from 'react'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { useAppDispatch } from '../../../hooks/hooks'
import { updateExpiredAssessment } from '../update-expired-assessment-slice'

export const ExpireAssessmentConfirmationDialog = ({ open, assessmentRequestId, onClose, onSubmitSuccess }: ExpireAssessmentConfirmationDialogProps) => {
  const dispatch = useAppDispatch()
  const handleSubmit = useCallback(() => {
    if (!assessmentRequestId) {
      return
    }

    dispatch(updateExpiredAssessment({
      assessmentRequestId,
      onSuccess: () => {
        onSubmitSuccess?.()
        onClose()
      }
    }))
  }, [assessmentRequestId])

  return (
    <CustomDialogForm
      open={open}
      primaryText="Yes"
      handleSubmit={handleSubmit}
      onClose={onClose}
      title="Do you want to proceed with expiring the assessment?"
      secondaryText="Cancel">
      This assessment will be moved to expired. Both the customer and entity will be notified via email.
    </CustomDialogForm>
  )
}

export interface ExpireAssessmentConfirmationDialogProps {
  open: boolean
  assessmentRequestId?: number
  onClose: () => void
  onSubmitSuccess?: () => void
}

export interface ExpireAssessmentConfirmationDialogState {
  show: boolean
  assessmentRequestId?: number
}
