import { getOrderedAssessmentRequestsSlice } from './report/ordered-assessments/get-ordered-assessment-requests-slice'
import { getCompletedAssessmentRequestsSlice } from './report/completed-assessments/get-completed-assessment-requests-slice'
import { getReportWorkspaceBaseModelSlice } from './report/get-report-workspace-base-model-slice'
import { getSummarySlice } from './report/get-summary-slice'
import { generateReportWorkspacePdfSlice } from './report/report-workspace/generate-report-workspace-pdf-slice'
import { getReportWorkspaceDetailsSlice } from './report/report-workspace/get-report-workspace-details-slice'
import { getReportWorkspacesSlice } from './report/report-workspace/get-report-workspace-list-slice'
import { markWorkspaceAsDoneSlice } from './report/report-workspace/mark-workspace-as-done-slice'
import { runReportWorkspaceInputStageSlice } from './report/report-workspace/run-report-workspace-input-stage-slice'
import { runReportWorkspaceOutputStageSlice } from './report/report-workspace/run-report-workspace-output-stage-slice'
import { softDeleteReportWorkspaceSlice } from './report/report-workspace/soft-delete-report-workspace-slice'
import { runReportWorkspaceStagesSlice } from './report/report-workspace/run-report-workspace-stages-slice'
import { getExpiredAssessmentRequestsSlice } from './report/expired-assessments/get-expired-assessment-requests-slice'
import { updateExpiredAssessmentSlice } from './report/update-expired-assessment-slice'
import { setAnalystCommentsSlice } from './report/report-workspace/set-analyst-comments-slice'
import { getCustomersSlice } from './crm-tools/customers/get-customers-slice'
import { updateCustomerSubscriptionInformationSlice } from './crm-tools/customers/customer-subscription-information/update-customer-subscription-information-slice'
import { getCustomerDetailsSlice } from './crm-tools/customers/get-customer-details-slice'
import { getCustomerUsersSlice } from './crm-tools/customers/get-customer-users-slice'
import { setInternalNotesSlice } from '../components/set-internal-notes-slice'
import { getAssessmentRequestsInfoSlice } from './crm-tools/customers/get-assessment-requests-info-slice'
import { getEntityAssessedListSlice } from './crm-tools/entity-assessed/get-entity-assessed-list-slice'
import { getEntityAssessedDetailsSlice } from './crm-tools/entity-assessed/get-entity-assessed-details-slice'
import { getEntityAssessedUsersSlice } from './crm-tools/entity-assessed/get-entity-assessed-users-slice'
import { getAnalystUsersSlice } from './report/get-analyst-users-slice'
import { assignAnalystUserToAssessmentRequestsSlice } from './report/analyst/assign-analyst-to-assessment-requests-slice'
import { getAnalystUsersListSlice } from './analyst-tools/analysts/get-analyst-users-list-slice'
import { getAnalystDetailsSlice } from './analyst-tools/analysts/get-analyst-details-slice'
import { getAnalystCompletedAssessmentRequestsSlice } from './analyst-tools/analysts/analyst-assessments/get-analyst-completed-assessments-slice'
import { getAnalystOrderedAssessmentRequestsSlice } from './analyst-tools/analysts/analyst-assessments/get-analyst-ordered-assessments-slice'
import { getAnalystInProgressReportsSlice } from './analyst-tools/analysts/analyst-assessments/get-analyst-in-progress-reports-slice'
import { uploadPPSRSlice } from './report/report-workspace/upload-ppsr-slice'
import { deletePPSRSlice } from './report/report-workspace/delete-ppsr-slice'
import { triggerManualCreatedEventSlice } from './report/trigger-manual-created-event-slice'
import { getReportWorkspaceSuperFormOptionsSlice } from './report/report-workspace/get-report-workspace-super-form-options'
import { createReportWorkspaceSlice } from './report/report-workspace/create-report-workspace-slice'
import { uploadSignedOutreachLetterSlice } from './crm-tools/customers/upload-signed-outreach-letter-slice'
import { deleteSignedOutreachLetterSlice } from './crm-tools/customers/delete-signed-outreach-letter-slice'
import { setAssessmentRequestOnHoldStatusSlice } from './report/set-assessment-request-on-hold-status-slice'
import { getOnHoldAssessmentRequestsSlice } from './report/on-hold-assessments/get-on-hold-assessment-requests-slice'
import { getUnderReviewAssessmentRequestsSlice } from './report/under-review-assessments/get-under-review-assessment-requests-slice'
import { approveUnderReviewAssessmentSlice } from './report/under-review-assessments/approve-under-review-assessment-slice'
import { getSubmissionReviewWorkspaceSlice } from './report/under-review-assessments/get-submission-review-workspace-slice'
import { setAssessmentRequestFinancialStatementPreparationSlice } from './report/under-review-assessments/set-assessment-request-financial-statement-preparation-slice'
import { getEntityAssessedUsersDomainListSlice } from './crm-tools/entity-assessed/get-entity-users-domain-list-slice'
import { inviteEntityAssessedUserSlice } from './crm-tools/entity-assessed/invite-entity-assessed-user-slice'
import { syncCrmSlice } from './developer-tools/jobs/sync-crm-slice'
import { getReportConfigurationsListSlice } from './developer-tools/report-configuration/get-report-configurations-list-slice'
import { getReportConfigurationSlice } from './developer-tools/report-configuration/get-report-configuration-slice'
import { setAssessmentRequestSubmissionReviewCompletedSlice } from './report/under-review-assessments/set-assessment-request-submission-review-completed-slice'
import { syncCrmDealSlice } from './report/under-review-assessments/sync-crm-deal-slice'
import { getCustomerSubsidiariesSlice } from './crm-tools/customers/get-customer-subsidiaries-slice'
import { addCustomerSubsidiarySlice } from './crm-tools/customers/customer-subsidiary-details/add-customer-subsidiary-slice'
import { getCustomerSubsidiaryDetailsSlice } from './crm-tools/customers/customer-subsidiary-details/get-customer-subsidiary-details-slice'
import { updateCustomerSubsidiarySlice } from './crm-tools/customers/customer-subsidiary-details/update-customer-subsidiary-slice'
import { uploadCustomerSubsidiarySignedOutreachLetterSlice } from './crm-tools/customers/customer-subsidiary-details/upload-customer-subsidiary-signed-outreach-letter-slice'
import { deleteCustomerSubsidiarySignedOutreachLetterSlice } from './crm-tools/customers/customer-subsidiary-details/delete-customer-subsidiary-signed-outreach-letter-slice'
import { inviteCustomerUserSlice } from './crm-tools/customers/invite-customer-user-slice'
import { resendInviteCustomerUserSlice } from './crm-tools/customers/resend-invite-customer-user-slice'
import { resendInviteEntityAssessedUserSlice } from './crm-tools/entity-assessed/resend-invite-entity-assessed-user-slice'
import { reactivateDeclinedAssessmentRequestSlice } from './report/expired-assessments/reactivate-declined-assessment-request-slice'
import { reactivateCustomerUserSlice } from './crm-tools/customers/reactivate-customer-user-slice'
import { reactivateEntityAssessedUserSlice } from './crm-tools/entity-assessed/reactivate-entity-assessed-user-slice'
import { setAssessmentRequestToClarificationsRequiredSlice } from './report/under-review-assessments/set-assessment-request-to-clarifications-required-slice'
import { getAnalystBasicInfoSlice } from './analyst-tools/analysts/get-analyst-basic-info-slice'
import { setSelectedEntityAssessedSlice } from './crm-tools/entity-assessed/set-selected-entity-assessed-slice'
import { sendCustomerSSOInitialBatchInvitesSlice } from './crm-tools/customers/send-customer-sso-initial-batch-invites-slice'
import { updateCustomerSpecialRequirementsSlice } from './crm-tools/customers/update-customer-special-requirements-slice'
import { updateEntityAssessedSpecialRequirementsSlice } from './crm-tools/entity-assessed/update-entity-assessed-special-requirements'
import { confirmSpecialRequirementsSlice } from './report/report-workspace/confirm-special-requirements-slice'
import { uploadReportWorkspaceFileAttachmentSlice } from './report/report-workspace/upload-report-workspace-file-attachment-slice'
import { getReportWorkspaceFileAttachmentsSlice } from './report/report-workspace/get-report-workspace-file-attachments-slice'
import { deleteReportWorkspaceFileAttachmentSlice } from './report/report-workspace/delete-report-workspace-file-attachment-slice'
import { getFinancialStatementFilesSlice } from './report/under-review-assessments/financial-extraction-tool/get-financial-statement-files-slice'
import { getFinancialStatementTablesSlice } from './report/under-review-assessments/financial-extraction-tool/get-financial-statement-tables-slice'
import { refreshIndustryInsightsSlice } from './developer-tools/jobs/refresh-industry-insights-slice'

export const PagesReducers = {
  getReportConfigurationState: getReportConfigurationSlice.reducer,
  getReportWorkspacesState: getReportWorkspacesSlice.reducer,
  getOnHoldAssessmentState: getOnHoldAssessmentRequestsSlice.reducer,
  getReportWorkspaceDetailsState: getReportWorkspaceDetailsSlice.reducer,
  runReportWorkspaceOutputStageState: runReportWorkspaceOutputStageSlice.reducer,
  runReportWorkspaceInputStageState: runReportWorkspaceInputStageSlice.reducer,
  generateReportWorkspacePdfState: generateReportWorkspacePdfSlice.reducer,
  getReportWorkspaceBaseModelState: getReportWorkspaceBaseModelSlice.reducer,
  markWorkspaceAsDoneState: markWorkspaceAsDoneSlice.reducer,
  softDeleteReportWorkspaceState: softDeleteReportWorkspaceSlice.reducer,
  getSummaryState: getSummarySlice.reducer,
  getOrderedAssessmentRequestsState: getOrderedAssessmentRequestsSlice.reducer,
  getCompletedAssessmentRequestsState: getCompletedAssessmentRequestsSlice.reducer,
  getExpiredAssessmentRequestsState: getExpiredAssessmentRequestsSlice.reducer,
  runReportWorkspaceStagesState: runReportWorkspaceStagesSlice.reducer,
  updateExpiredAssessmentState: updateExpiredAssessmentSlice.reducer,
  setAnalystCommentsState: setAnalystCommentsSlice.reducer,
  getCustomersState: getCustomersSlice.reducer,
  updateCustomerSubscriptionInformationState: updateCustomerSubscriptionInformationSlice.reducer,
  getCustomerDetailsState: getCustomerDetailsSlice.reducer,
  getCustomerUsersState: getCustomerUsersSlice.reducer,
  getCustomerSubsidiariesState: getCustomerSubsidiariesSlice.reducer,
  setInternalNotesState: setInternalNotesSlice.reducer,
  getAssessmentRequestsInfoState: getAssessmentRequestsInfoSlice.reducer,
  getEntityAssessedListState: getEntityAssessedListSlice.reducer,
  getEntityAssessedDetailsState: getEntityAssessedDetailsSlice.reducer,
  getEntityAssessedUsersState: getEntityAssessedUsersSlice.reducer,
  getAnalystUsersState: getAnalystUsersSlice.reducer,
  getAnalystDetailsState: getAnalystDetailsSlice.reducer,
  assignAnalystUserToAssessmentRequestsState: assignAnalystUserToAssessmentRequestsSlice.reducer,
  getAnalystUsersListState: getAnalystUsersListSlice.reducer,
  getAnalystCompletedAssessmentRequestsState: getAnalystCompletedAssessmentRequestsSlice.reducer,
  getAnalystOrderedAssessmentRequestsState: getAnalystOrderedAssessmentRequestsSlice.reducer,
  getAnalystInProgressReportsState: getAnalystInProgressReportsSlice.reducer,
  uploadPPSRState: uploadPPSRSlice.reducer,
  deletePPSRState: deletePPSRSlice.reducer,
  triggerManualCreatedEventState: triggerManualCreatedEventSlice.reducer,
  getReportWorkspaceSuperFormOptionsState: getReportWorkspaceSuperFormOptionsSlice.reducer,
  createReportWorkspaceState: createReportWorkspaceSlice.reducer,
  uploadSignedOutreachLetterState: uploadSignedOutreachLetterSlice.reducer,
  deleteSignedOutreachLetterState: deleteSignedOutreachLetterSlice.reducer,
  setAssessmentRequestOnHoldStatusState: setAssessmentRequestOnHoldStatusSlice.reducer,
  getUnderReviewAssessmentRequestsState: getUnderReviewAssessmentRequestsSlice.reducer,
  approveUnderReviewAssessmentState: approveUnderReviewAssessmentSlice.reducer,
  getSubmissionReviewWorkspaceState: getSubmissionReviewWorkspaceSlice.reducer,
  setAssessmentRequestFinancialStatementPreparationState: setAssessmentRequestFinancialStatementPreparationSlice.reducer,
  getEntityAssessedUsersDomainListState: getEntityAssessedUsersDomainListSlice.reducer,
  inviteEntityAssessedUserState: inviteEntityAssessedUserSlice.reducer,
  syncCrmState: syncCrmSlice.reducer,
  getReportConfigurationListState: getReportConfigurationsListSlice.reducer,
  setAssessmentRequestSubmissionReviewCompletedState: setAssessmentRequestSubmissionReviewCompletedSlice.reducer,
  syncCrmDealState: syncCrmDealSlice.reducer,
  addCustomerSubsidiaryState: addCustomerSubsidiarySlice.reducer,
  getCustomerSubsidiaryDetailsState: getCustomerSubsidiaryDetailsSlice.reducer,
  updateCustomerSubsidiaryDetailsState: updateCustomerSubsidiarySlice.reducer,
  uploadCustomerSubsidiarySignedOutreachLetterState: uploadCustomerSubsidiarySignedOutreachLetterSlice.reducer,
  deleteCustomerSubsidiarySignedOutreachLetterState: deleteCustomerSubsidiarySignedOutreachLetterSlice.reducer,
  inviteCustomerTeamMemberState: inviteCustomerUserSlice.reducer,
  reactivateDeclinedAssessmentRequestState: reactivateDeclinedAssessmentRequestSlice.reducer,
  resendInviteCustomerUserState: resendInviteCustomerUserSlice.reducer,
  resendInviteEntityAssessedUserState: resendInviteEntityAssessedUserSlice.reducer,
  reactivateCustomerUserState: reactivateCustomerUserSlice.reducer,
  reactivateEntityAssessedUserState: reactivateEntityAssessedUserSlice.reducer,
  setAssessmentRequestToClarificationsRequiredState: setAssessmentRequestToClarificationsRequiredSlice.reducer,
  getAnalystBasicInfoState: getAnalystBasicInfoSlice.reducer,
  setSelectedEntityAssessedState: setSelectedEntityAssessedSlice.reducer,
  sendCustomerSSOInitialBatchInvitesState: sendCustomerSSOInitialBatchInvitesSlice.reducer,
  updateCustomerSpecialRequirementsState: updateCustomerSpecialRequirementsSlice.reducer,
  updateEntityAssessedSpecialRequirementsState: updateEntityAssessedSpecialRequirementsSlice.reducer,
  confirmSpecialRequirementsState: confirmSpecialRequirementsSlice.reducer,
  uploadReportWorkspaceFileAttachmentState: uploadReportWorkspaceFileAttachmentSlice.reducer,
  getReportWorkspaceFileAttachmentsState: getReportWorkspaceFileAttachmentsSlice.reducer,
  deleteReportWorkspaceFileAttachmentState: deleteReportWorkspaceFileAttachmentSlice.reducer,
  getFinancialStatementFilesState: getFinancialStatementFilesSlice.reducer,
  getFinancialStatementTablesState: getFinancialStatementTablesSlice.reducer,
  refreshIndustryInsightsState: refreshIndustryInsightsSlice.reducer
}
