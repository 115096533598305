import axios from 'axios'

export const getAxiosWithInterceptor = (onUnAuthorized: () => void) => {
  const response = axios.interceptors.response.use(
    function (response) {
      // If the response was successful, just return it
      return response
    },
    function (error) {
      // If the response had a status of 401, call onUnAuthorized
      if (error.response && error.response.status === 401) {
        onUnAuthorized?.()
      }

      // If the response had a different status, just reject the promise with the error
      return Promise.reject(error)
    }
  )
  return { response }
}
