
import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react'

import { PaginatedTable } from '../../../../components/paginated-table'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { formatDate, formatDuration, secureFileDownload } from '../../../../utils/functions'
import { setPageNumber, setPageSize, OnHoldAssessmentRequestsItem, getOnHoldAssessmentRequests } from '../../../report/on-hold-assessments/get-on-hold-assessment-requests-slice'
import { ExpiredContextMenuItem, InternalNoteContextMenuItem, ReassignAnalystContextMenuItem, UnOnHoldAssessmentsContextMenuItem } from '../../../report/components/assessments-action-menu'
import { MenuListItemProps } from '../../../../components/menu-list-item'
import { setSelectedAssessmentInternalNote } from '../../../../components/set-internal-notes-slice'
import { ActionMenu } from '../../../../components/action-menu'
import AnalystUsersList from '../../../report/analyst/analyst-users-list'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../../../report/components/expire-assessment-confirmation-dialog'
import { AssessmentRemoveOnHoldConfirmationDialog, AssessmentRemoveOnHoldConfirmationDialogState } from '../../../report/components/assessment-remove-on-hold-confirmation-dialog'
import { usePermission } from '../../../../hooks/use-permission'
import { AnilityScopes as FiableScopes } from '../../../../interfaces/anility-scopes'
import { columns } from '../../../report/on-hold-assessments/on-hold-assessments'
import { AssessmentFooter } from '../../../report/components/assessment-footer'
import { TableHeader } from '../../../../components/table-header'

interface CustomerOnHoldAssessmentsProps {
  customerId: string;
  searchValue: string;
  onSearchClick: () => void;
  onResetSearchClick: () => void;
  onSearchTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setShowInternalNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

const tableCellStyles = {
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  cursor: 'pointer'
}

export const CustomerOnHoldAssessments = ({ customerId, searchValue, onSearchClick, onResetSearchClick, onSearchTextChange, setShowInternalNotes }: CustomerOnHoldAssessmentsProps) => {
  const dispatch = useAppDispatch()

  const { getOnHoldAssessmentState, getAnalystUsersState, tokenState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount } = getOnHoldAssessmentState
  const [writeAssignment, writeAssessment] = usePermission([FiableScopes.Write.Assignment, FiableScopes.Write.Assessment])
  const [assignmentEnabledId, setAssignmentEnabledId] = useState<number | null>(null)
  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const [showRemoveOnHoldAssessmentDialog, setShowRemoveOnHoldAssessmentDialog] = useState<AssessmentRemoveOnHoldConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined,
      status: ''
    }
  )

  const loadOnHoldAssessmentsInCustomers = () => {
    dispatch(getOnHoldAssessmentRequests({
      customerId
    }))
  }

  useEffect(() => {
    if (getOnHoldAssessmentState.loading === 'idle') {
      loadOnHoldAssessmentsInCustomers()
    }
  }, [getOnHoldAssessmentState.loading, customerId])

  const handleInternalNotesClick = (item: OnHoldAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const handleExpireAssessmentSubmitSuccess = () => {
    loadOnHoldAssessmentsInCustomers()
    setAssignmentEnabledId(null)
  }

  const handleAssessmentRemoveOnHoldSubmitSuccess = () => {
    loadOnHoldAssessmentsInCustomers()
  }

  const generateContextMenu = (item: OnHoldAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.id
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => { setAssignmentEnabledId(item.id) }
      }),
      UnOnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => setShowRemoveOnHoldAssessmentDialog({
          show: true,
          assessmentRequestId: item.id,
          status: item.status
        })
      })
    ]
  }

  const handleSearch = () => {
    onSearchClick()
  }

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const formattedOnHoldAssessments = items.map((item, index) => {
    return {
      ...item,
      orderedDate: formatDate(item.orderDate),
      customerName: item.customer,
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering),
      entityAssessedResponseTime: item.entityAssessedResponseTime === 0 ? '' : formatDuration(item.entityAssessedResponseTime),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label,
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.id}
          index={index}
          value={item.assignedAnalyst!}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          onSuccess={() => {
            loadOnHoldAssessmentsInCustomers()
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label,
      rowInlineStyles: tableCellStyles
    }
  })

  const page = useMemo(() => {
    return { totalCount, size: pageSize, number: pageNumber }
  }, [totalCount, pageSize, pageNumber])

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()

    params.append('search', searchValue)
    params.append('customerId', customerId)
    params.append('isOnHold', 'true')

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/export?${params.toString()}`, tokenState.token)
  }

  return (
    <>
      <PaginatedTable
        columns={columns}
        items={formattedOnHoldAssessments}
        headerComponent={
          <TableHeader
            searchValue={searchValue}
            onSearchTextChange={onSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={onResetSearchClick}
            onExportButtonClick={handleExportButtonClick} />}
        footerComponent={
          <AssessmentFooter
            page={page}
            onTablePageChange={handlePageChange}
            onTablePageSizeChange={handlePageSizeChange}
          />
        }
        renderMenuRowActions={(item, index, itemLength) => {
          return (
            <ActionMenu
              isContextMenuDisabled={false}
              contextMenuIndicatorMarginLeft={0.6}
              menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
          )
        }}
      />
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
      <AssessmentRemoveOnHoldConfirmationDialog
        open={showRemoveOnHoldAssessmentDialog.show}
        assessmentRequestId={showRemoveOnHoldAssessmentDialog.assessmentRequestId}
        status={showRemoveOnHoldAssessmentDialog.status}
        onSubmitSuccess={handleAssessmentRemoveOnHoldSubmitSuccess}
        onClose={() => setShowRemoveOnHoldAssessmentDialog({
          show: false,
          assessmentRequestId: undefined,
          status: ''
        })}
      />
    </>
  )
}
