import { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { Notification, NotificationMessage } from '../../../components/notification-message'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { syncCrm } from './sync-crm-slice'
import { refreshIndustryInsights } from './refresh-industry-insights-slice'

export const JobList = () => {
  const dispatch = useAppDispatch()
  const { syncCrmState, refreshIndustryInsightsState } = useAppSelector((state) => state)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const loading = syncCrmState.loading === 'loading' || refreshIndustryInsightsState.loading === 'loading'

  useEffect(() => {
    const tempNotifications: Notification[] = []
    if (syncCrmState.error) {
      tempNotifications.push({
        message: syncCrmState.error,
        severity: 'error'
      })
    }
    setNotifications(tempNotifications)
  }, [syncCrmState.error])

  const handleSyncCrmClick = () => {
    dispatch(syncCrm({
      onSuccess: () => {
        const tempNotifications: Notification[] = []
        tempNotifications.push({
          message: 'Sync CRM completed successfully.',
          severity: 'success'
        })
        setNotifications(tempNotifications)
      }
    }))
  }

  const handleRefreshIndustryInsightsClick = () => {
    dispatch(refreshIndustryInsights({
      onSuccess: () => {
        const tempNotifications: Notification[] = []
        tempNotifications.push({
          message: 'Refresh industry insights completed successfully.',
          severity: 'success'
        })
        setNotifications(tempNotifications)
      },
      onError: (error) => {
        const tempNotifications: Notification[] = []
        tempNotifications.push({
          message: `Refresh industry insights error: ${error.message}`,
          severity: 'error'
        })
        setNotifications(tempNotifications)
      }
    }))
  }

  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        rowGap: 0.2
      }}>
        <Box py={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack spacing={1.5}>
            <Typography variant='h3'>Jobs</Typography>
            <Typography color={({ palette }) => palette.text.secondary}>List of Anility jobs that can be triggered manually.</Typography>
          </Stack>
        </Box>
        <Card>
          <CardContent>
            <Grid container rowGap={1.5}>
              <JobItem name="Sync CRM" description="Sync the CRM assessment activities, notes, and emails." onClick={handleSyncCrmClick} />
              <JobItem name="Refresh Industry Insights" description="Refresh the industry insights from IBIS World." onClick={handleRefreshIndustryInsightsClick} />
            </Grid>
          </CardContent>
        </Card>
        <NotificationMessage items={notifications} />
      </Box>
      <AnilityBackdrop open={loading} />
    </>
  )
}

interface JobItemProps {
  name: string
  description?: string
  onClick?: () => void
}

const JobItem = ({
  name,
  description,
  onClick
}: JobItemProps) => {
  return (
    <>
      <Grid container item xs={3} alignContent="center">
        <Button variant="text" sx={{ height: '2rem' }} onClick={onClick}>{name}</Button>
      </Grid>
      <Grid container item xs={9} alignContent="center">
        <Typography mt={0} variant="paragraph">
          {description}
        </Typography>
      </Grid>
    </>
  )
}
