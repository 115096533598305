import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'

interface GetAssessmentRequestsInfoState {
  assessmentRequestInfo: AssessmentRequestsInfo | null
  loading: LoadingType
  error: string
}

export interface AssessmentRequestsInfo {
  id: number
  internalNotes: string | ''
  onHoldReason: string | ''
}

const initialState: GetAssessmentRequestsInfoState = {
  assessmentRequestInfo: null,
  loading: 'idle',
  error: ''
}

interface GetAssessmentRequestsInfoParams {
  assessmentRequestId: number
  onSuccess?: () => void
}

export const getAssessmentRequestsInfo = createAsyncThunk<
  AssessmentRequestsInfo,
  GetAssessmentRequestsInfoParams,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('report/getAssessmentRequestsInfo', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<AssessmentRequestsInfo>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getAssessmentRequestsInfoSlice = createSlice({
  name: 'get-assessment-request-info',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAssessmentRequestsInfo.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getAssessmentRequestsInfo.fulfilled, (state, { payload }) => {
      state.assessmentRequestInfo = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getAssessmentRequestsInfo.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = getAssessmentRequestsInfoSlice.actions
