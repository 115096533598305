import { Box, Grid } from '@mui/material'
import { SummaryCard } from '../../../../components/summary-card'
import { AnalystDetails } from '../get-analyst-details-slice'
import { formatDuration } from '../../../../utils/functions'

export const AnalystDetailsSummary = ({
  analystDetails
}: {
  analystDetails?: AnalystDetails;
}) => {
  return (
    <>
      <Box>
        <Grid
          container
          rowGap={1}
          columnGap={1}
          justifyContent="space-between"
          marginLeft={0}
        >
          <SummaryCard
            title="Ordered"
            value={analystDetails?.orderedAssessments ?? 'N/A'}
            description={'Assessments'}
          />
          <SummaryCard
            title="In Progress"
            value={analystDetails?.inProgressAssessments ?? 'N/A'}
            description={'Assessments since joining'}
          />
          <SummaryCard
            title="Average Time"
            value={formatDuration(analystDetails?.averageAssessmentTimeMs ?? 0)}
            description={'To Complete an assessment'}
          />
        </Grid>
      </Box>
    </>
  )
}
