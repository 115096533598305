import React, { ChangeEvent, useEffect, useState } from 'react'
import { AnalystTabTypes } from '../../../../interfaces/tabs'
import { AnilityScopes } from '../../../../interfaces/anility-scopes'
import { Box, Tab, Tabs } from '@mui/material'
import { InternalNotes } from '../../../../components/internal-notes'
import { usePermission } from '../../../../hooks/use-permission'
import {
  setSearchText as searchAnalystOrderedAssessment, resetLoadingStatus as resetAnalystOrderedAssessment, setPageNumber as
  setAnalystOrderedAssessmentTablePageNumber
} from './get-analyst-ordered-assessments-slice'
import {
  setSearchText as searchAnalystCompletedAssessment, resetLoadingStatus as resetAnalystCompletedAssessment,
  setPageNumber as setAnalystCompletedAssessmentTablePageNumber
} from './get-analyst-completed-assessments-slice'
import {
  setSearchText as searchAnalystInProgressReports, resetLoadingStatus as resetAnalystInProgressReports,
  setPageNumber as setAnalystInProgressReportsPageNumber
} from './get-analyst-in-progress-reports-slice'

import { useAppDispatch } from '../../../../hooks/hooks'
import { AnalystOrderedAssessments } from './analyst-ordered-assessments'
import { AnalystCompletedAssessments } from './analyst-completed-assessments'
import { AnalystInProgressReports } from './analyst-in-progress-reports'
import TabPanel from '../../../../components/tab-panel'

interface AnalystDetailsAssessmentProps {
  analystId: number
}

const AnalystDetailsAssessment = ({ analystId }: AnalystDetailsAssessmentProps) => {
  const dispatch = useAppDispatch()
  const [selectedTab, setSelectedTab] = useState<string>(AnalystTabTypes.Ordered)
  const [orderedAssessmentsSearchText, setOrderedAssessmentSearchText] = useState<string>('')
  const [inProgressAssessmentsSearchText, setInProgressAssessmentSearchText] = useState<string>('')
  const [completedAssessmentsSearchText, setCompletedAssessmentSearchText] = useState<string>('')
  const [showInternalNotes, setShowInternalNotes] = useState(false)
  const [writeInternalNotes] = usePermission([AnilityScopes.Write.InternalNotes])

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue)
    dispatch(resetAnalystOrderedAssessment())
    dispatch(resetAnalystInProgressReports())
    dispatch(resetAnalystCompletedAssessment())
  }

  // #region Ordered
  const handleOrderedAssessmentSearchClick = () => {
    dispatch(searchAnalystOrderedAssessment(orderedAssessmentsSearchText))
  }
  const handleOrderedAssessmentSearchResetClick = () => {
    setOrderedAssessmentSearchText('')
    dispatch(searchAnalystOrderedAssessment(''))
  }

  const handleOrderedAssessmentSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOrderedAssessmentSearchText(event.target.value)
  }
  // #endregion

  // #region In Progress
  const handleInProgressAssessmentSearchClick = () => {
    dispatch(searchAnalystInProgressReports(inProgressAssessmentsSearchText))
  }
  const handleInProgressAssessmentSearchResetClick = () => {
    setInProgressAssessmentSearchText('')
    dispatch(searchAnalystInProgressReports(''))
  }
  const handleInProgressAssessmentSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInProgressAssessmentSearchText(event.target.value)
  }
  // #endregion

  // #region Completed assessment
  const handleCompletedAssessmentSearchClick = () => {
    dispatch(searchAnalystCompletedAssessment(completedAssessmentsSearchText))
  }
  const handleCompletedAssessmentSearchResetClick = () => {
    setCompletedAssessmentSearchText('')
    dispatch(searchAnalystCompletedAssessment(''))
  }
  const handleCompletedAssessmentSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCompletedAssessmentSearchText(event.target.value)
  }
  // #endregion

  useEffect(() => {
    dispatch(resetAnalystOrderedAssessment())
    dispatch(resetAnalystCompletedAssessment())
    dispatch(resetAnalystInProgressReports())
    dispatch(setAnalystOrderedAssessmentTablePageNumber(1))
    dispatch(setAnalystInProgressReportsPageNumber(1))
    dispatch(setAnalystCompletedAssessmentTablePageNumber(1))
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="analyst-assessment-tabs">
          <Tab label="Ordered" value={AnalystTabTypes.Ordered} sx={{ textTransform: 'none', width: 200 }} />
          <Tab label="In Progress" value={AnalystTabTypes.InProgress} sx={{ textTransform: 'none', width: 200 }} />
          <Tab label="Completed" value={AnalystTabTypes.Completed} sx={{ textTransform: 'none', width: 200 }} />
        </Tabs>
      </Box>
      <TabPanel selectedTab={selectedTab} value={AnalystTabTypes.Ordered}>
        <AnalystOrderedAssessments
          analystId={analystId}
          searchValue={orderedAssessmentsSearchText}
          onResetSearchClick={handleOrderedAssessmentSearchResetClick}
          onSearchClick={handleOrderedAssessmentSearchClick}
          onSearchTextChange={handleOrderedAssessmentSearchTextChange}
          setShowInternalNotes={setShowInternalNotes}
        />
      </TabPanel>
      <TabPanel selectedTab={selectedTab} value={AnalystTabTypes.InProgress}>
        <AnalystInProgressReports
          analystId={analystId}
          searchValue={inProgressAssessmentsSearchText}
          onResetSearchClick={handleInProgressAssessmentSearchResetClick}
          onSearchClick={handleInProgressAssessmentSearchClick}
          onSearchTextChange={handleInProgressAssessmentSearchTextChange}
          setShowInternalNotes={setShowInternalNotes} />
      </TabPanel>
      <TabPanel selectedTab={selectedTab} value={AnalystTabTypes.Completed}>
        <AnalystCompletedAssessments
          analystId={analystId}
          searchValue={completedAssessmentsSearchText}
          onResetSearchClick={handleCompletedAssessmentSearchResetClick}
          onSearchClick={handleCompletedAssessmentSearchClick}
          onSearchTextChange={handleCompletedAssessmentSearchTextChange}
          setShowInternalNotes={setShowInternalNotes} />
      </TabPanel>
      {writeInternalNotes && <InternalNotes open={showInternalNotes} handleClose={() => setShowInternalNotes(false)} />}
    </Box>
  )
}

export default AnalystDetailsAssessment
