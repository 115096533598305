import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { AutoCompleteOptions } from '../../interfaces/autocomplete-options'

interface GetReportWorkspaceSuperFormOptionsState {
  assessmentRequestOptions: AutoCompleteOptions[]
  loading: LoadingType
  error: string
}

export interface ReportWorkspaceSuperFormOptions {
  assessmentRequestOptions: AutoCompleteOptions[]
}

const initialState: GetReportWorkspaceSuperFormOptionsState = {
  assessmentRequestOptions: [],
  loading: 'idle',
  error: ''
}

export const getReportWorkspaceSuperFormOptions = createAsyncThunk<
  ReportWorkspaceSuperFormOptions,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('report/getReportWorkspaceSuperFormOptions', async (_, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<ReportWorkspaceSuperFormOptions>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/superform-options`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getReportWorkspaceSuperFormOptionsSlice = createSlice({
  name: 'get-report-workspace-super-form-options',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReportWorkspaceSuperFormOptions.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(
      getReportWorkspaceSuperFormOptions.fulfilled,
      (state, { payload }) => {
        state.assessmentRequestOptions = payload.assessmentRequestOptions
        state.loading = 'loaded'
        state.error = ''
      }
    )
    builder.addCase(getReportWorkspaceSuperFormOptions.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export default getReportWorkspaceSuperFormOptionsSlice.reducer
