import { Box } from '@mui/material'
import React, { ReactNode } from 'react'

interface TabPanelProps {
  id?: string;
  children?: ReactNode;
  selectedTab: string;
  value: string;
}

export const TabPanel = ({ id, children, selectedTab, value }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== selectedTab}
      id={`${id}-tabpanel-${value}`}
      aria-labelledby={`${id}-${value}`}
      style={{ minHeight: 300 }}
    >
      {value === selectedTab && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  )
}

export default TabPanel
