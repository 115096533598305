import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../store/store'
import { LoadingType } from '../../utils/types'

export interface TriggerManualCreatedEvent {
  assessmentRequestId: number;
  onSuccess?: () => void;
}

interface TriggerManualCreatedEventState {
  loading: LoadingType;
  error: string;
}

const initialState: TriggerManualCreatedEventState = {
  loading: 'idle',
  error: ''
}

export const triggerManualCreatedEvent = createAsyncThunk<
  void,
  TriggerManualCreatedEvent,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('report/triggerManualCreatedEvent', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}/events/manual-created`,
      {},
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const triggerManualCreatedEventSlice = createSlice({
  name: 'trigger-manual-created-event',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(triggerManualCreatedEvent.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(triggerManualCreatedEvent.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(triggerManualCreatedEvent.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export default triggerManualCreatedEventSlice.reducer
