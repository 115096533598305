import React, { ReactNode } from 'react'

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export interface PaginatedTableProps {
  columns: Column[];
  items: Record<string, any>[];
  headerComponent?: ReactNode;
  footerComponent?: ReactNode;
  renderMenuRowActions?: (row: any, index: number, itemLength: number) => any;
  onRowClick?: (item: any) => void
}

export interface Column {
  id: string
  label: string
  minWidth?: number
  width?: number
  align?: 'center' | 'left' | 'right'
  className?: string
  hide?: boolean
  format?: (row: Record<string, any>) => React.ReactElement
  onRowClick?: () => void
}

const defaultTableStyles = {
  borderBottom: '1px solid rgba(224, 224, 224, 1)'
}

export const PaginatedTable = ({
  columns, items, headerComponent, footerComponent, renderMenuRowActions, onRowClick
}: PaginatedTableProps) => {
  return (
    <Box sx={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      position: 'relative'
    }}>
      <Box py={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {!!headerComponent && headerComponent}
      </Box>
      <Box sx={{ flex: 1, position: 'relative' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.label}
                    align={column.align}
                    style={{ minWidth: column.minWidth, width: column.width }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                {!!renderMenuRowActions && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={`${row.id}-${index}`}>
                    {columns.map((column: Column, index: number) => {
                      const inlineStyle = row.rowInlineStyles ? { ...defaultTableStyles, ...row.rowInlineStyles } : defaultTableStyles
                      return (
                        <TableCell
                          key={`${column.id}-${row.id}}`}
                          className={column.className}
                          align={column.align}
                          sx={inlineStyle}
                          onClick={() => {
                            if (column?.onRowClick !== undefined) {
                              column.onRowClick?.()
                            } else {
                              onRowClick?.(row)
                            }
                          }}
                        >
                          {column.format ? column.format(row) : row[column.id]}
                        </TableCell>
                      )
                    })}
                    {!!renderMenuRowActions &&
                      <TableCell sx={defaultTableStyles}>{renderMenuRowActions(row, index, items.length)}</TableCell>}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!!footerComponent && footerComponent}
      </Box>
    </Box>
  )
}

export default PaginatedTable
