import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { AdditionalDocument } from './get-report-workspace-details-slice'

export interface GenerateReportWorkspacePdf {
  htmlEndpoint: string
  reportWorkspaceId: string
  additionalDocuments: AdditionalDocument[]
  onSuccess?: () => void
  onFinal?: () => void
}

interface GenerateReportWorkspacePdfState {
  loading: LoadingType
  error: string
}

const initialState: GenerateReportWorkspacePdfState = {
  loading: 'idle',
  error: ''
}

export const generateReportWorkspacePdf = createAsyncThunk<
  void,
  GenerateReportWorkspacePdf,
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'report-workspace/generateReportWorkspacePdf',
  async (request, thunkAPI) => {
    const { tokenState } = thunkAPI.getState()
    try {
      const stages: string[] = ['Output']
      request.additionalDocuments.forEach(_ => stages.push(_.stageName))
      await axios.post<{}>(
        `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${request.reportWorkspaceId}`,
        stages,
        {
          headers: { Authorization: `Bearer ${tokenState.token}` },
          validateStatus: (status) => status < 400
        }
      )

      await axios.post<{}>(
        `${process.env.REACT_APP_API_BASE}${request.htmlEndpoint}${request.reportWorkspaceId}`,
        {},
        {
          headers: { Authorization: `Bearer ${tokenState.token}` },
          validateStatus: (status) => status < 400
        }
      )

      for (const item of request.additionalDocuments) {
        await axios.post(
          `${process.env.REACT_APP_API_BASE}${request.htmlEndpoint}${request.reportWorkspaceId}/additional-documents/${item.reportAdditionalDocumentId}`,
          {},
          {
            headers: { Authorization: `Bearer ${tokenState.token}` },
            validateStatus: (status) => status < 400
          }
        )
      }

      request.onSuccess?.()
    } catch (error) {
      return thunkAPI.rejectWithValue({ error })
    } finally {
      request.onFinal?.()
    }
  }
)

export const generateReportWorkspacePdfSlice = createSlice({
  name: 'generate-report-workspace-pdf',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(generateReportWorkspacePdf.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(generateReportWorkspacePdf.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(
      generateReportWorkspacePdf.rejected,
      (state, action) => {
        state.loading = 'error'
        state.error = action.error.message || ''
      }
    )
  }
})

export const { resetLoadingStatus } = generateReportWorkspacePdfSlice.actions
export default generateReportWorkspacePdfSlice.reducer
