import axios, { AxiosError } from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../../utils/types'
import { AppDispatch, RootState } from '../../../../store/store'
import { RejectValue } from '../../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../../utils/functions'
import { DropdownOption } from '../../../../interfaces/dropdown-option'

export interface GetFinancialStatementFilesState {
  financialStatement?: FinancialStatement
  loading: LoadingType
  error: string
}

interface FinancialStatement {
  jsonFiles: DropdownOption[]
}

const initialState: GetFinancialStatementFilesState = {
  financialStatement: undefined,
  loading: 'idle',
  error: ''
}

export interface GetFinancialStatementFilesParam {
  assessmentRequestId: number
}

export const getFinancialStatementFiles = createAsyncThunk<
  FinancialStatement,
  GetFinancialStatementFilesParam,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: RejectValue
  }
>('submissionReview/getFinancialStatementFiles', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()

  try {
    const response = await axios.get<FinancialStatement>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}/financial-statement-files`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const getFinancialStatementFilesSlice = createSlice({
  name: 'get-financial-statement-files',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFinancialStatementFiles.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getFinancialStatementFiles.fulfilled, (state, { payload }) => {
      state.financialStatement = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getFinancialStatementFiles.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetLoadingStatus } = getFinancialStatementFilesSlice.actions
