import { Alert, Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { useState } from 'react'
import { onHoldReasonOptions } from '../../../interfaces/constants'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { setAssessmentRequestOnHoldStatus } from '../set-assessment-request-on-hold-status-slice'
import { AnilityBackdrop } from '../../../components/anility-backdrop'

const AssessmentOnHoldDialogForm = ({
  onClose,
  show,
  assessmentRequestId,
  onSubmitSuccess
}: AssessmentOnHoldDialogFormProps) => {
  const [selectedValue, setSelectedValue] = useState<string>('')
  const { setAssessmentRequestOnHoldStatusState } = useAppSelector((state) => state)
  const error = setAssessmentRequestOnHoldStatusState.error
  const theme = useTheme()
  const isLoading = setAssessmentRequestOnHoldStatusState.loading === 'loading'
  const dispatch = useAppDispatch()
  const handleAssessmentOnHoldReasonChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string
    setSelectedValue(value)
  }

  const resetValue = () => {
    setSelectedValue('')
  }

  const handleSubmit = () => {
    if (!assessmentRequestId) {
      return
    }
    dispatch(setAssessmentRequestOnHoldStatus({
      assessmentRequestId,
      reason: selectedValue,
      setToOnHold: true,
      onSuccess: () => {
        onSubmitSuccess?.()
        resetValue()
        onClose()
      }
    }))
  }

  return (
    <Box sx={{ flex: 1, position: 'relative' }}>
      <CustomDialogForm
        handleSubmit={handleSubmit}
        minWidth={600}
        maxWidth={600}
        disablePrimary={!selectedValue}
        onClose={() => {
          resetValue()
          onClose()
        }}
        primaryText='Submit'
        title='Assessment On Hold'
        open={show}
        showCloseDialogIcon={true}
      >
        <Grid item container pt={2} pb={4}>
          <Grid typography='body1' item xs={12}>
            Please select a reason the assessment is being placed on hold:
          </Grid>
        </Grid>
        <Grid container spacing={2} pb={1} pt={3}>
          <Grid item xs={12} pb={2}>
              <FormControl fullWidth>
              <InputLabel id="reason-for-onhold-label" sx={{ color: theme.palette.primary.main }}>Reason for On Hold</InputLabel>
                <Select
                  labelId="reason-for-onhold-label"
                  id="assessmentRequest"
                  value={selectedValue}
                  label='Reason for On Hold'
                  onChange={handleAssessmentOnHoldReasonChange}
                  sx={{ textAlign: 'left' }}
                >
                  {onHoldReasonOptions.map((val) => (
                    <MenuItem
                      key={val}
                      value={val}
                    >
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>
        </Grid>
        {error && (
          <Grid container pt={1} pb={1}>
            <Grid item xs={12}>
              <Alert severity='error'>{error}</Alert>
            </Grid>
          </Grid>
        )}
      </CustomDialogForm>
      <AnilityBackdrop open={isLoading} />
    </Box>
  )
}

interface AssessmentOnHoldDialogFormProps extends AssessmentOnHoldDialogState {
  onClose: () => void;
  onSubmitSuccess?: () => void
}

export interface AssessmentOnHoldDialogState {
  assessmentRequestId?: number
  show: boolean
}

export default AssessmentOnHoldDialogForm
