import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../store/store'
import { LoadingType } from '../../utils/types'

interface GetSummaryState {
  summary?: Summary;
  loading: LoadingType;
  error: string;
}

interface Summary {
  assessmentsCount: number;
  customersCount: number;
  entityAssessedCount: number;
  analystsCount: number;
  assessmentsCompletedCount: number;
  assessmentsOrderedCount: number;
  assessmentsInProgressCount: number;
  assessmentsDeclinedCount: number;
  createAssessmentAvgTimeMs: number;
  entityAssessedRespondAvgTimeMs: number;
  deliverAssessmentAvgTimeMs: number;
}

const initialState: GetSummaryState = {
  summary: undefined,
  loading: 'idle',
  error: ''
}
export const getSummary = createAsyncThunk<
  Summary,
  undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('report/getSummary', async (_, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<Summary>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/summary`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getSummarySlice = createSlice({
  name: 'get-summary',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSummary.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(
      getSummary.fulfilled,
      (state, { payload }) => {
        state.summary = payload
        state.loading = 'loaded'
        state.error = ''
      }
    )
    builder.addCase(getSummary.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = getSummarySlice.actions
export default getSummarySlice.reducer
