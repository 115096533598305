import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'

interface GetAnalystBasicInfoState {
  analystDetails?: AnalystBasicInfo
  loading: LoadingType
  error: string
}

export interface AnalystBasicInfo {
  id: number
  firstName: string
  lastName: string
  fullName: string
  phoneNumber: string
  auth0Id: string
  email: string
  status: string
  createdAt: string
}

const initialState: GetAnalystBasicInfoState = {
  analystDetails: undefined,
  loading: 'idle',
  error: ''
}

interface GetAnalystBasicInfoParams {
  auth0Id: string
}

export const getAnalystBasicInfo = createAsyncThunk<
  AnalystBasicInfo,
  GetAnalystBasicInfoParams,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('analyst/getAnalystBasicInfo', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<AnalystBasicInfo>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/analystUsers/basic-info/${request.auth0Id}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getAnalystBasicInfoSlice = createSlice({
  name: 'get-analyst-basic-info',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalystBasicInfo.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getAnalystBasicInfo.fulfilled, (state, { payload }) => {
      state.analystDetails = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getAnalystBasicInfo.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = getAnalystBasicInfoSlice.actions
