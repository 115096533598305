import { useAuth0 } from '@auth0/auth0-react'
import { MICROSOFT_NAMESPACE } from '../utils/constants'

export function useRole () {
  const { user } = useAuth0()

  const roles: string[] = user ? user[`${MICROSOFT_NAMESPACE}/role`] || [] : []

  return roles
}
