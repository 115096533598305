import { useCallback } from 'react'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { useAppDispatch } from '../../../hooks/hooks'
import { triggerManualCreatedEvent } from '../trigger-manual-created-event-slice'

export const TriggerManualCreatedEventConfirmationDialog = ({ open, assessmentRequestId, onClose, onSubmitSuccess }: TriggerManualCreatedEventConfirmationDialogProps) => {
  const dispatch = useAppDispatch()
  const handleSubmit = useCallback(() => {
    if (!assessmentRequestId) {
      return
    }

    dispatch(triggerManualCreatedEvent({
      assessmentRequestId,
      onSuccess: () => {
        onSubmitSuccess?.()
        onClose()
      }
    }))
  }, [assessmentRequestId])

  return (
    <CustomDialogForm
      open={open}
      primaryText="Yes"
      handleSubmit={handleSubmit}
      onClose={onClose}
      title="Do you want to proceed with manually triggering the created event for this assessment?"
      secondaryText="Cancel">
      This assessment will be migrated to the CRM.
    </CustomDialogForm>
  )
}

export interface TriggerManualCreatedEventConfirmationDialogProps {
  open: boolean
  assessmentRequestId?: number
  onClose: () => void
  onSubmitSuccess?: () => void
}

export interface TriggerManualCreatedEventConfirmationDialogState {
  show: boolean
  assessmentRequestId?: number
}
