import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import { formatDate } from '../../../../utils/functions'
import { AnalystDetails } from '../get-analyst-details-slice'

export const AnalystDetailsHeader = ({
  analystDetails
}: {
  analystDetails?: AnalystDetails;
}) => {
  if (!analystDetails) {
    return null
  }
  return (
    <Card>
      <CardContent>
        <Box>
          <Grid container rowGap={1}>
            <Grid item xs={12} display="inline-flex">
              <Typography variant="h3">{analystDetails?.fullName}</Typography>
              {analystDetails?.status && analystDetails.status === 'Deactivated' && (
                <Chip variant="filled" label={analystDetails.status} size="small" color={'error'} sx={{ mx: 1 }} />
              )}

            </Grid>
            <Grid item xs={12} mt={2} mb={2} display="inline-flex" sx={{ backgroundColor: (theme) => theme.palette.grey[200] }}>
              <Divider />
            </Grid>

            <Grid item xs={12} container direction={'row'}>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  color={(theme) => theme.palette.text.secondary}
                >
                    Email Address:
                </Typography>
                <Typography
                  variant="body1"
                  color={(theme) => theme.palette.text.primary}
                >
                  {analystDetails?.email}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  color={(theme) => theme.palette.text.secondary}
                >
                    Phone:
                </Typography>
                <Typography
                  variant="body1"
                  color={(theme) => theme.palette.text.primary}
                >
                  {analystDetails?.phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  color={(theme) => theme.palette.text.secondary}
                >
                  Joined Date:{' '}
                </Typography>
                <Typography
                  variant="body1"
                  color={(theme) => theme.palette.text.primary}
                >
                  {formatDate(analystDetails?.createdAt, 'Do MMMM, YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}
