import { Alert, Autocomplete, Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AutoCompleteOptions } from '../../interfaces/autocomplete-options'
import { createReportWorkspace, resetCreateReportWorkspaceLoadingStatus } from './create-report-workspace-slice'

const CreateAssessmentDialogSuperForm = ({
  onClose,
  show
}: CreateAssessmentDialogSuperFormProps) => {
  const { getReportWorkspaceSuperFormOptionsState, createReportWorkspaceState } = useAppSelector((state) => state)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { assessmentRequestOptions } = getReportWorkspaceSuperFormOptionsState
  const [selectedValue, setSelectedValue] = useState<AutoCompleteOptions | undefined>()
  const error = getReportWorkspaceSuperFormOptionsState.error || createReportWorkspaceState.error

  const handleAssessmentRequestChange = (item: AutoCompleteOptions) => {
    setSelectedValue(item)
  }

  const handleSubmit = () => {
    if (!selectedValue?.value) {
      return
    }
    dispatch(
      createReportWorkspace({
        assessmentRequestId: selectedValue.value,
        onSuccess: () => {
          onClose()
          clearState()
          navigate('/report/report-workspace')
        }
      })
    )
  }

  const clearState = () => {
    setSelectedValue(undefined)
    dispatch(resetCreateReportWorkspaceLoadingStatus())
  }

  return (
    <Box sx={{ flex: 1, position: 'relative' }}>
      <CustomDialogForm
        handleSubmit={handleSubmit}
        maxWidth={800}
        disablePrimary={!selectedValue?.value}
        onClose={() => {
          onClose()
          clearState()
        }}
        primaryText='Submit'
        title='Create New Assessment'
        open={show}
        showCloseDialogIcon={true}
      >
        <Grid item container pt={1} pb={4}>
          <Grid typography='body1' item xs={12}>
            Please select the assessment request from the list below
          </Grid>
        </Grid>
        <Grid item container xs={12} mt={3} mb={3}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell key='customer-request'>
                  <Typography variant='label' color={(theme) => theme.palette.primary.main}>
                    Assessment Request
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell padding="none">
                  <Box sx={{ position: 'relative', zIndex: 3 }}>
                    <Autocomplete
                      disableClearable
                      id="assessmentRequest"
                      options={assessmentRequestOptions}
                      onChange={(_event, newValue) => handleAssessmentRequestChange(newValue)}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Please select"
                        />
                      )}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        {error && (
          <Grid container pt={1} pb={1}>
            <Grid item xs={12}>
              <Alert severity='error'>{error}</Alert>
            </Grid>
          </Grid>
        )}
      </CustomDialogForm>
    </Box>
  )
}

interface CreateAssessmentDialogSuperFormProps {
  onClose: () => void;
  show: boolean;
}

export default CreateAssessmentDialogSuperForm
