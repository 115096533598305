import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { CustomerSubsidiaryDetails } from './customer-subsidiary'
import { LoadingType } from '../../../../utils/types'
import { AppDispatch, RootState } from '../../../../store/store'

interface GetCustomerSubsidiaryDetailsState {
  details?: CustomerSubsidiaryDetails
  loading: LoadingType
  error: string
}

const initialState: GetCustomerSubsidiaryDetailsState = {
  details: undefined,
  loading: 'idle',
  error: ''
}

interface GetCustomerSubsidiaryDetailsParams {
  id: number
  customerId: number
}

export const getCustomerSubsidiaryDetails = createAsyncThunk<
  CustomerSubsidiaryDetails,
  GetCustomerSubsidiaryDetailsParams,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('customer/getCustomerSubsidiaryDetails', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<CustomerSubsidiaryDetails>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/customers/${request.customerId}/subsidiary/${request.id}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getCustomerSubsidiaryDetailsSlice = createSlice({
  name: 'get-customer-subsidiary',
  initialState,
  reducers: {
    resetGetSubsidiaryLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerSubsidiaryDetails.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getCustomerSubsidiaryDetails.fulfilled, (state, { payload }) => {
      state.details = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getCustomerSubsidiaryDetails.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetGetSubsidiaryLoadingStatus } = getCustomerSubsidiaryDetailsSlice.actions
