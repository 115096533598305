import { Route, Routes } from 'react-router-dom'
import { DashboardLayout } from '../../components/dashboard-layout'
import navigation from '../../_navMenu'
import { Analysts } from './analysts'
import { ProtectedRoute } from '../../components/protected-route'
import { AnilityScopes } from '../../interfaces/anility-scopes'

export const AnalystTools = () => {
  return (
    <DashboardLayout mainContainerProps={{ p: 0, display: 'flex', flexGrow: 1 }}
      menuItems={navigation}>
      <Routes>
        <Route
          path='/analysts/*'
          element={<ProtectedRoute permissions={[AnilityScopes.Read.AnalystUsers]}><Analysts /></ProtectedRoute>}
        />
      </Routes>
    </DashboardLayout>
  )
}
