import { useState } from 'react'
import { usePermission } from '../../../../hooks/use-permission'
import { Box, Grid, Link } from '@mui/material'
import { formatDate, formatDuration } from '../../../../utils/functions'
import { SummaryCard } from '../../../../components/summary-card'
import { EntityAssessedDetails } from '../get-entity-assessed-details-slice'
import { AnilityScopes } from '../../../../interfaces/anility-scopes'
import EntityAssessedUsers from '../entity-assessed-users'
import { InviteEntityAssessedUserDialog } from './invite-entity-assessed-user-dialog'

export const EntityAssessedDetailsSummary = ({
  entityAssessedDetails,
  refreshEntityAssessedDetails
}: {
  entityAssessedDetails?: EntityAssessedDetails
  refreshEntityAssessedDetails: () => void
}) => {
  const [showEntityAssessedUsers, setShowEntityAssessedUsers] = useState(false)
  const [showInviteEntityUser, setShowInviteEntityUser] = useState(false)
  const [hasReadEntityAssessed, hasWriteInviteTeamMember] = usePermission([
    AnilityScopes.Read.EntityAssessed, AnilityScopes.Write.InviteTeamMember
  ])

  if (!entityAssessedDetails) {
    return null
  }

  const handleEntityAssessedUsersClose = () => {
    setShowEntityAssessedUsers(false)
  }

  const handleShowEntityAssessedUsers = () => {
    setShowEntityAssessedUsers(true)
  }

  const handleInviteEntityUserDialogVisibility = () => {
    setShowEntityAssessedUsers(false)
    setShowInviteEntityUser((prev) => !prev)
  }

  const handleSuccessInviteEntityUser = () => {
    setShowInviteEntityUser(false)
    refreshEntityAssessedDetails()
  }

  return (
    <>
      <Box>
        <Grid container rowGap={1} columnGap={1} justifyContent="space-between" marginLeft={0}>
          <SummaryCard
            title="Completed Assessments"
            value={entityAssessedDetails.totalCompletedAssessmentsCount}
            description={`Since ${formatDate(entityAssessedDetails.onboardingDate, 'MMMM DD, YYYY')}`}
          />
          <SummaryCard
            title="Team Members"
            value={<Link component='span' onClick={handleShowEntityAssessedUsers} style={{ cursor: 'pointer' }}>{entityAssessedDetails.teamMembersCount}</Link>}
            description="Added to account"
          />
          <SummaryCard title='Average Response Time' description='To respond to an assessment request'
            value={formatDuration(entityAssessedDetails?.entityAssessedRespondAvgTimeMs ?? 0)} />
        </Grid>
      </Box>

      {hasReadEntityAssessed && showEntityAssessedUsers &&
        <EntityAssessedUsers open={showEntityAssessedUsers} entityAssessedDetails={entityAssessedDetails} onClose={handleEntityAssessedUsersClose} onClickInviteTeamMemberButton={hasWriteInviteTeamMember ? handleInviteEntityUserDialogVisibility : undefined}/>
      }
      {hasWriteInviteTeamMember && showInviteEntityUser &&
        <InviteEntityAssessedUserDialog entityAssessedId={entityAssessedDetails.id} open={showInviteEntityUser} onSubmitSuccess={handleSuccessInviteEntityUser} onClose={handleInviteEntityUserDialogVisibility}/>
      }
    </>
  )
}
