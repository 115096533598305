import { Route, Routes } from 'react-router-dom'
import { DashboardLayout } from '../../components/dashboard-layout'
import navigation from '../../_navMenu'
import { ProtectedRoute } from '../../components/protected-route'
import { AnilityScopes } from '../../interfaces/anility-scopes'
import { Jobs } from './jobs'
import { ReportConfiguration } from './report-configuration'
import { BaseModels } from './base-models'

export const DeveloperTools = () => {
  return (
    <DashboardLayout mainContainerProps={{ p: 0, display: 'flex', flexGrow: 1 }}
      menuItems={navigation}>
      <Routes>
        <Route
          path="/report-configuration/*"
          element={<ProtectedRoute permissions={[AnilityScopes.Read.ReportConfiguration]}><ReportConfiguration /></ProtectedRoute>}
        />
        <Route
          path="/base-models/*"
          element={<ProtectedRoute permissions={[AnilityScopes.Read.ReportConfiguration]}><BaseModels /></ProtectedRoute>}
        />
        <Route
          path="/jobs/*"
          element={<ProtectedRoute permissions={[AnilityScopes.Write.Events]}><Jobs /></ProtectedRoute>}
        />
      </Routes>
    </DashboardLayout>
  )
}
