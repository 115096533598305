import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../store/store'
import { LoadingType } from '../../utils/types'

export interface UpdateExpiredAssessment {
  assessmentRequestId: number;
  onSuccess?: () => void;
}

interface UpdateExpiredAssessmentState {
  loading: LoadingType;
  error: string;
}

const initialState: UpdateExpiredAssessmentState = {
  loading: 'idle',
  error: ''
}

export const updateExpiredAssessment = createAsyncThunk<
  void,
  UpdateExpiredAssessment,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('report/updateExpiredAssessment', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    await axios.patch<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}/updateExpiredAssessment`,
      {},
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const updateExpiredAssessmentSlice = createSlice({
  name: 'update-expired-assessment',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(updateExpiredAssessment.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(updateExpiredAssessment.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(updateExpiredAssessment.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export default updateExpiredAssessmentSlice.reducer
