import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import { AppDispatch, RootState } from '../../../store/store'
import { RejectValue } from '../../../interfaces/reject-value'
import axios, { AxiosError } from 'axios'
import { getGenericErrorMessage } from '../../../utils/functions'

interface UpdateCustomerSpecialRequirementsState {
  loading: LoadingType
  error: string
}

const initialState: UpdateCustomerSpecialRequirementsState = {
  loading: 'idle',
  error: ''
}

interface UpdateCustomerSpecialRequirementsParams {
  customerId: number
  specialRequirements: string
  onSuccess?: () => void
}

export const updateCustomerSpecialRequirements = createAsyncThunk<
  void,
  UpdateCustomerSpecialRequirementsParams,
  {
    dispatch: AppDispatch
    state: RootState,
    rejectValue: RejectValue
  }>('customer/updateCustomerSpecialRequirements',
    async (params, thunkAPI) => {
      const { tokenState, appSettingsState } = thunkAPI.getState()

      try {
        await axios.patch(
          `${process.env.REACT_APP_API_BASE}/ui/admin/customers/${params.customerId}/special-requirements`,
          {
            customerId: params.customerId,
            specialRequirements: params.specialRequirements
          },
          {
            validateStatus: (status) => status < 400,
            headers: { Authorization: `Bearer ${tokenState.token}` }
          })
        params.onSuccess?.()
      } catch (error) {
        const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
        if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
          return thunkAPI.rejectWithValue({ message: errors.response.data.title })
        }

        return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
      }
    })

export const updateCustomerSpecialRequirementsSlice = createSlice({
  name: 'update-customer-special-requirements',
  initialState,
  reducers: {
    resetUpdateCustomerSpecialRequirements: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateCustomerSpecialRequirements.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(updateCustomerSpecialRequirements.fulfilled, (state) => {
      state.loading = 'loaded'
    })
    builder.addCase(updateCustomerSpecialRequirements.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetUpdateCustomerSpecialRequirements } = updateCustomerSpecialRequirementsSlice.actions
export default updateCustomerSpecialRequirementsSlice.reducer
