
import React from 'react'
import { MenuListItemProps } from '../../../components/menu-list-item'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { ContextMenuItemProps, createContextMenuItem } from '../../../components/action-menu'
import { AssignmentInd, EditNoteOutlined, IntegrationInstructionsOutlined, PauseOutlined, StopCircleOutlined, TimerOffOutlined, ToggleOnOutlined } from '@mui/icons-material'

export const ExpiredContextMenuItem = ({ onClick }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Move to expired', <TimerOffOutlined />, [AnilityScopes.Write.Reports], onClick)
}

export const InternalNoteContextMenuItem = ({ onClick }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Internal notes', <EditNoteOutlined />, [AnilityScopes.Write.InternalNotes], onClick)
}

export const ReassignAnalystContextMenuItem = ({ onClick }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Reassign analyst', <AssignmentInd />, [AnilityScopes.Write.Assignment], onClick)
}

export const TriggerCreateEventContextMenuItem = ({ onClick }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Trigger create event', <IntegrationInstructionsOutlined />, [AnilityScopes.Write.Events], onClick)
}

export const OnHoldAssessmentsContextMenuItem = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Move to on hold', <PauseOutlined />, [AnilityScopes.Write.Assessment], onClick, show)
}

export const UnOnHoldAssessmentsContextMenuItem = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Remove On Hold', <StopCircleOutlined />, [AnilityScopes.Write.Assessment], onClick, show)
}

export const ReactivateDeclinedAssessmentContextMenuItem = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Reactivate assessment', <ToggleOnOutlined />, [AnilityScopes.Write.Assessment], onClick, show)
}
