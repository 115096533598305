import { Typography } from '@mui/material'
import { TerminatePage } from '../components/terminate-page'

export function NotFound () {
  return <TerminatePage title='Page is not available'>
    <Typography variant="body1">
      Please check the URL and try again
    </Typography>
  </TerminatePage>
}
