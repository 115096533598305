import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'

interface RunReportWorkspaceStagesState {
  loading: LoadingType;
  error: string;
  actionName?: string;
}

const initialState: RunReportWorkspaceStagesState = {
  loading: 'idle',
  error: ''
}

export interface RunReportWorkspaceStages {
  actionName: string;
  reportWorkspaceId: string;
  stages: string[];
  onSuccess?: () => void;
  onFinal?: () => void;
}

export const runReportWorkspaceStages = createAsyncThunk<
  void,
  RunReportWorkspaceStages,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('report-workspace/stages', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    thunkAPI.dispatch(setActionName(request.actionName))
    await axios.post<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${request.reportWorkspaceId}`,
      request.stages,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  } finally {
    request.onFinal?.()
  }
})

export const runReportWorkspaceStagesSlice = createSlice({
  name: 'run-report-workspace-stages',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    },
    setActionName: (state, action: PayloadAction<string>) => {
      state.actionName = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(runReportWorkspaceStages.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(runReportWorkspaceStages.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(runReportWorkspaceStages.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus, setActionName } = runReportWorkspaceStagesSlice.actions
export default runReportWorkspaceStagesSlice.reducer
