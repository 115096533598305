import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { LoadingType } from '../../../utils/types'
import { AppDispatch, RootState } from '../../../store/store'

export interface AssignAnalystToAssessmentRequests {
  assessmentRequestId: number
  analystUserId: number
  onSuccess?: () => void
}

interface AssignAnalystToAssessmentRequestsState {
  loading: LoadingType
  error: string
}

const initialState: AssignAnalystToAssessmentRequestsState = {
  loading: 'idle',
  error: ''
}

export const assignAnalystUserToAssessmentRequests = createAsyncThunk<
  void,
  AssignAnalystToAssessmentRequests,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('assessmentRequest/assignAnalystUserAssessmentRequests', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()
  try {
    await axios.patch<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}/analyst-users`,
      request.analystUserId,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'application/json'
        },
        validateStatus: (status) => status < 400
      }
    )

    request.onSuccess?.()
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const assignAnalystUserToAssessmentRequestsSlice = createSlice({
  name: 'assign-analyst-user-assessment-requests',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(assignAnalystUserToAssessmentRequests.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(assignAnalystUserToAssessmentRequests.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(assignAnalystUserToAssessmentRequests.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = assignAnalystUserToAssessmentRequestsSlice.actions
export default assignAnalystUserToAssessmentRequestsSlice.reducer
