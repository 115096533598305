import axios, { AxiosError } from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DropdownOption } from '../../../../interfaces/dropdown-option'
import { LoadingType } from '../../../../utils/types'
import { getGenericErrorMessage } from '../../../../utils/functions'
import { AppDispatch, RootState } from '../../../../store/store'
import { RejectValue } from '../../../../interfaces/reject-value'

interface GetFinancialStatementStatementTablesState {
  response?: FinancialStatementTables
  loading: LoadingType
  error: string
}

interface FinancialStatementTables {
  financialStatementTables: FinancialStatementTablesInfo[]
}

export interface FinancialStatementTablesInfo extends DropdownOption
{
  tableId: string,
  header: Row
  data: Row[]
}

export interface Row
{
  cells: Cell[]
}

export interface Cell
{
  id: string
  columnIndex: number
  columnSpan: number
  rowIndex: number
  rowSpan: number
  text: string
  formattedText: string
}

const initialState: GetFinancialStatementStatementTablesState = {
  response: undefined,
  loading: 'idle',
  error: ''
}

interface GetFinancialStatementStatementTablesStateParam {
  assessmentRequestId: number
  fileNameWithBasePath: string
}

export const getFinancialStatementTables = createAsyncThunk<
  FinancialStatementTables,
  GetFinancialStatementStatementTablesStateParam,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: RejectValue
  }
>('submissionReview/getFinancialStatementTables', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()

  try {
    const response = await axios.get<FinancialStatementTables>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}/financial-statement-files/tables?fileNameWithBasePath=${encodeURIComponent(request.fileNameWithBasePath)}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const getFinancialStatementTablesSlice = createSlice({
  name: 'get-financial-statement-potential-header-lines',
  initialState,
  reducers: {
    resetFinancialStatementTables: (state) => {
      state.loading = 'idle'
      state.error = ''
      state.response = undefined
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFinancialStatementTables.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getFinancialStatementTables.fulfilled, (state, { payload }) => {
      state.response = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getFinancialStatementTables.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetFinancialStatementTables } = getFinancialStatementTablesSlice.actions
