import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { AllPermissions, AnyPermissions, usePermission } from '../hooks/use-permission'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppSelector } from '../hooks/hooks'
import { useLogger } from '../hooks/use-logger'

interface IProtectedRoute {
  permissions: string[]
  condition?: 'All' | 'Any'
}

export const ProtectedRoute = (props: React.PropsWithChildren<IProtectedRoute>) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const location = useLocation()
  const token = useAppSelector((state) => state.tokenState.token)
  const permissions = usePermission(props.permissions)
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  const { logger } = useLogger('Admin.ProtectedRoute')

  useEffect(() => {
    if (isLoading) return
    if (!isAuthenticated) {
      logger?.info('User is not authenticated, redirecting to login page', {})
      loginWithRedirect({ appState: { returnTo: location.pathname, search: location.search } })
    }
    if (token === undefined || token === '') {
      console.log('token is undefined')
      return
    }
    if (permissions.length > 0 && !permissions.every(_ => _ === undefined)) {
      const result = props.condition === undefined || props.condition === 'All'
        ? AllPermissions(permissions)
        : AnyPermissions(permissions)
      if (!result) {
        navigate('/unauthorized')
      }
      setVisible(permissions.length !== 0 && result)
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, token, permissions, logger])

  return <>{visible && props.children}</>
}
