import { Box, Grid } from '@mui/material'
import { Logo } from './logo.component'

const topbarHeight = '64px'
export const AppLogo = () => {
  return <Box
    height={topbarHeight}
    lineHeight={topbarHeight}
    textAlign="center"
    borderBottom="1px solid rgba(255, 255, 255, .2)"
  >
    <Grid container justifyContent='center' sx={{ height: '100%' }}><Logo sx={{ margin: 'auto' }} size="medium" variant="white" /></Grid>
  </Box>
}
