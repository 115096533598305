import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface AccessTokenState {
    token: string
    forceLoginWithRedirect: boolean
}

// Define the initial state using that type
const initialState: AccessTokenState = {
  token: '',
  forceLoginWithRedirect: false
}

export const tokenSlice = createSlice({
  name: 'sidebarSlice',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setForceLoginWithRedirect: (state) => {
      state.forceLoginWithRedirect = true
    }
  }
})

export const { setToken, setForceLoginWithRedirect } = tokenSlice.actions

export default tokenSlice.reducer
