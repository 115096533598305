import { Link, Typography } from '@mui/material'
import { TerminatePage } from '../components/terminate-page'
import { useAuth0 } from '@auth0/auth0-react'

export function Unauthorized () {
  const { logout } = useAuth0()
  return <TerminatePage title='Unauthorized access'>
    <Typography variant="body1">
      You don&apos;t have access to this page. Click here to <Link component="button" onClick={() => logout({ returnTo: window.location.origin })}>logout</Link>
    </Typography>
  </TerminatePage>
}
