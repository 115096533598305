import { Route, Routes } from 'react-router-dom'
import ReportsWorkflow from './reports-workflow'

export const ReportConfiguration = () => {
  return (
    <Routes>
      <Route
        path='reports-workflow'
        element={<ReportsWorkflow />}
      />
    </Routes>
  )
}
