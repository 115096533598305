import React, { ChangeEvent, MouseEvent, useEffect, useMemo } from 'react'
import { Grid, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { PaginatedTable, Column } from '../../../../components/paginated-table'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { formatDate, formatDuration, secureFileDownload } from '../../../../utils/functions'
import { TimeInfo } from '../../../report/components/time-info'
import { TableSearch, TableSearchProps } from '../../../../components/table-search'
import { TablePagination, TablePaginationProps } from '../../../../components/table-pagination'
import { InternalNoteContextMenuItem } from '../../../report/components/assessments-action-menu'
import { setSelectedAssessmentInternalNote } from '../../../../components/set-internal-notes-slice'
import { MenuListItemProps } from '../../../../components/menu-list-item'
import { CompletedAssessmentRequestsItem } from '../../../report/completed-assessments/get-completed-assessment-requests-slice'
import { getAnalystCompletedAssessmentRequests, setPageNumber, setPageSize } from './get-analyst-completed-assessments-slice'
import { DownloadLink } from '../../../../components/download-link'
import { ActionMenu } from '../../../../components/action-menu'
import { Download } from '@mui/icons-material'

interface AnalystCompletedAssessmentsProps {
  analystId: number;
  searchValue: string;
  onSearchClick: () => void;
  onResetSearchClick: () => void;
  onSearchTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setShowInternalNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

const tableCellStyles = {
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  cursor: 'pointer'
}

const SearchComponent = ({ searchValue, onSearchClick, onResetSearchClick, onSearchTextChange, onExportButtonClick }: TableSearchProps) =>
  <Grid container justifyContent="flex-end" alignItems="flex-end">
    { !!onExportButtonClick && <IconButton sx={{ marginRight: 2, borderRadius: 0, backgroundColor: (theme) => theme.palette.grey[300] }} color="primary" onClick={onExportButtonClick}><Download /></IconButton> }
    <TableSearch searchValue={searchValue} onSearchClick={onSearchClick} onResetSearchClick={onResetSearchClick} onSearchTextChange={onSearchTextChange} />
  </Grid>

const FooterComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <Grid container justifyContent='space-between'>
    <Grid item xs={8} py={2}>
      <TimeInfo />
    </Grid>
    <Grid item xs={4}>
      <TablePagination page={page}
        onTablePageChange={onTablePageChange}
        onTablePageSizeChange={onTablePageSizeChange} />
    </Grid>
  </Grid>

export const AnalystCompletedAssessments = ({ analystId, searchValue, onSearchClick, onResetSearchClick, onSearchTextChange, setShowInternalNotes }: AnalystCompletedAssessmentsProps) => {
  const columns: Column[] = [
    {
      id: 'assignedAnalyst',
      label: 'Assigned To',
      minWidth: 150,
      className: 'wrapped-text'
    },
    {
      id: 'orderDate',
      label: 'Date Ordered',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'reportConfigurationId',
      label: 'Assessment Type',
      minWidth: 90
    },
    {
      id: 'customer',
      label: 'Customer Name',
      minWidth: 150,
      className: 'wrapped-text'
    },
    {
      id: 'contractName',
      label: 'Assessment Subject',
      minWidth: 150,
      className: 'wrapped-text'
    },
    {
      id: 'completedDateUtc',
      label: 'Completed',
      minWidth: 100
    },
    {
      id: 'entityAssessedResponseTime',
      label: 'Entity Assessed Response Time',
      minWidth: 150
    },
    {
      id: 'timeTakenToCreateAssessment',
      label: 'Time Taken to Create Assessment',
      minWidth: 150
    },
    {
      id: 'timeTakenToDeliverAssessment',
      label: 'Overall Time Taken to Deliver Assessment',
      minWidth: 170
    },
    {
      id: 'assessmentReportLink',
      label: 'Report',
      minWidth: 100
    }
  ]
  const status = 'Completed'

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { getAnalystCompletedAssessmentRequestsState, tokenState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount } = getAnalystCompletedAssessmentRequestsState

  const handleViewReportWorkspace = (
    item: CompletedAssessmentRequestsItem
  ) => {
    navigate(`/report/report-workspace/view/${item.reportWorkspaceId}`, {
      state: {
        source: '/report/completed-assessments'
      }
    })
  }

  useEffect(() => {
    if (getAnalystCompletedAssessmentRequestsState.loading === 'idle') {
      dispatch(getAnalystCompletedAssessmentRequests({
        analystId,
        status
      }))
    }
  }, [getAnalystCompletedAssessmentRequestsState.loading, analystId])

  const handleInternalNotesClick = (item: CompletedAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const generateContextMenu = (item: CompletedAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      })
    ]
  }

  const handleSearch = () => {
    onSearchClick()
  }

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const formattedCompletedAssessments = items.map((item: CompletedAssessmentRequestsItem) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      assignedAnalyst: item.assignedAnalyst?.label,
      completedDateUtc: formatDate(item.completedDateUtc),
      entityAssessedResponseTime: formatDuration(item.entityAssessedResponseTime),
      timeTakenToCreateAssessment: formatDuration(item.timeTakenToCreateAssessment),
      timeTakenToDeliverAssessment: formatDuration(item.timeTakenToDeliverAssessment),
      rowInlineStyles: tableCellStyles,
      assessmentReportLink: item.assessmentReportLink &&
        (<DownloadLink url={item.assessmentReportLink}><CloudDownloadOutlinedIcon /></DownloadLink>)
    }
  })

  const page = useMemo(() => {
    return { totalCount, size: pageSize, number: pageNumber }
  }, [totalCount, pageSize, pageNumber])

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()

    params.append('status', status)
    params.append('search', searchValue)
    params.append('analystId', analystId.toString())

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/export?${params.toString()}`, tokenState.token)
  }

  return (
    <PaginatedTable
      columns={columns}
      items={formattedCompletedAssessments}
      headerComponent={
        <SearchComponent
          searchValue={searchValue}
          onSearchTextChange={onSearchTextChange}
          onSearchClick={handleSearch}
          onResetSearchClick={onResetSearchClick}
          onExportButtonClick={handleExportButtonClick} />}
      footerComponent={
        <FooterComponent
          page={page}
          onTablePageChange={handlePageChange}
          onTablePageSizeChange={handlePageSizeChange}
        />
      }
      renderMenuRowActions={(item, index, itemLength) => {
        return (
          <ActionMenu
            isContextMenuDisabled={false}
            contextMenuIndicatorMarginLeft={0.6}
            menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
        )
      }}
      onRowClick={handleViewReportWorkspace}
    />
  )
}
