import { Button, Typography } from '@mui/material'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import React, { ChangeEvent, forwardRef } from 'react'

interface SimpleUploadButtonProps
{
  id: string
  acceptedFiles: string
  uploadText: string
  onUploadFile: (e: ChangeEvent) => void
}

export const SimpleUploadButton = forwardRef<HTMLInputElement, SimpleUploadButtonProps>(({ id, acceptedFiles, uploadText, onUploadFile }: SimpleUploadButtonProps, ref) => {
  return (
    <>
        <input
          type="file"
          id={id}
          accept={acceptedFiles}
          style={{ display: 'none' }}
          onChange={onUploadFile}
          ref={ref}

        />
        <label htmlFor={id}>
        <Button variant="text" color="primary" component="span" sx={{ p: 0, height: 'auto' }}>
            <CloudUploadOutlinedIcon sx={{ fontSize: 20, fontWeight: 400, m: 0.5, ml: 0 }} />
            <Typography>{ uploadText || 'Upload' }</Typography>
        </Button>
        </label>
    </>
  )
})

SimpleUploadButton.displayName = 'SimpleUploadButton'
