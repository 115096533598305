import React, { ChangeEvent, MouseEvent, useEffect } from 'react'
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { useNavigate } from 'react-router-dom'
import { Column, PaginatedTable } from '../../../components/paginated-table'
import { TablePagination, TablePaginationProps } from '../../../components/table-pagination'
import { getCustomerSubsidiaries, resetLoadingStatus, setPageNumber, setPageSize } from './get-customer-subsidiaries-slice'
import DeleteOutlinedIcon from '@mui/icons-material/Delete'
import { EditOutlined } from '@mui/icons-material'
import { usePermission } from '../../../hooks/use-permission'
import { AnilityScopes } from '../../../interfaces/anility-scopes'

interface CustomerSubsidiariesListProps {
  open: boolean;
  dialogName: string
  id: number
  onClose: () => void;
  onAddClick: () => void
  onEditClick: (id: number) => void
}

const PaginationComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <Grid container justifyContent='flex-end'>
    <TablePagination page={page}
      onTablePageChange={onTablePageChange}
      onTablePageSizeChange={onTablePageSizeChange} />
  </Grid>

const CustomerSubsidiariesList = ({
  open,
  id,
  dialogName,
  onClose,
  onAddClick,
  onEditClick
}: CustomerSubsidiariesListProps) => {
  const { getCustomerSubsidiariesState } = useAppSelector(state => state)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [writeCustomer] = usePermission([AnilityScopes.Write.Customers])
  const { items, totalCount, pageNumber, pageSize } = getCustomerSubsidiariesState

  const columns: Column[] = [{
    id: 'name',
    label: 'Subsidiary Name',
    minWidth: 100
  }, {
    id: 'domainName',
    label: 'Domain Name',
    minWidth: 100
  },
  {
    id: 'softDeleteCustomerSubsidiaries',
    label: 'Actions',
    align: 'center',
    hide: !writeCustomer
  }]

  useEffect(() => {
    dispatch(resetLoadingStatus())
  }, [])

  useEffect(() => {
    if (!id || isNaN(+id)) {
      navigate('..')
    } else {
      dispatch(getCustomerSubsidiaries({
        customerId: id
      }))
    }
  }, [id, pageSize, pageNumber])

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }
  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const formattedItems = items.map((item) => {
    return {
      ...item,
      softDeleteCustomerSubsidiaries: !item.isDeleted && writeCustomer
        ? <Box display="flex" alignItems="center" justifyContent="center">
            <Tooltip title="Edit customer subsidiary">
              <IconButton sx={{ color: 'black' }} onClick={() => onEditClick(item.id)}>
                <EditOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Soft delete customer subsidiary">
              <IconButton sx={{ color: 'red' }} onClick={() => {}}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
        </Box>
        : <></>
    }
  })

  const page = { totalCount, size: pageSize, number: pageNumber }
  const visibleColumns = columns.filter(_ => !_.hide)

  return (
    <>
      <CustomDialogForm
        minWidth={600}
        disablePrimary={true}
        onClose={onClose}
        primaryText=''
        title={dialogName}
        open={open}
        showCloseDialogIcon={true}
        applyFixedWidth={true}
        hideDialogActions={true}
      >
        <Grid container pb={1} pt={3}>
          <PaginatedTable
            columns={visibleColumns}
            items={formattedItems}
            headerComponent={<Box display="flex" sx={{ width: '100%', height: 'auto', justifyContent: 'flex-end' }}>
            {writeCustomer && <Button variant="contained" onClick={onAddClick}>Add customer subsidiary</Button>}
          </Box>}
            footerComponent={
              <PaginationComponent
                page={page}
                onTablePageChange={handlePageChange}
                onTablePageSizeChange={handlePageSizeChange}
              />
            }
          />
        </Grid>

      </CustomDialogForm>
      <AnilityBackdrop open={getCustomerSubsidiariesState.loading === 'loading'} />
    </>
  )
}

export default CustomerSubsidiariesList
