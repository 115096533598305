import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'

interface GetReportConfiguration {
  reportConfigurationId: string
}

interface GetReportConfigurationState {
  config: string
  loading: LoadingType
  error: string
}

const initialState: GetReportConfigurationState = {
  config: '{}',
  loading: 'idle',
  error: ''
}

export const getReportConfiguration = createAsyncThunk<
  string,
  GetReportConfiguration,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('report-configuration/getReportConfiguration', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<string>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/reportconfigurations/${request.reportConfigurationId}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getReportConfigurationSlice = createSlice({
  name: 'get-report-configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReportConfiguration.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getReportConfiguration.fulfilled, (state, { payload }) => {
      state.config = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getReportConfiguration.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export default getReportConfigurationSlice.reducer
